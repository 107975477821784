import { create } from "zustand";
import { useStytchMember } from "@stytch/react/b2b";
import { useCallback, useState } from "react";
import moment from "moment-timezone";
import { GenerateLoadingOrError, genFlatOptionList, genIdOptionList, CommonDestinationTimezone } from "../../lib/utils";
import {
  InputRequiredStatus,
  InputFormType,
  InputType,
  ImportType,
  ExportType,
  InputFilter,
  InputRequiredStatusType,
} from "../../lib/form";
import {
  useListLocations,
  Location,
  useCreateLocation,
  useUpdateLocation,
} from "../../lib/api/generalContract";
import * as FormHelper from "../../lib/form";
import * as FormLayout from "../../lib/form/layout";
import {
  PrimitiveGridLayout,
  PrimitiveManagedTable,
  PrimitiveDialogForm,
} from "../../lib/form/layout";
import { Button } from "../../components/ui/button";
import { useToast } from "../../components/ui/use-toast";
import { Loader } from "../../components/ui/loader";
import { fetchNewsArticlesPage, useGetArticleContent, useListFeeds, useListNewsArticles, useReplayArticle, useUpdateNewsArticleRating } from "@/lib/api/news";
import { Label } from "../../components/ui/label";
import { MultiSelect } from "../../components/ui/selectMulti";
import { Input } from "../../components/ui/input";
import useDebounce from "../otc/useDebounce";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../components/ui/dialog";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";
import { Badge } from "../../components/ui/badge";
import { hasAccessToFeature } from "@/lib/models/auth";
import { Star, ThumbsDown, ThumbsUp } from "lucide-react";

interface AIMetaData {
  data?: {
    summary: string;
    unknowns: Record<string, string | Record<string, string>>;
    sentiment: string;
    commodities: string[];
    un_urgent_reason?: string;
    un_important_reason?: string;
    skip_reason?: string;
    topic_group: string;
    urgent_factor: number;
    important_factor: number;
    additional_insights: Record<string, { [key: string]: string }>;
    reasoning_breakouts: Record<string, { [key: string]: string }>;
  };
  scoring_data?: AIScoringMetaData;
}

interface AIScoringMetaData {
  news_type: string;
  scores: any;
  reason: string;
  ignore_reason?: string;
  affected_securities: string[];
  impact_path: string;
  key_uncertainties: string[];
  key_points: string[];
  effect_cause_depth: string;
  time_horizon: string;
  confidence: string;
  verification_level: string;
  implementation_timeline: string;
  market_adjustment_period: string;
  final_market_adjustment_period: string;
}

interface AIMetaDataDialogProps {
  isOpen: boolean;
  onClose: () => void;
  data?: AIMetaData;
  article_id: string;
  feed_id: string;
  article_title?: string;
  rating?: number;
}

interface FilterState {
  feedIds: string[];
  dateRange: {
    start?: string;
    end?: string;
  };
  urgencyFactor: {
    min?: number;
    max?: number;
  };
  importanceFactor: {
    min?: number;
    max?: number;
  };
  setFeedIds: (ids: string[]) => void;
  setDateRange: (range: { start?: string; end?: string }) => void;
  setUrgencyFactor: (factor: { min?: number; max?: number }) => void;
  setImportanceFactor: (factor: { min?: number; max?: number }) => void;
}

const useFilterStore = create<FilterState>((set) => ({
  feedIds: [],
  dateRange: {
    start: moment().subtract(2, "days").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  },
  urgencyFactor: {
    min: 7,
    max: 10,
  },
  importanceFactor: {
    min: 7,
    max: 10,
  },
  setFeedIds: (ids) => set({ feedIds: ids }),
  setDateRange: (range) => set({ dateRange: range }),
  setUrgencyFactor: (factor) => set({ urgencyFactor: factor }),
  setImportanceFactor: (factor) => set({ importanceFactor: factor }),
}));

const ArticleFormSchema = {
  id: { hidden: true, input_type: InputType.Uuid, input_type_validation: InputFormType.Uuid, required_status: InputRequiredStatus.Optional },

  feed_id: { label: "Feed", options_reference_id: "FeedOptions", input_type: InputType.Enum, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original, externalReferences }: any) => { return externalReferences.FeedOptions.find((option: any) => option.value === original.feed_id)?.label || "Unknown" } },
  article_url: {
    action: "button",
    label: "Article URL",
    input_type: InputType.String,
    input_type_validation: InputFormType.String,
    required_status: InputRequiredStatus.Required,
    apiDisplayFormat: ({ original }: any) => { return <a key={original.id} href={original.article_url} target="_blank" className="text-blue-500 underline">{original.article_title}</a> }
  },
  article_date: { label: "Article Date", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => { return moment.tz(original.article_date, CommonDestinationTimezone).format("MM/DD/YYYY") } },

  urgency_factor: { label: "Urgency Score", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => { return original.ai_meta_data?.data?.urgent_factor || "Unknown" } },
  important_factor: { label: "Important Score", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => { return original.ai_meta_data?.data?.important_factor || "Unknown" } },

  rating: {
    action: "button",
    label: "Rating", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional, apiDisplayFormat: ({ original }: any) => {
    return <RatingComponent rating={original.rating} articleId={original.id} feedId={original.feed_id} />
  }},
};

export function NewsArticlesDashboard() {
  const [page, setPage] = useState(1);
  const [isAIMetaDataDialogOpen, setIsAIMetaDataDialogOpen] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState<any | undefined>(undefined);
  const feedsRes: any = useListFeeds();
  const filters = useFilterStore(
    useCallback((state) => ({
      urgency_factor: state.urgencyFactor,
      importance_factor: state.importanceFactor,
      feed_ids: state.feedIds,
      article_date_range: state.dateRange,
    }), [])
  );
  const debouncedFilters = useDebounce(filters, 300);
  const newsArticleRes: any = useListNewsArticles({
    page,
    filters: debouncedFilters as any,
    queryFn: () => fetchNewsArticlesPage(page, debouncedFilters as any)
  });
  const loadingOrErrorUi = GenerateLoadingOrError([newsArticleRes, feedsRes])

  if (loadingOrErrorUi) {
    return (
      <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
        <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
          Articles Dashboard
        </h1>
        {loadingOrErrorUi}
      </section>
    );
  }

  const feeds = feedsRes.data?.data || [];
  const isLoading = [newsArticleRes, feedsRes].some((result) => result.isFetching)
  const info = newsArticleRes.data?.data || {};

  return (
    <>
      <div className="container flex flex-col mb-2">
        <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
          Articles Dashboard
        </h1>
      </div>
      <div className="container flex flex-col md:flex-col items-start gap-4">
        <FilterOptions feeds={feeds} />
      </div>
      <div className="flex items-start flex-col h-full mt-4">
        <div className="container flex flex-col">
          <PrimitiveManagedTable
            tableHeight="600px"
            externalReferences={{
              FeedOptions: genIdOptionList(feeds, "source_name")
            }}
            schema={ArticleFormSchema}
            values={info.data}
            onRowClick={(item: any) => {
              setSelectedArticle(item);
              setIsAIMetaDataDialogOpen(true);
            }}
          />
        </div>
        <div className="container flex items-center justify-between mt-4">
          <Button
            onClick={() => setPage(page - 1)}
            disabled={page <= 1 || isLoading}
          >
            {isLoading ? <Loader /> : <span>Previous</span>}
          </Button>

          <span>
            Page {page} of {info.totalPages}
          </span>

          <Button
            onClick={() => setPage(page + 1)}
            disabled={page >= info.totalPages || isLoading}
          >
            {isLoading ? <Loader /> : <span>Next</span>}
          </Button>
        </div>
      </div>
      <AIMetaDataDialog
        isOpen={isAIMetaDataDialogOpen}
        onClose={() => setIsAIMetaDataDialogOpen(false)}
        data={selectedArticle?.ai_meta_data}
        rating={selectedArticle?.rating}
        article_id={selectedArticle?.id}
        feed_id={selectedArticle?.feed_id}
        article_title={selectedArticle?.article_title}
      />
    </>
  );
}

function FilterOptions({ feeds }: { feeds: any[] }) {
  const { feedIds, dateRange, urgencyFactor, importanceFactor, setFeedIds, setDateRange, setUrgencyFactor, setImportanceFactor } = useFilterStore();

  return (
    <>
      <div className="flex flex-row gap-2">

        <div className="space-y-2">
          <Label>Urgency Factor</Label>
          <div className="grid grid-cols-2 gap-2">
            <Input
              type="number"
              value={urgencyFactor.min}
              onChange={(e) => {
                const inputValue = e.target.value;
                let value;
                if (inputValue === '10') {
                  value = 10;
                } else {
                  value = inputValue === '' ? 1 : Number(inputValue.slice(-1));
                }
                setUrgencyFactor({ ...urgencyFactor, min: value });
              }}
              placeholder="Min"
              min={1}
              max={10}
            />
            <Input
              type="number"
              value={urgencyFactor.max}
              onChange={(e) => {
                const inputValue = e.target.value;
                let value;
                if (inputValue === '10') {
                  value = 10;
                } else {
                  value = inputValue === '' ? 1 : Number(inputValue.slice(-1));
                }
                setUrgencyFactor({ ...urgencyFactor, max: value });
              }}
              placeholder="Max"
              min={1}
              max={10}
            />
          </div>
        </div>
        <div className="space-y-2">
          <Label>Importance Factor</Label>
          <div className="grid grid-cols-2 gap-2">
            <Input
              type="number"
              value={importanceFactor.min}
              onChange={(e) => {
                const inputValue = e.target.value;
                let value;
                if (inputValue === '10') {
                  value = 10;
                } else {
                  value = inputValue === '' ? 1 : Number(inputValue.slice(-1));
                }
                setImportanceFactor({ ...importanceFactor, min: value });
              }}
              placeholder="Min"
              min={1}
              max={10}
            />
            <Input
              type="number"
              value={importanceFactor.max}
              onChange={(e) => {
                const inputValue = e.target.value;
                let value;
                if (inputValue === '10') {
                  value = 10;
                } else {
                  value = inputValue === '' ? 1 : Number(inputValue.slice(-1));
                }
                setImportanceFactor({ ...importanceFactor, max: value });
              }}
              placeholder="Max"
              min={1}
              max={10}
            />
          </div>

        </div>
      </div>
      <div className="flex flex-row gap-2">
        <div className="space-y-2">
          <Label>Date Range</Label>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 ">
            <Input
              type="date"
              value={dateRange.start}
              onChange={(e) => setDateRange({ ...dateRange, start: e.target.value })}
              placeholder="Start date"
            />
            <Input
              type="date"
              value={dateRange.end}
              onChange={(e) => setDateRange({ ...dateRange, end: e.target.value })}
              placeholder="End date"
            />
          </div>
        </div>
        <div className="space-y-2">
          <Label>Feeds</Label>
          <MultiSelect
            options={feeds.map((feed: any) => ({
              label: feed.source_name,
              value: feed.id
            }))}
            values={feedIds}
            setValues={(newValue) => {
              if (Array.isArray(newValue)) {
                setFeedIds(newValue);
              } else if (typeof newValue === 'function') {
                setFeedIds(newValue(feedIds));
              }
            }}
          />
        </div>
      </div>
    </>
  )
}

function RatingComponent({ onUpdate, disabled, rating, articleId, feedId, variant = 'thumbs' }: { onUpdate?: () => void; disabled?: boolean; rating?: number, articleId: string, feedId: string, variant?: 'stars' | 'thumbs' }) {
  const { toast } = useToast();
  const updateRating = useUpdateNewsArticleRating();
  const [isLoading, setIsLoading] = useState(false);
  const handleRatingChange = async (newRating: number) => {
    if (isLoading) {
      return;
    }

    if (disabled) {
      return;
    }

    setIsLoading(true);

    const response = await updateRating({ 
      article_id: articleId,
      feed_id: feedId,
      rating: newRating 
    });

    if (!response?.success) {
      toast({
        title: "Failed to update rating",
        variant: "destructive"
      });
    } else {
      toast({
        title: "Updated Rating",
      });
      onUpdate?.();
    }

    setIsLoading(false);
  };

  if (variant === 'thumbs') {
    return (
      <div className="flex gap-2 items-center">
        <Button 
          variant="ghost" 
          size="sm"
          onClick={(e) => {
            if (e?.preventDefault) {
              e.preventDefault();
            }
            return handleRatingChange(2);
          }}
          disabled={disabled}
          className={rating && rating < 3 ? "text-red-600" : "text-gray-400"}
        >
          <ThumbsDown className="h-4 w-4" />
        </Button>
        <Button 
          variant="ghost" 
          size="sm"
          onClick={(e) => {
            if (e?.preventDefault) {
              e.preventDefault();
            }
            return handleRatingChange(4);
          }}
          disabled={disabled}
          className={rating && rating >= 3 ? "text-green-600" : "text-gray-400"}
        >
          <ThumbsUp className="h-4 w-4" />
        </Button>
      </div>
    );
  }

  return (
    <div className="flex gap-1">
      {[1, 2, 3, 4, 5].map((star) => (
        <Button
          key={star}
          variant="ghost"
          disabled={disabled}
          size="sm"
          onClick={(e) => {
            if (e?.preventDefault) {
              e.preventDefault();
            }
            return handleRatingChange(star);
          }}
          className={star <= (rating || 0) ? "text-yellow-500" : "text-gray-300"}
        >
          <Star className="h-4 w-4" />
        </Button>
      ))}
    </div>
  );
}

export function AIMetaDataDialog({ isOpen, onClose, data, article_id, feed_id, article_title, rating }: AIMetaDataDialogProps) {
  const { data: metadata, scoring_data } = data || { metadata: undefined };
  const { toast } = useToast();
  const [content, setContent] = useState<string | undefined>(undefined);
  const [isLoadingContent, setIsLoadingContent] = useState(false);
  const { member } = useStytchMember();
  const isDeveloper = hasAccessToFeature({ name: "Developer", requiredPackages: ["developer"] }, member);
  const replayArticle = useReplayArticle();
  const getContent = useGetArticleContent();

  const hasOnlyScoringData = Object.keys(metadata || {}).length <= 2 && !!scoring_data;

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-2xl max-h-[80vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="text-xl font-semibold">Article Analysis</DialogTitle>
        </DialogHeader>

        {metadata && (
          <div className="space-y-6 py-4">
            {/* Summary Section */}
            <div>
              <h2 className="font-medium mb-2">{article_title}</h2>
              {isDeveloper && (
                <Button onClick={() => replayArticle({ feed_id: feed_id, article_id: article_id, replay: true })}>Replay</Button>
              )}
              {isDeveloper && (
                <Button className="ml-2" onClick={async () => {
                  if (isLoadingContent) {
                    return;
                  }
                  setIsLoadingContent(true);
                  const response = await getContent({ articleId: article_id, feedId: feed_id });

                  if (!response.success) {
                    toast({
                      title: "Failed to get content",
                    });
                  } else {
                    toast({
                      title: "Fetched Content",
                    })
                    try {
                      setContent(JSON.parse(response.data.content).text.replace(/\n|\\n/img,"<br/>"));
                    } catch(error) {
                      setContent(response.data.content || "");
                      toast({
                        title: "Failed to parse content",
                      });
                    }
                  }

                  setIsLoadingContent(false);
                }}>{isLoadingContent ? <Loader /> : "Get Content"}</Button>
              )}
            </div>

            {/* Key Metrics */}
            <div className="flex gap-4">
              <div className="flex items-center gap-2">
                <Badge variant="outline">Urgency: {metadata?.urgent_factor}/10</Badge>
                <Badge variant="outline">Importance: {metadata?.important_factor}/10</Badge>
                { metadata.sentiment && (<Badge>{metadata?.sentiment || ""}</Badge> )}
                { scoring_data?.confidence && (<Badge>{scoring_data?.confidence || ""}</Badge> )}
              </div>
            </div>

           {/* Add Rating Component here */}
            <div className="flex items-center gap-2">
              <span className="text-sm font-medium">Rating:</span>
              <RatingComponent 
                rating={rating} 
                articleId={article_id} 
                feedId={feed_id} 
                onUpdate={onClose}
              />
            </div>





            {hasOnlyScoringData && scoring_data && (
              <div>
                <h3 className="font-medium mb-2">Key Points</h3>
                <ul>
                  {scoring_data.key_points.map((point: string, index: number) => (
                    <li key={`${article_id}-${index}`}>
                      <p className="text-sm text-gray-600">• {point}</p>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {hasOnlyScoringData && scoring_data.key_uncertainties && (
              <div>
                <h3 className="font-medium mb-2">Key Uncertainties</h3>
                <ul>
                  {scoring_data.key_uncertainties.map((uncertainty: string, index: number) => (
                    <li key={`${article_id}-${index}`}>
                      <p className="text-sm text-gray-600">• {uncertainty}</p>
                    </li>
                  ))}
                </ul>
              </div>
            )}


            {!!metadata.summary && (
              <div>
                <h3 className="font-medium mb-2">Summary</h3>
                <p className="text-sm text-gray-600">{metadata?.summary || ""}</p>
              </div>
            )}

            {(metadata.un_urgent_reason || metadata.un_important_reason) && (
              <div>
                  {metadata.un_urgent_reason && (
                    <div>
                      <h3 className="font-medium mb-2">Non-Urgent Reason</h3>
                      <p className="text-sm text-gray-600">{metadata.un_urgent_reason}</p>
                    </div>
                  )}
                  {metadata.un_important_reason && (
                    <div>
                      <h3 className="font-medium mb-2">Non-Important Reason</h3>
                      <p className="text-sm text-gray-600">{metadata.un_important_reason}</p>
                    </div>
                  )}
              </div>
            )}

            { hasOnlyScoringData && scoring_data?.ignore_reason && (
              <div>
                <h3 className="font-medium mb-2">Scoring Ignore Reason</h3>
                <p className="text-sm text-gray-600">{scoring_data.ignore_reason}</p>
              </div>
            )}

            { hasOnlyScoringData && scoring_data?.affected_securities && (
              <div>
                <h3 className="font-medium mb-2">Affected Securities</h3>
                <ul>
                  {scoring_data.affected_securities.map((security: string, index: number) => (
                    <li key={`${article_id}-${index}`}>
                      <p className="text-sm text-gray-600">• {security}</p>
                    </li>
                  ))}
                </ul>
              </div>
            )}


            {metadata.commodities && (
              <div>
                <h3 className="font-medium mb-2">Commodities</h3>
                <div className="flex gap-2">
                {(metadata?.commodities || []).map((commodity) => (
                  <Badge key={commodity} variant="secondary">
                    {commodity.replace('_', ' ')}
                    </Badge>
                  ))}
                </div>
              </div>
            )}


            <Accordion type="single" collapsible className="w-full">
              {Object.entries(metadata.additional_insights || []).map(([category, insights]) => (
                <AccordionItem key={`insights-${category}`} value={`insights-${category}`}>
                  <AccordionTrigger className="text-sm font-medium">
                    Additional Insights: {category.replace('_', ' ')}
                  </AccordionTrigger>
                  <AccordionContent>
                    <div className="space-y-2">
                      {typeof insights === "string" && (
                        <div className="text-sm">
                          {insights}
                        </div>
                      )}
                      {typeof insights === "object" && (
                        Object.entries(insights).map(([key, value]) => (
                          <div key={`insights-object-${key}`} className="text-sm">
                            <span className="font-medium">{key.replace('_', ' ')}: </span>
                            {value}
                          </div>
                        ))
                      )}
                    </div>
                  </AccordionContent>
                </AccordionItem>
              ))}

              {Object.entries(metadata.reasoning_breakouts || []).map(([category, reasoning]) => (
                <AccordionItem key={`reasoning-${category}`} value={`reasoning-${category}`}>
                  <AccordionTrigger className="text-sm font-medium">
                    Detailed Analysis: {category.replace('_', ' ')}
                  </AccordionTrigger>
                  <AccordionContent>
                    <div className="space-y-2">
                      {typeof reasoning === "string" && (
                        <div className="text-sm">
                          {reasoning}
                        </div>
                      )}
                      {typeof reasoning === "object" && (
                        Object.entries(reasoning).map(([key, value]) => (
                          <div key={`reasoning-object-${key}`} className="text-sm">
                            <span className="font-medium">{key.replace('_', ' ')}: </span>
                            {value}
                          </div>
                        ))
                      )}
                    </div>
                  </AccordionContent>
                </AccordionItem>
              ))}

              {Object.entries(metadata.unknowns || []).map(([category, unknown]) => (
                <AccordionItem key={`unknowns-${category}`} value={`unknowns-${category}`}>
                  <AccordionTrigger className="text-sm font-medium">
                    Unknowns: {category.replace('_', ' ')}
                  </AccordionTrigger>
                  <AccordionContent>
                    <div className="space-y-2">
                      {typeof unknown === "string" && (
                        <div className="text-sm">
                          {unknown}
                        </div>
                      )}
                      {typeof unknown === "object" && (
                        Object.entries(unknown).map(([key, value]) => (
                          <div key={`unknown-object-${key}`} className="text-sm">
                            <span className="font-medium">{key.replace('_', ' ')}: </span>
                            {value}
                          </div>
                        ))
                      )}
                    </div>
                  </AccordionContent>
                </AccordionItem>
              ))}
              { content && (
                <AccordionItem value="content">
                  <AccordionTrigger className="text-sm font-medium">
                    Content
                  </AccordionTrigger>
                  <AccordionContent>
                    <div className="text-sm" dangerouslySetInnerHTML={{ __html: content }}></div>
                  </AccordionContent>
                </AccordionItem>
              )}
              { isDeveloper && (
                <AccordionItem value="article_id">
                  <AccordionTrigger className="text-sm font-medium">
                    Article ID
                  </AccordionTrigger>
                  <AccordionContent>
                    <div className="text-sm">{article_id}</div>
                  </AccordionContent>
                </AccordionItem>
              )}
              { isDeveloper && (
                <AccordionItem value="ai_meta_data_raw">
                  <AccordionTrigger className="text-sm font-medium">
                    AI Meta Data
                  </AccordionTrigger>
                  <AccordionContent>
                    <div className="text-sm"><JSONDisplay data={metadata} /></div>
                  </AccordionContent>
                </AccordionItem>
              )}
            </Accordion>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}


function JSONDisplay({ data }: { data: Record<string, any> }) {
  // Store raw JSON as data attribute
  const rawJSON = JSON.stringify(data);

  // Format JSON for display
  const prettyJSON = JSON.stringify(data, null, 2);

  return (
    <pre
      className="bg-gray-100 p-4 rounded-lg font-mono text-sm overflow-x-auto whitespace-pre"
      data-raw={rawJSON}
      onClick={(e) => {
        // On selection/copy, replace with raw JSON
        const selection = window.getSelection();
        if (selection?.toString()) {
          e.preventDefault();
          const raw = e.currentTarget.getAttribute('data-raw');
          selection.selectAllChildren(e.currentTarget);
          document.execCommand('insertText', false, raw as any);
        }
      }}
    >
      {prettyJSON}
    </pre>
  );
}
