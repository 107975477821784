import * as React from "react";
import { Clock } from "lucide-react";
import { Button } from "./button";
import { Input } from "./input";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { cn } from "../../lib/utils";

export function TimePicker({
  disabled,
  value,
  onChange,
  className,
}: {
  disabled?: boolean;
  value: string;
  onChange: (time: string) => void;
  className?: string;
}) {
  const [open, setOpen] = React.useState(false);

  const displayTime = value;

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTime = e.target.value + ":00";
    onChange(newTime);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          disabled={disabled}
          variant="outline"
          className={cn(
            "w-[120px] justify-start text-left font-normal",
            !value && "text-muted-foreground",
            className
          )}
        >
          <Clock className="mr-2 h-4 w-4" />
          {displayTime.substring(0, 5)}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-4">
        <Input
          disabled={disabled}
          type="time"
          value={displayTime.substring(0, 5)}
          onChange={handleTimeChange}
        />
      </PopoverContent>
    </Popover>
  );
}
