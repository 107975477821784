import { useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { BaseDbObject } from "../models/db";
import {
  fetchApiGet,
  fetchApiPut,
} from "./utils";
import { PossibleRole } from "lhc_permissions";

const ENTITY_KEY = "users";

// Validation schema for updating user permissions
export const UpdateUserPermissionsValidation = z.object({
  user_id: z.string().uuid(),
  permissions: z.array(z.string()),
});

export interface DbUserWithPackages extends BaseDbObject {
  email: string;
  name: string;
  packages: string[];
  role: PossibleRole;
}

// Hook to list all users
export function useListUsers() {
  const queryParams = new URLSearchParams();

  return useQuery({
    queryKey: [ENTITY_KEY],
    queryFn: () => fetchApiGet<DbUserWithPackages[]>("users", queryParams),
    retry: 1,
  });
}

// Hook to update user permissions
export function useUpdateUserPermissions() {
  const queryClient = useQueryClient();

  return async (data: typeof UpdateUserPermissionsValidation._type) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPut<DbUserWithPackages>(
      `users/${data.user_id}/permissions`,
      { permissions: data.permissions, user_id: data.user_id },
      queryParams,
    );

    queryClient.invalidateQueries({ queryKey: [ENTITY_KEY] });
    return result;
  };
}
