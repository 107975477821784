import moment, { Moment } from "moment-timezone";
import Decimal from "decimal.js";
import { CommonDestinationTimezone } from "../utils";

export function reduceArrayTo(items: any[], col?: string): Record<string, any> {
    return items.reduce((acc, item) => {
        const value = col ? item[col]: item;
        acc[value] = value;
        return acc;
    }, {})
}

export const optionLetterToMonthNumber: Record<string, number> = {
  "F": 1,
  "G": 2,
  "H": 3,
  "J": 4,
  "K": 5,
  "M": 6,
  "N": 7,
  "Q": 8,
  "U": 9,
  "V": 10,
  "X": 11,
  "Z": 12,
};

export const monthNumberToOptionLetter: Record<number, string> = {
  1: "F",
  2: "G",
  3: "H",
  4: "J",
  5: "K",
  6: "M",
  7: "N",
  8: "Q",
  9: "U",
  10: "V",
  11: "X",
  12: "Z",
};

export const futureMonths = ["F", "H", "K", "N", "Q", "U", "X", "Z"];

export const OptionMonthOptions = generateCurrentAndFutureMonthOptions(3, moment.tz(CommonDestinationTimezone).startOf("month")).filter(filterOutPastOptions()).map(item => ({ value: item, label: item }));
export const OlderOptionMonthOptions = generateCurrentAndFutureMonthOptions(1, moment.tz(CommonDestinationTimezone).subtract(1, "year").startOf("year")).map(item => ({ value: item, label: item }));

export function optionMonthAbbrevToPair(item: string) {
  const month = item.slice(0, 3);
  const year = item.slice(4,5);
  const monthNumber = moment.tz(`2024-${month}-01T12:00:00`, "YYYY-MMM-DDTHH:mm:ss", CommonDestinationTimezone).month() + 1;
  return `${monthNumberToOptionLetter[monthNumber]}${year}`;
}

export function generateMonthOptionsFor(date: Moment) {
  const dateMonthNumber: number = date.month() + 1;
  const dateMonthYear = date.year() % 10;
  const monthLetter: string = monthNumberToOptionLetter[dateMonthNumber] || "";

  return `${monthLetter}${dateMonthYear}`;
}

export function generateCurrentAndFutureMonthOptions(year_count: number, startDate = moment.tz(CommonDestinationTimezone)) {
  const iter_list = [];
  const list = [];
  let ticker = 0;
  let date = moment.tz(startDate, CommonDestinationTimezone);


  const startMonth = date.month() + 1;
  const startYear = date.year() % 10;
  do {
    iter_list.push({ year: date.year() % 10, month: date.month() + 1, date: moment.tz(date, CommonDestinationTimezone) });
    date = date.add(1, "year");

    ticker++;
  } while (ticker < year_count);


  for (const pair of iter_list) {
    list.push(...futureMonths.map((monthLetter: string) => `${monthLetter}${pair.year}`));
    list.push(...futureMonths.map((monthLetter: string) => `O${monthLetter}${pair.year}`));
  }

  return list;
}

export function filterOutPastOptions() {
  return function filterOutPastOptionsHelper(item: string): boolean {
    // Account for short dated
    if (item.length === 3) return filterOutPastOptionsHelper(item.slice(-2));
    const date = moment.tz(CommonDestinationTimezone).startOf("month")
    const startMonth = date.month() + 1;
    const startYear = date.year() % 10;

    const assembledOptionMonth = `${monthNumberToOptionLetter[startMonth]}${startYear}`;
    return isOptionMonthPastOther(assembledOptionMonth)(item);
  }
}

// NOTE: AA053 is discretionary
export function generateAccountLabel(account_id?: string) {
  if (!account_id) return undefined;
  return ({
  "AA054": "AA054 - Dakota",
    "AA059": "AA059 - Nebraska",
    "AA061": "AA061 - Ohio",
    "AA069": "AA069 - Wisconsin",
    "AA071": "AA071 - Minnesota",
    "AA072": "AA072 - Minnesota",
    "AA073": "AA073 - Minnesota",
    "87300100": "87300100 - RJO Dakota",
    "87310100": "87310100 - RJO Nebraska",
    "87320100": "87320100 - RJO Ohio",
    "11898": "11898 - StoneX",
})[account_id]
}

export function findMatchOption(
  options: { value: string; label: string }[],
  value: string,
) {
  return options.find(
    (item: any) =>
      item.value?.toLocaleLowerCase()?.trim() ===
      value?.toLocaleLowerCase()?.trim(),
  );
}

export function formulateOptionMonths(optionMonth?: string) {
  if (!optionMonth) return OptionMonthOptions;
  const oldFound = OlderOptionMonthOptions.find(older => older.value === optionMonth);
  if (!oldFound) return OptionMonthOptions;

  return [oldFound, ...OptionMonthOptions];
}

function isOptionMonthPastOther(comparableMonth?: string) {
    return function isOptionMonthPastOtherHelper(baseMonth: string) {
        if (!comparableMonth) return true;

        const year = parseInt(baseMonth[1]);
        const month = optionLetterToMonthNumber[baseMonth[0]];

        const comparableYear = parseInt(comparableMonth[1]);
        const comparableMonthNumber = optionLetterToMonthNumber[comparableMonth[0]];

        if (year < comparableYear) return false;
        if (year > comparableYear) return true;

        return month >= comparableMonthNumber;
    }
}
