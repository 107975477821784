import {
  Check as CheckIcon,
  X as XIcon,
} from "lucide-react";
import {
    InputRequiredStatus,
    InputFormType,
    InputType,
    ImportType,
    ExportType,
    InputFilter,
    InputRequiredStatusType,
} from "../../lib/form";
import {
  CommodityOptions,
  Commodities,
} from "../../lib/api/commodity";
import { reduceArrayTo, formulateOptionMonths } from "../../lib/api/common";
import { GenerateLoadingOrError, genFlatOptionList, genIdOptionList, CommonDestinationTimezone, createDateLabel } from "../../lib/utils";
import moment from 'moment-timezone';

export const BaseFormSchema = {
    id: { hidden: true, input_type: InputType.Uuid, input_type_validation: InputFormType.Uuid, required_status: InputRequiredStatus.Optional },

    client_id: { label: "Id", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
    short_name: { label: "Short Name", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
    long_name: { label: "Long Name", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },

    is_commercial: { label: "Commercial", input_type: InputType.Boolean, input_type_validation: InputFormType.Boolean, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => { return original.is_commercial ? <CheckIcon /> : <XIcon /> } },
    email: { label: "Email", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },
    phone_number: { label: "Phone Number", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },
    city: { label: "City", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },
    state: { label: "State", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },
    country: { label: "Country", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },
};
