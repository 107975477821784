import ExcelJS from 'exceljs';
import * as Excel from "./index";
import { CellFormat, numberToExcelColumn } from "./common";

interface CellStyle {
  width?: number;
  format?: CellFormat;
  font?: Partial<ExcelJS.Font>;
  fill?: Partial<ExcelJS.Fill>;
  border?: Partial<ExcelJS.Borders>;
  alignment?: Partial<ExcelJS.Alignment>;
}

interface FillType {
    type: "pattern";
    pattern: "solid";
    fgColor: { argb: string }
}

interface FooterCalculation {
  type: 'value' | 'sum' | 'average' | 'min' | 'max';
  value?: string | number;
  column?: number;
  customFormula?: string;
  bold?: boolean;
  fill?: FillType
  fontColor?: string;
  format?: string;
}

interface CellValue {
  value?: string | number | boolean | Date;
  formula?: string;
  bold?: boolean;
  fontSize?: number;
  fill?: FillType
  fontColor?: string;
  format?: string
}

interface ConditionalRule {
    amountCeiling: string;
    bold: boolean;
}

interface ConditionalFormat {
    column: string;
    rules: ConditionalRule[];
}

interface SectionConfig {
  headers?: string[];
  data: CellValue[][];
  padData?: {
      ifEmpty: boolean;
      data: CellValue[][];
  };
  footers?: CellValue[][];
  footerCalculations?: FooterCalculation[];
  columnSummary?: {
    column: number;
    formula: string;
  }[];
  styling?: {
      conditionalFormatting?: ConditionalFormat[];
      columns?: Record<string, CellStyle>
      header?: {
          border?: { type: "bottom" }
          bold?: boolean;
          fill?: FillType;
          fontColor?: string;
      };
      footer?: {
          border: { type: "box" | "top" }
      };
      section?: {
          border: { type: "around" | "box" }
      }
  };
}

interface SectionPosition {
  startRow: number;
  startColumn: number;
}

type SectionOffset = {
  rows?: number;
  columns?: number;
};

class ExcelSection {
  public config: SectionConfig & SectionPosition;
  public endRow: number;
  public endColumn: number;

  public dataRowStart: number;
  public dataRowEnd: number;

  public headerRowNumber?: number;
  public footerRowNumber?: number;

  constructor(config: SectionConfig & SectionPosition) {
    this.config = config;
    this.endRow = this.config.startRow - 1;
    this.endColumn = this.config.startColumn - 1;

     this.dataRowStart = this.config.startRow - 1;
     this.dataRowEnd = this.config.startRow - 1;
  }

  public async applyToSheet(sheet: ExcelJS.Worksheet): Promise<void> {
    this.applyHeaders(sheet);
    this.applyData(sheet);
    this.applyFooterCalculations(sheet);
    this.applyStyling(sheet);
  }

  private applyStyling(sheet: ExcelJS.Worksheet): void {
      if (!this.config.styling) { return; }

      if (!!this.config.styling.columns) {
        Object.keys(this.config.styling.columns).forEach((column: string) => {
            const styles = this.config.styling!.columns![column];
            if (styles.width) {
                sheet.getColumn(column).width = styles.width;
            }
            if (styles.format) {
                sheet.getColumn(column).numFmt = styles.format;
            }
        });
      }

      if (!!this.config.styling.conditionalFormatting) {
          this.config.styling.conditionalFormatting.forEach((item: ConditionalFormat) => {
              const dataColumnRange = `${item.column}${this.dataRowStart}:${item.column}${this.dataRowEnd}`;
              sheet.addConditionalFormatting({
                    ref: dataColumnRange,
                    rules: item.rules.map(rule => ({
                        type: 'cellIs',
                        operator: 'greaterThan',
                        formulae: [rule.amountCeiling],
                        style: {
                            font: {
                                bold: true
                            }
                        }
                    })) as any[]
              });
          })
      }

      if (!!this.config.styling?.header?.border) {
        const startColumn = numberToExcelColumn(this.config.startColumn);
        const endColumn = numberToExcelColumn(this.config.startColumn + (this.config.headers!.length - 1  || 0));
        Excel.applyBorderToRange(
            sheet,
                Excel.getCellWith(sheet, startColumn, this.headerRowNumber!),
                Excel.getCellWith(sheet, endColumn, this.headerRowNumber!),
            ['bottom']
        );
      }

      if (!!this.config.styling?.header?.bold) {
        const startColumn = numberToExcelColumn(this.config.startColumn);
        const endColumn = numberToExcelColumn(this.config.startColumn + (this.config.headers!.length - 1  || 0));

        const row = sheet.getRow(this.headerRowNumber!);
        (new Array(this.config.headers!.length).fill("", 0, this.config.headers!.length)).forEach((_item, index: number) => {
            const cell = row.getCell(this.config.startColumn + (index));
            Excel.boldCellWith(row, cell.col);
        })
      }

      if (!!this.config.styling?.header?.fontColor) {
        const startColumn = numberToExcelColumn(this.config.startColumn);
        const endColumn = numberToExcelColumn(this.config.startColumn + (this.config.headers!.length - 1  || 0));

        const row = sheet.getRow(this.headerRowNumber!);
        (new Array(this.config.headers!.length).fill("", 0, this.config.headers!.length)).forEach((_item, index: number) => {
            const cell = row.getCell(this.config.startColumn + (index));
            const colLetter = numberToExcelColumn(cell.col as any);
            Excel.setFontColor(sheet, [`${colLetter}${row.number}`], this.config.styling?.header?.fontColor);
        })
      }

      if (!!this.config.styling?.header?.fill) {
        const startColumn = numberToExcelColumn(this.config.startColumn);
        const endColumn = numberToExcelColumn(this.config.startColumn + (this.config.headers!.length - 1  || 0));

        const row = sheet.getRow(this.headerRowNumber!);
        (new Array(this.config.headers!.length).fill("", 0, this.config.headers!.length)).forEach((_item, index: number) => {
            const cell = row.getCell(this.config.startColumn + (index));
            cell.fill = this.config.styling?.header?.fill!;
        })
      }

      if (!!this.config.styling?.footer?.border) {
        const startColumn = numberToExcelColumn(this.config.startColumn);
        const endColumn = numberToExcelColumn(this.config.startColumn + (this.config.footerCalculations!.length - 1 || 0));
        if (this.config.styling?.footer?.border.type === "top") {
          Excel.applyBorderToRange(
              sheet,
                  Excel.getCellWith(sheet, startColumn, this.footerRowNumber!),
                  Excel.getCellWith(sheet, endColumn, this.footerRowNumber!),
              ['top']
          );
        } else if (this.config.styling?.footer?.border.type === "box") {
          Excel.applyBorderToRange(
              sheet,
                  Excel.getCellWith(sheet, startColumn, this.footerRowNumber!),
                  Excel.getCellWith(sheet, endColumn, this.footerRowNumber!),
              ['top', 'left', 'bottom', 'right']
          );
        }
      }

      if (!!this.config.styling.section?.border) {
          const borderType = this.config.styling.section?.border.type;

          const startRow = this.config.startRow;
          const startColumn = numberToExcelColumn(this.config.startColumn);

          const endRow = this.endRow;
          const endColumn = numberToExcelColumn(this.endColumn);

          switch(borderType) {
            case "around":
              Excel.applyAroundBorders(sheet, `${startColumn}${startRow}`, `${endColumn}${endRow}`);
              break;
            case "box":
                Excel.applyBorderToRange(
                    sheet,
                        Excel.getCellWith(sheet, startColumn, startRow),
                        Excel.getCellWith(sheet, endColumn, endRow),
                    ['top', 'left', 'bottom', 'right']
                );
              break;
          }
      }
  }

  private applyFooterCalculations(sheet: ExcelJS.Worksheet): void {
    if (!this.config.footerCalculations || !this.config.footerCalculations.length) {
        return;
    }

    this.footerRowNumber = ++this.endRow;

    const footerRow = sheet.getRow(this.footerRowNumber);
    this.config.footerCalculations.forEach((calc, index: number) => {
    const cell = footerRow.getCell(this.config.startColumn + (calc.column || (index)));

    if (calc.bold) {
        Excel.boldCellWith(footerRow, cell.col);
    }

    if (calc.fill) {
        cell.fill = calc.fill;
    }

    if (calc.type === "value") {
        cell.value = calc.value || "";
        return;
    }

    const columnLetter = numberToExcelColumn(this.config.startColumn + (calc.column || index));
    const startCell = `${columnLetter}${this.dataRowStart}`;
    const endCell = `${columnLetter}${this.dataRowEnd}`;

    let formulaFunctionParams = `${startCell}:${endCell}`;
    if (this.dataRowStart === this.dataRowEnd && this.dataRowStart < this.config.startRow) {
        formulaFunctionParams = `0`;
    }

    let formula: string | undefined = undefined;
    switch (calc.type) {
        case 'sum':
        formula = `SUM(${formulaFunctionParams})`;
        break;
        case 'average':
        formula = `AVERAGE(${formulaFunctionParams})`;
        break;
        case 'min':
        formula = `MIN(${formulaFunctionParams})`;
        break;
        case 'max':
        formula = `MAX(${formulaFunctionParams})`;
        break;
        default:
        formula = '';
    }

        if (formula) {
            cell.value = {
                formula: this.termReplaceFormulas(formula, this.footerRowNumber!)
            };
        }

        if (calc.format) {
          cell.numFmt = calc.format;
        }
    });
  }

  private applyHeaders(sheet: ExcelJS.Worksheet): void {
    if (!this.config.headers || !this.config.headers.length) {
        return;
    }

    this.headerRowNumber = ++this.endRow;
    const headerRow = sheet.getRow(this.headerRowNumber);
    this.config.headers.forEach((header, index) => {
    const cell = headerRow.getCell(this.config.startColumn + index);
    cell.value = header;

    this.endColumn = Math.max(this.endColumn, this.config.startColumn + index);
    });
  }

  private applyData(sheet: ExcelJS.Worksheet): void {
    if (this.config.data.length > 0 || !!this.config.padData?.ifEmpty) {
        this.dataRowStart = this.endRow + 1;
    }

    const dataToIterate = (!this.config.data.length && !!this.config.padData?.ifEmpty) ? this.config.padData.data : this.config.data;

    dataToIterate.forEach((rowData) => {
      const rowNumber = ++this.endRow;
      this.dataRowEnd = rowNumber;

      const dataRow = sheet.getRow(rowNumber);
      rowData.forEach((cellData, cellIndex) => {
        const cell = dataRow.getCell(this.config.startColumn + cellIndex);
        if (cellData.formula) {
          cell.value = {
              formula: this.termReplaceFormulas(cellData.formula, rowNumber),
          }
        } else {
          cell.value = cellData.value!;
        }
        if (cellData.bold) {
            Excel.boldCellWith(dataRow, cell.col);
        }
        if (cellData.format) {
            cell.numFmt = cellData.format;
        }
        if (cellData.fill) {
            cell.fill = cellData.fill;
        }
        if (cellData.fontSize) {
          cell.font = cell.font || {};
          cell.font.size = cellData.fontSize;
        }
        this.endColumn = Math.max(this.endColumn, this.config.startColumn + cellIndex);
      });
    });
  }

  private termReplaceFormulas(formula: string, rowNumber: number): string {
    return formula.replace(/\$ROW/img, `${rowNumber}`);
    // return formula.replace(/([A-Z]+)(\d+)/g, (match, column, row) => {
    //   const adjustedRow = parseInt(row) + this.config.startRow - 1;
    //   return `${column}${adjustedRow}`;
    // });
  }

  public getStartPosition(): SectionPosition {
    return { startRow: this.config.startRow, startColumn: this.config.startColumn };
  }

  public getEndPosition(): SectionPosition {
    return { startRow: this.endRow, startColumn: this.endColumn };
  }

  public getCellReference(rowOffset: number, columnOffset: number): string {
    const row = this.config.startRow + rowOffset;
    const column = numberToExcelColumn(this.config.startColumn + columnOffset);
    return `${column}${row}`;
  }

  public getSectionRange(): string {
    const startCell = this.getCellReference(0, 0);
    const endCell = this.getCellReference(this.endRow - this.config.startRow, this.endColumn - this.config.startColumn);
    return `${startCell}:${endCell}`;
  }
}

class ExcelComposer {
  public workbook: ExcelJS.Workbook;
  public currentSheet: ExcelJS.Worksheet | null = null;
  private sections: Map<string, ExcelSection> = new Map();
  private nextSectionId: number = 1;

  constructor() {
    this.workbook = new ExcelJS.Workbook();
  }

  public createSheet(name: string): ExcelComposer {
    this.currentSheet = this.workbook.addWorksheet(name);
    return this;
  }

  public setSheet(sheet: ExcelJS.Worksheet): ExcelComposer {
    this.currentSheet = sheet;
    return this;
  }

  public setPaperLike(): ExcelComposer {
    Excel.makePaperLikeSpreadsheet(this.currentSheet);
    return this;
  }

  public async addImage(imageBuffer: Buffer, range: string | any): Promise<ExcelComposer> {
    await Excel.addImage(imageBuffer, {
      workbook: this.workbook,
      worksheet: this.currentSheet,
      extension: "",
      range,
    });
    return this;
  }

  private generateSectionId(): string {
    return `section_${this.nextSectionId++}`;
  }

  public addSection(config: SectionConfig, position: SectionPosition): ExcelSection {
    if (!this.currentSheet) {
      throw new Error("No active sheet. Call createSheet() first.");
    }
    const section = new ExcelSection({ ...config, ...position });
    section.applyToSheet(this.currentSheet);
    const sectionId = this.generateSectionId();
    this.sections.set(sectionId, section);
    return section;
  }

  public getSectionPosition(sectionId: string): SectionPosition | null {
    const section = this.sections.get(sectionId);
    return section ? section.getStartPosition() : null;
  }

  public getSectionRange(sectionId: string): string | null {
    const section = this.sections.get(sectionId);
    return section ? section.getSectionRange() : null;
  }

  public async getBuffer(): Promise<Buffer> {
    return this.workbook.xlsx.writeBuffer() as any;
  }

  public async save(filename: string): Promise<void> {
    await this.workbook.xlsx.writeFile(filename);
  }
}

export { ExcelComposer, ExcelSection };
export type { SectionConfig, SectionPosition, SectionOffset, CellStyle, CellValue  };
