import { Member } from "@stytch/vanilla-js/b2b";
import {
  Home as HomeIcon,
  Bell as BellIcon,
  Activity as ActivityIcon,
  Wheat as GrainIcon,
  FlaskConical as FlaskConicalIcon,
  LineChart as LineChartIcon,
  Upload as UploadIcon,
  BarChartBig as BarChartBigIcon,
  Info as InfoIcon,
  Zap as ZapIcon,
  CandlestickChart as CandlestickChartIcon,
  Receipt as ReceiptIcon,
  FileSpreadsheet as FileSpreadsheetIcon,
  Building as BuildingIcon,
  ListStart as ListStartIcon,
  FileBarChart as FileBarChartIcon,
  MessageCircle as MessageCircleIcon,
  UserCog as UserCogIcon,
} from "lucide-react";
import { PossiblePackage, doesUserHavePackages } from "../lib/models/auth";

export enum RoutePath {
  Dashboard = "/",
  HedgeBeaconAnalysis = "/analyst/recommendation",
  HedgeBeaconAlert = "/analyst/alert",
  HedgeBeaconMarketThoughts = "/analyst/market_thoughts",
  HedgeBeaconEmailPreview = "/analyst/email/preview",
  TicketsWeightDashboard = "/weight/tickets/dashboard",
  TicketsTradingDashboard = "/trading/tickets/dashboard",
  TicketsUpload = "/tickets/upload",
  SalesAndPurchaseReports = "/tickets/freight",
  DormanStagedDashboard = "/tickets/dorman/staged",
  MarketingTransactionalReport = "/reports/marketing",
  SpreadReport = "/reports/spread",
  ARReport = "/reports/ar",
  TicketsJobsDashboard = "/tickets/jobs",
  TradingBacktest = "/trading/backtest",
  TradingAtsData = "/trading/ats/dashboard",
  TradingAtsLive = "/trading/ats/live",
  QueuedDocuments = "/document/queue",
  DeveloperDashboard = "/developer/dashboard",
  DeveloperJobsDashboard = "/developer/jobs/dashboard",
  DeveloperTestPage = "/developer/test",
  DeveloperClientNotifications = "/developer/client/notifications",
  MobileClientUsers = "/mobile/client/users",
  Authenticate = "/authenticate",
  Login = "/login",
  ManageCustomers = "/ManageCustomers",
  LocationDashboard = "/location",
  ContactsDashboard = "/contacts",
  MarketZoneDashboard = "/marketZone",
  GeneralContractsDashboard = "/general/contracts",
  StagedGeneralContractsDashboard = "/general/contracts/staged",
  AgtechContractsDashboard = "/agtech/contracts",
  ManageTraders = "/ManageTraders",
  CogsReports = "/cogs",
  OtcDashboard = "/otc",
  PositionsDashboard = "/trading/positions",
  DestinationMessagingDashboard = "/destination/messaging",
  DestinationMessagingConsentScreenshot = "/destination-consent-screenshot",
  DestinationMessagingConsent = "/destination-consent",
  NewsArticles = "/news/articles",
  ManagePermissions = "/permissions/manage",
  InvoiceAndStatementReport = "/reports/invoices",
  PrivacyPolicy = "/privacy-policy",
}

export function pagesList({
  currentPathname,
  member,
  filterByPackages,
}: {
  member?: Member | null;
  currentPathname?: string;
  filterByPackages: boolean;
}) {
  return [
    {
      name: "Privacy Policy",
      href: RoutePath.PrivacyPolicy,
      icon: BarChartBigIcon,
      requiredPackages: [],
      description: "Privacy Policy",
      background: "bg-blue-500",
      isProtected: false,
      excludeFromDashboard: true,
    },
    {
      name: "Developer",
      href: RoutePath.DeveloperDashboard,
      icon: BarChartBigIcon,
      requiredPackages: [PossiblePackage.Developer],
      description: "Private dashboard to do developer actions",
      background: "bg-blue-500",
      isProtected: true,
      excludeFromDashboard: true,
    },
    {
      name: "Manage Permissions",
      href: RoutePath.ManagePermissions,
      icon: UserCogIcon,
      requiredPackages: [PossiblePackage.ManagePermission],
      description: "Manage Permissions",
      background: "bg-blue-500",
      isProtected: true,
    },
    {
      name: "Jobs Dashboard",
      href: RoutePath.DeveloperJobsDashboard,
      icon: BarChartBigIcon,
      requiredPackages: [PossiblePackage.Developer],
      description: "Job Status Dashboard",
      background: "bg-blue-500",
      isProtected: true,
    },
    {
      name: "Developer Test",
      href: RoutePath.DeveloperTestPage,
      icon: BarChartBigIcon,
      requiredPackages: [PossiblePackage.Developer],
      description: "Test new functionality",
      background: "bg-blue-500",
      isProtected: true,
      excludeFromDashboard: true,
    },
    {
      name: "Developer Client Notifications",
      href: RoutePath.DeveloperClientNotifications,
      icon: BarChartBigIcon,
      requiredPackages: [PossiblePackage.Developer, PossiblePackage.ClientNotification],
      description: "Send client notifications",
      background: "bg-blue-500",
      isProtected: true,
      excludeFromDashboard: true,
    },
    {
      name: "Authenticate",
      href: RoutePath.Authenticate,
      icon: BarChartBigIcon,
      requiredPackages: [],
      description: "Authenticate",
      background: "bg-blue-500",
      isProtected: false,
      excludeFromDashboard: true,
    },
    {
      name: "Login",
      href: RoutePath.Login,
      icon: BarChartBigIcon,
      requiredPackages: [],
      description: "Login Page",
      background: "bg-blue-500",
      isProtected: false,
      excludeFromDashboard: true,
    },
    {
      name: "Destination Consent",
      href: RoutePath.DestinationMessagingConsent,
      icon: MessageCircleIcon,
      requiredPackages: [],
      description: "",
      background: "bg-pink-500",
      isProtected: false,
      excludeFromDashboard: true,
      shouldStayOnUnAuthed: true,
    },
    {
      name: "Destination Consent Screenshot",
      href: RoutePath.DestinationMessagingConsentScreenshot,
      icon: MessageCircleIcon,
      requiredPackages: [],
      description: "",
      background: "bg-pink-500",
      isProtected: false,
      excludeFromDashboard: true,
      shouldStayOnUnAuthed: true,
    },
    {
      name: "Dashboard",
      href: RoutePath.Dashboard,
      icon: HomeIcon,
      requiredPackages: [],
      isProtected: true,
    },
    {
      name: "Analysis",
      href: RoutePath.HedgeBeaconAnalysis,
      icon: ActivityIcon,
      requiredPackages: [PossiblePackage.HedgeBeacon],
      description:
        "Write analysis for upcoming Daily HedgeBeacon Analysis emails.",
      background: "bg-indigo-500",
      isProtected: true,
    },
    {
      name: "Email Preview",
      href: RoutePath.HedgeBeaconEmailPreview,
      icon: ActivityIcon,
      requiredPackages: [PossiblePackage.HedgeBeacon],
      description:
        "Preview the email for a given set of details.",
      background: "bg-indigo-500",
      isProtected: true,
    },
    {
      name: "Alerts",
      href: RoutePath.HedgeBeaconAlert,
      icon: BellIcon,
      requiredPackages: [PossiblePackage.HedgeBeacon],
      description:
        "Create alerts on commodities and have them sent out as texts on publish.",
      background: "bg-indigo-500",
      isProtected: true,
    },
    {
      name: "Market Thoughts",
      href: RoutePath.HedgeBeaconMarketThoughts,
      icon: ZapIcon,
      requiredPackages: [PossiblePackage.HedgeBeaconMarketThoughts],
      description: "Send out market thoughts as texts on publish.",
      background: "bg-pink-500",
      isProtected: true,
    },
    {
      name: "News Articles",
      href: RoutePath.NewsArticles,
      icon: MessageCircleIcon,
      requiredPackages: [PossiblePackage.News],
      description: "",
      background: "bg-pink-500",
      isProtected: true,
      excludeFromDashboard: false,
    },
    {
      name: "Weight Tickets",
      href: RoutePath.TicketsWeightDashboard,
      icon: GrainIcon,
      requiredPackages: [PossiblePackage.Tickets],
      description: "View and download weight tickets by provider",
      background: "bg-pink-500",
      isProtected: true,
    },
    {
      name: "Ticket Jobs",
      href: RoutePath.TicketsJobsDashboard,
      icon: InfoIcon,
      requiredPackages: [PossiblePackage.Tickets],
      description: "View status of PDF ticket jobs",
      background: "bg-pink-500",
      isProtected: true,
    },
    {
      name: "Trading Tickets",
      href: RoutePath.TicketsTradingDashboard,
      icon: LineChartIcon,
      requiredPackages: [PossiblePackage.Tickets],
      description: "View and download trading tickets by provider",
      background: "bg-pink-500",
      isProtected: true,
    },
    {
      name: "Staged Dorman Tickets",
      href: RoutePath.DormanStagedDashboard,
      icon: LineChartIcon,
      requiredPackages: [PossiblePackage.Tickets],
      description: "Pair Dorman and TT orders",
      background: "bg-pink-500",
      isProtected: true,
    },
    {
      name: "OTC Report",
      href: RoutePath.OtcDashboard,
      icon: FileBarChartIcon,
      requiredPackages: [PossiblePackage.OTC],
      description: "Contracts report",
      background: "bg-pink-500",
      isProtected: true,
    },
    {
      name: "Mobile Contact",
      href: RoutePath.ContactsDashboard,
      icon: BuildingIcon,
      requiredPackages: [PossiblePackage.Employee],
      description: "",
      background: "bg-pink-500",
      isProtected: true,
    },
    {
      name: "Mobile Client Users",
      href: RoutePath.MobileClientUsers,
      icon: BuildingIcon,
      requiredPackages: [PossiblePackage.ClientManageUsers],
      description: "",
      background: "bg-pink-500",
      isProtected: true,
    },
    {
      name: "Locations",
      href: RoutePath.LocationDashboard,
      icon: BuildingIcon,
      requiredPackages: [PossiblePackage.General],
      description: "",
      background: "bg-pink-500",
      isProtected: true,
    },
    {
      name: "Market Zones",
      href: RoutePath.MarketZoneDashboard,
      icon: BuildingIcon,
      requiredPackages: [PossiblePackage.General],
      description: "",
      background: "bg-pink-500",
      isProtected: true,
    },
    {
      name: "Destination Messaging",
      href: RoutePath.DestinationMessagingDashboard,
      icon: MessageCircleIcon,
      requiredPackages: [PossiblePackage.Destination],
      description: "",
      background: "bg-pink-500",
      isProtected: true,
    },
    {
      name: "General Contracts",
      href: RoutePath.GeneralContractsDashboard,
      icon: FileBarChartIcon,
      requiredPackages: [PossiblePackage.GeneralContract],
      description: "",
      background: "bg-pink-500",
      isProtected: true,
    },
    {
      name: "Staged General Contracts",
      href: RoutePath.StagedGeneralContractsDashboard,
      icon: FileBarChartIcon,
      requiredPackages: [PossiblePackage.GeneralContract],
      description: "",
      background: "bg-pink-500",
      isProtected: true,
    },
    {
      name: "Agtech Contracts",
      href: RoutePath.AgtechContractsDashboard,
      icon: FileBarChartIcon,
      requiredPackages: [PossiblePackage.GeneralContract, PossiblePackage.OTC],
      description: "",
      background: "bg-pink-500",
      isProtected: true,
    },
    {
      name: "Positions Report",
      href: RoutePath.PositionsDashboard,
      icon: FileBarChartIcon,
      requiredPackages: [PossiblePackage.OTC],
      description: "Positions report",
      background: "bg-pink-500",
      isProtected: true,
    },
    {
      name: "Customers",
      href: RoutePath.ManageCustomers,
      icon: BuildingIcon,
      requiredPackages: [PossiblePackage.ManagerCustomer],
      description: "Manage Customers",
      background: "bg-pink-500",
      isProtected: true,
    },
    {
      name: "Traders",
      href: RoutePath.ManageTraders,
      icon: BuildingIcon,
      requiredPackages: [PossiblePackage.TransactionalReports],
      description: "Manage Traders",
      background: "bg-pink-500",
      isProtected: true,
    },
    {
      name: "Upload",
      href: RoutePath.TicketsUpload,
      icon: UploadIcon,
      requiredPackages: [PossiblePackage.Tickets],
      description: "Reupload or upload missed / failed tickets",
      background: "bg-pink-500",
      isProtected: true,
    },
    {
      name: "Freight",
      href: RoutePath.SalesAndPurchaseReports,
      icon: ReceiptIcon,
      requiredPackages: [PossiblePackage.Freight],
      description: "Sales and purchase reports",
      background: "bg-pink-500",
      isProtected: true,
    },
    {
      name: "COGS",
      href: RoutePath.CogsReports,
      icon: ReceiptIcon,
      requiredPackages: [PossiblePackage.Tickets],
      description:
        "Cost of Goods Sold (COGS) and Sales Transactional analysis reports",
      background: "bg-pink-500",
      isProtected: true,
    },
    {
      name: "Spread Report",
      href: RoutePath.SpreadReport,
      icon: ReceiptIcon,
      requiredPackages: [PossiblePackage.TransactionalReports],
      description:
        "Spread report",
      background: "bg-pink-500",
      isProtected: true,
    },
    {
      name: "AR Report",
      href: RoutePath.ARReport,
      icon: ReceiptIcon,
      requiredPackages: [PossiblePackage.TransactionalReports],
      description:
        "AR report",
      background: "bg-pink-500",
      isProtected: true,
    },
    {
      name: "Marketing Fees Report",
      href: RoutePath.MarketingTransactionalReport,
      icon: FileSpreadsheetIcon,
      requiredPackages: [PossiblePackage.TransactionalReports],
      description: "Marketing Fees",
      background: "bg-pink-500",
      isProtected: true,
    },
    {
      name: "Invoice Report",
      href: RoutePath.InvoiceAndStatementReport,
      icon: FileSpreadsheetIcon,
      requiredPackages: [PossiblePackage.InvoiceAndStatement],
      description: "Invoice and Statements",
      background: "bg-pink-500",
      isProtected: true,
    },
    {
      name: "Queued Documents",
      href: RoutePath.QueuedDocuments,
      icon: ListStartIcon,
      requiredPackages: [PossiblePackage.SignatureDocuments],
      description: "View documents queued to send",
      background: "bg-pink-500",
      isProtected: true,
    },
    {
      name: "ATS",
      href: RoutePath.TradingAtsLive,
      icon: CandlestickChartIcon,
      requiredPackages: [PossiblePackage.AtsRead],
      description: "Submit commands to ATS and view its current position.",
      background: "bg-blue-500",
      isProtected: true,
    },
    {
      name: "Backtest",
      href: RoutePath.TradingBacktest,
      icon: FlaskConicalIcon,
      requiredPackages: [PossiblePackage.AtsBacktest],
      description: "Backtest ATS algorithms",
      background: "bg-blue-500",
      isProtected: true,
      excludeFromDashboard: false,
    },
    {
      name: "ATS Data",
      href: RoutePath.TradingAtsData,
      icon: BarChartBigIcon,
      requiredPackages: [PossiblePackage.AtsBacktest],
      description: "View and download recent data, such as Price Bars.",
      background: "bg-blue-500",
      isProtected: true,
    },
  ]
    .map((item) => ({
      ...item,
      current:
        currentPathname === item.href ||
        (item.href !== "/" && currentPathname?.startsWith(item.href)),
    }))
    .filter((item) => {
      if (!filterByPackages) return true;
      if (!item.requiredPackages.length) return true;

      return doesUserHavePackages({
        requiredPackages: item.requiredPackages,
        member,
      });
    });
}
