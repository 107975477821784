import moment from "moment-timezone";
import { CommonDestinationTimezone } from "../../lib/utils";
import { GenerateLoadingOrError } from "../../lib/utils";
import {
    InputRequiredStatus,
    InputFormType,
    InputType,
} from "../../lib/form";
import {
    useListJobsDashboardInfo,
    useAcknowledgeJob,
    useActionOnJob,
} from "../../lib/api/developer";
import * as FormHelper from "../../lib/form";
import * as FormLayout from "../../lib/form/layout";
import {
    PrimitiveGridLayout,
    PrimitiveManagedTable,
} from "../../lib/form/layout";
import { Button } from "../../components/ui/button";
import { useToast } from "../../components/ui/use-toast";
import { FormGridHelper } from "../reports/helper";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
    DialogClose,
    DialogFooter,
  } from "../../components/ui/dialog";
import { cn } from "../../lib/utils";
import { useState } from "react";

export const JobCountsSchema = {
    job_type: { label: "Job Type", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
    count: { label: "Count", input_type: InputType.Decimal, input_type_validation: InputFormType.Decimal, required_status: InputRequiredStatus.Optional },
};

export const BaseDepthSchema = {
    running_jobs: { label: "Running Jobs", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
    last_updated: { label: "Last Updated", input_type: InputType.DateTime, input_type_validation: InputFormType.DateTime, required_status: InputRequiredStatus.Optional },
    snapshot_version: { label: "Snapshot Version", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
};

export const CurrentFlagsSchema = {
    flag_type: { label: "Flag Type", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
    flag_status: { label: "Flag Status", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional, apiDisplayFormat: ({ original }: any) => { return `${original.flag_status === true ? "Enabled" : "Disabled"}` } },
    enabled: { label: "Enabled", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => { return `${original.enabled === true ? "Enabled" : "Disabled"}` } },
    meta_data: { label: "Meta Data", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => { return JSON.stringify(original.meta_data || {}) } }
};

export const JobQueueSchema = {
    created_at: { label: "Created At", input_type: InputType.DateTime, input_type_validation: InputFormType.DateTime, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => { return original.created_at ? moment.tz(original.created_at, CommonDestinationTimezone).format("MM/DD/YYYY HH:mm") : "" } },
    updated_at: { label: "Updated At", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => { return original.updated_at ? moment.tz(original.updated_at, CommonDestinationTimezone).format("MM/DD/YYYY HH:mm") : "" } },
    job_type: { label: "Job Type", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
    stage: { label: "Stage", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },
    job_status: { label: "Job Status", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
    poll_skip_to: { label: "Poll Skip To", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional, apiDisplayFormat: ({ original }: any) => { return original.poll_skip_to ? moment.tz(original.poll_skip_to, CommonDestinationTimezone).format("MM/DD/YYYY HH:mm") : "" } },
    retried_count: { label: "Retry Count", input_type: InputType.Number, input_type_validation: InputFormType.Number, required_status: InputRequiredStatus.Required },
    expiryDateTime: { label: "Expiry Date", input_type: InputType.DateTime, input_type_validation: InputFormType.DateTime, required_status: InputRequiredStatus.Optional, apiDisplayFormat: ({ original }: any) => { return original.expiryDateTime ? moment.tz(original.expiryDateTime, CommonDestinationTimezone).format("MM/DD/YYYY HH:mm") : "" } },
    last_reason: { label: "Last Reason", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },
    order_group_id: { label: "Order Group ID", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },
    priority: { label: "Priority", input_type: InputType.Number, input_type_validation: InputFormType.Number, required_status: InputRequiredStatus.Required },
    parent_job_id: { label: "Parent Job ID", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },
    external_provider_type: { label: "External Provider", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },
};

export const TicketJobSchema = {
    last_computed_status: { label: "Last Computed Status", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
    filename: { label: "Filename", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => { return original.document?.filename || "" } },
    ticket_job_created_at: { label: "Ticket Job Created At", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => { return original.ticket_job_created_at ? moment.tz(original.ticket_job_created_at, CommonDestinationTimezone).format("MM/DD/YYYY HH:mm") : "" } },
    key: { label: "Document Key", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => { return original.document?.key || "" } },
    bucket: { label: "Bucket", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => { return original.document?.bucket || "" } },
    ticket_job_id: { label: "Ticket Job ID", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
    document_id: { label: "Document ID", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
};

const BaseJobQueueActionFormSchema = {
    type: { label: "Type", input_type: InputType.Literal, input_type_validation: InputFormType.Literal("queue_job"), required_status: InputRequiredStatus.Required },
    action: { label: "Action", input_type: InputType.Literal, input_type_validation: InputFormType.Literal("reset_to_fresh_queued"), required_status: InputRequiredStatus.Required },
    should_acknowledge: { label: "Should Acknowledge", input_type: InputType.Boolean, input_type_validation: InputFormType.Boolean, required_status: InputRequiredStatus.Required },
    reason: { label: "Reason", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },
};

const BaseTicketJobActionFormSchema = {
    type: { label: "Type", input_type: InputType.Literal, input_type_validation: InputFormType.Literal("ticket_job"), required_status: InputRequiredStatus.Required },
    action: { label: "Action", input_type: InputType.Literal, input_type_validation: InputFormType.Literal("replay_incoming_ticket_s3_bucket_event"), required_status: InputRequiredStatus.Required },
    should_acknowledge: { label: "Should Acknowledge", input_type: InputType.Boolean, input_type_validation: InputFormType.Boolean, required_status: InputRequiredStatus.Required },
    reason: { label: "Reason", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },
};

const ticketJobActionState = FormHelper.generateBaseState({
    schemas: [BaseJobQueueActionFormSchema],
    baseSchema: BaseTicketJobActionFormSchema,
});

const jobQueueActionState = FormHelper.generateBaseState({
    schemas: [BaseJobQueueActionFormSchema],
    baseSchema: BaseJobQueueActionFormSchema,
});

export function DeveloperJobsDashboard() {
    const loadingOrErrorUi = GenerateLoadingOrError([]);
    const { data, isLoading, isError } = useListJobsDashboardInfo();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogType, setDialogType] = useState<"job_queue" | "ticket_job" | undefined>(undefined);
    const [actionItem, setActionItem] = useState<any>(undefined);

    const populateQueueJobWith = jobQueueActionState.useInputsState((state: any) => state.populateWith);
    const populateTicketJobWith = ticketJobActionState.useInputsState((state: any) => state.populateWith);

    if (loadingOrErrorUi) {
         return (
             <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
                <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                    Jobs Dashboard
                </h1>
                {loadingOrErrorUi}
            </section>
        );
    }

    const jobQueueDepthSnapshots = data?.data?.job_queue_depth_snapshots || [];
    const currentJobFlags = data?.data?.current_job_flags || [];
    const job_queue = data?.data?.job_queue || [];
    const halted_job_queue = data?.data?.halted_job_queue || [];
    const ticket_jobs = data?.data?.ticket_jobs || [];
    const job_counts = data?.data?.job_counts || [];

    return (
        <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
            <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                Jobs Dashboard
            </h1>
            <ActionDialog
                isOpen={isDialogOpen}
                dialogType={dialogType}
                actionItem={actionItem}
                onDialogChange={(value: boolean) => {
                    setIsDialogOpen(value);
                }}
            />
            <div className="flex flex-col justify-end gap-4 mb-4">
            <h3 className="scroll-m-20 text-xl font-extrabold tracking-tight lg:text-2xl">Job Queue Depth Snapshots</h3>
            <PrimitiveManagedTable 
                schema={BaseDepthSchema} 
                values={jobQueueDepthSnapshots} 
            />
            <h3 className="scroll-m-20 text-xl font-extrabold tracking-tight lg:text-2xl">Queued Job Counts</h3>
            <PrimitiveManagedTable
                schema={JobCountsSchema}
                values={job_counts}
            />
            <h3 className="scroll-m-20 text-xl font-extrabold tracking-tight lg:text-2xl">Current Flags</h3>
            <PrimitiveManagedTable 
                schema={CurrentFlagsSchema} 
                values={currentJobFlags} 
            />
            <h3 className="scroll-m-20 text-xl font-extrabold tracking-tight lg:text-2xl">Halted Job Queue</h3>
            <PrimitiveManagedTable 
                schema={JobQueueSchema} 
                values={halted_job_queue} 
                onRowClick={(value) => {
                    populateQueueJobWith({ type: "queue_job", action: "reset_to_fresh_queued" });
                    setActionItem(value);
                    setDialogType("job_queue");
                    setIsDialogOpen(true);
                }}
            />
            <h3 className="scroll-m-20 text-xl font-extrabold tracking-tight lg:text-2xl">Ticket Jobs</h3>
            <PrimitiveManagedTable 
                schema={TicketJobSchema} 
                values={ticket_jobs} 
                onRowClick={(value) => {
                    populateTicketJobWith({ type: "ticket_job", action: "replay_incoming_ticket_s3_bucket_event" });
                    setActionItem(value);
                    setDialogType("ticket_job");
                    setIsDialogOpen(true);
                }}
            />
            <h3 className="scroll-m-20 text-xl font-extrabold tracking-tight lg:text-2xl">Job Queue</h3>
            <PrimitiveManagedTable 
                schema={JobQueueSchema} 
                values={job_queue} 
                onRowClick={(value) => {
                    populateQueueJobWith({ type: "queue_job", action: "reset_to_fresh_queued" });
                    setActionItem(value);
                    setDialogType("job_queue");
                    setIsDialogOpen(true);
                }}
            />
            </div>
        </section>
    );
}

export function ActionDialog({
    isOpen,
    dialogType,
    onDialogChange,
    className,
    actionItem,
}: {
    actionItem: any;
    isOpen: boolean;
    className?: string;
    dialogType: "job_queue" | "ticket_job" | undefined;
    onDialogChange: (value: boolean) => void;
}) {
        return (
            <Dialog
                onOpenChange={(value: boolean) => {
                    if (onDialogChange) {
                        onDialogChange(value);
                    }
                }}
                open={isOpen || false}
                defaultOpen={false}
            >
                <DialogContent className={cn("max-w-full sm:max-w-lg lg:max-w-3xl xl:max-w-7xl flex flex-col flex-start", className)}>
                    <DialogHeader>
                        <DialogTitle>
                            {dialogType === "job_queue" ? "Action on Job Queue" : "Action on Ticket Job"}
                        </DialogTitle>
                    </DialogHeader>
                    {dialogType === "job_queue" ? <JobQueueActionForm actionItem={actionItem} action_type="queue_job" onDialogChange={onDialogChange} /> : <TicketJobActionForm actionItem={actionItem} action_type="ticket_job" onDialogChange={onDialogChange} />}
                </DialogContent>
            </Dialog>
        );
}



function JobQueueActionForm({
    actionItem,
    action_type,
    onDialogChange,
}: {
    actionItem: any;
    action_type: "queue_job" | "ticket_job";
    onDialogChange: (value: boolean) => void;
}) {
    const { toast } = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const onActionOnJob = useActionOnJob();
    const acknowledgeJob = useAcknowledgeJob();

    return (
        <div>
            <div className="text-sm text-gray-500 mb-4">{actionItem?.id || ""}</div>
            <Button className="mb-4" disabled={isLoading} onClick={async () => {
                if (isLoading) return;
                setIsLoading(true);
                const result = await acknowledgeJob({ id: actionItem.id, type: action_type, reason: "" });
                if (!result.success) {
                    toast({
                        title: "Failed to acknowledge job",
                        description: "",
                    });
                } else {
                    toast({
                        title: "Acknowledged",
                        description: "",
                    });
                    onDialogChange(false); 
                }

                setIsLoading(false);
            }}>
                Acknowledge
            </Button>
            <FormGridHelper
                hideRelations
                hideSkipButton
                onSuccess={() => {}}
                state={jobQueueActionState}
                activeSchema={BaseJobQueueActionFormSchema}
                id={"JobQueueActionForm"}
                externalReferences={{}}
                failMessage="Failed to action on Job Queue"
                successMessage="Actioned"
                onSubmitTo={(item: any) => {
                    return onActionOnJob({
                        id: actionItem.id,
                        should_acknowledge: item.should_acknowledge,
                        reason: item.reason,
                        action_info: {
                            type: action_type,
                            action: item.action,
                        },
                    });
                }}
                onCloseForm={() => { onDialogChange(false); }}
            />
        </div>
    );
}

function TicketJobActionForm({
    actionItem,
    action_type,
    onDialogChange,
}: {
    actionItem: any;
    action_type: "queue_job" | "ticket_job";
    onDialogChange: (value: boolean) => void;
}) {
    const { toast } = useToast();
    const [isLoading, setIsLoading] = useState(false);
    const onActionOnJob = useActionOnJob();
    const acknowledgeJob = useAcknowledgeJob();

    return (
        <div>

            <Button className="mb-4" disabled={isLoading} onClick={async () => {
                if (isLoading) return;
                setIsLoading(true);
                const result = await acknowledgeJob({
                    id: actionItem.ticket_job_id,
                    type: action_type,
                    reason: ""
                });
                if (!result.success) {
                    toast({
                        title: "Failed to acknowledge job",
                        description: "",
                    });
                } else {
                    toast({
                        title: "Acknowledged",
                        description: "",
                    });
                    onDialogChange(false); 
                }

                setIsLoading(false);
            }}>
                Acknowledge
            </Button>
            <FormGridHelper
                hideRelations
                hideSkipButton
                onSuccess={() => {}}
                state={ticketJobActionState}
                activeSchema={BaseTicketJobActionFormSchema}
                id={"TicketJobActionForm"}
                externalReferences={{}}
                failMessage="Failed to action on Ticket Job"
                successMessage="Actioned"
                onSubmitTo={(item: any) => {
                    return onActionOnJob({
                        id: actionItem.ticket_job_id,
                        should_acknowledge: item.should_acknowledge,
                        reason: item.reason,
                        action_info: {
                            type: action_type,
                            action: item.action,
                        },
                    });
                }}
                onCloseForm={() => { onDialogChange(false); }}
            />
        </div>
    );
}
