import ExcelJS from "exceljs";
import * as XLSX from "xlsx";
import Decimal from "decimal.js";
import moment from "moment-timezone";
import { CommonDestinationTimezone } from "../api/trading";
import { downloadBinaryData } from "../browser/download";
import { workbookToBuffer, safeParseDecimal, parseFromBrowserFile, CellFormat } from "../excel";
import { parseFromFile as parseXlsxFromFile } from "../file";
import { ServerError } from "../responseResults";
import {
  parseFromString,
  PurchaseReleaseRow,
  SalesReleaseRow,
  PurchaseAndSalesByContractPositionByMarketZoneRow,
  OpenPositionsFuturesAndOptions,
} from "./utils";
import { optionLetterToMonthNumber, monthNumberToOptionLetter } from "../api/otc";
import { Commodities, CommodityOptions } from "../api/commodity";

const showAmount = 8;
const bufferAmount = 2;

type GenericRow = Record<string, any>[];

enum ErrorReason {
    MissingCommodityIdColumn = "missing_commodity_id_column",
    UnknownCommodityIdColumn = "unknown_commodity_id_column",
    MissingOptionMonthColumn = "missing_option_month_column",
    UnknownOptionMonthColumn = "unknown_option_month_column",
    UnknownQuantityColumn = "unknown_quantity_column",
    MissingQuantityColumn = "missing_quantity_column",
    MissingContColumn = "missing_cont_column",
    MissingUomColumn = "missing_uom_column",
    UnknownUomColumn = "unknown_uom_column",
}

enum SkipReason {
    HasFuturesOrBasis = "has_futures_or_basis",
}

interface Info<T> {
    quantity: Decimal,
    formattedQuantity?: string;
    rows: T[]
}

interface NetInfo<T> {
    net: Decimal,
    rows: T[]
}

interface MarketZoneResult {
    SkippedRows: { row: PurchaseAndSalesByContractPositionByMarketZoneRow, reason: string }[],
    UnknownRows: { row: PurchaseAndSalesByContractPositionByMarketZoneRow, reason: string }[],
    map: Record<string, Record<string, Info<PurchaseAndSalesByContractPositionByMarketZoneRow>>>
}

interface OpenPositionResult {
    SkippedRows: { row: OpenPositionsFuturesAndOptions, reason: string }[],
    UnknownRows: { row: OpenPositionsFuturesAndOptions, reason: string }[],
    map: Record<string, Record<string, NetInfo<OpenPositionsFuturesAndOptions>>>
}

interface PurchaseReleaseResult {
    SkippedRows: { row: PurchaseReleaseRow, reason: string }[],
    UnknownRows: { row: PurchaseReleaseRow, reason: string }[],
    hta_map: Record<string, Record<string, Info<PurchaseReleaseRow>>>
    otc_map: Record<string, Record<string, Info<PurchaseReleaseRow>>>
    dp_map: Record<string, Record<string, Info<PurchaseReleaseRow>>>
    basis_map: Record<string, Record<string, Info<PurchaseReleaseRow>>>
}

interface SaleReleaseResult {
    SkippedRows: { row: SalesReleaseRow, reason: string }[],
    UnknownRows: { row: SalesReleaseRow, reason: string }[],
    basis_map: Record<string, Record<string, Info<SalesReleaseRow>>>
    dp_map: Record<string, Record<string, Info<SalesReleaseRow>>>
}

interface OptionOverrides {
    optionMonths: Record<string, string>
}

export async function processFromFiles({
    files,
    overrides,
}: {
    files: {
        purchaseRelease: File;
        salesRelease: File;
        byMarketZone: File;
        openPositionsFuturesAndOptions: File;
    };
    overrides: OptionOverrides
}) {
    const parsedFiles: Record<string, any[]> = {};
    for (const pair of Object.entries(files)) {
        const [key, value] = pair;

        if (value.name.endsWith(".csv")) {
            parsedFiles[key] = parseFromString((await parseFromBrowserFile(value)).data);
        } else {
            const workbook = await parseXlsxFromFile(value);
            let sheetName = workbook.SheetNames[0]!;
            let sheet = workbook.Sheets[sheetName];
            let sheetData = XLSX.utils.sheet_to_json(sheet);

            sheetData = sheetData.map((row: any) => {
                return Object.entries(row).reduce((acc: any, [key, value]) => {
                    (acc as any)[key.trim()] = value;
                    return acc;
                }, {} as any);
            });

            parsedFiles[key] = sheetData;
        }
    }

  return await processCSVs(parsedFiles as any, overrides);
}

async function processCSVs(files: {
    purchaseRelease: PurchaseReleaseRow[];
    salesRelease: SalesReleaseRow[];
    byMarketZone: PurchaseAndSalesByContractPositionByMarketZoneRow[];
    openPositionsFuturesAndOptions: OpenPositionsFuturesAndOptions[]
}, overrides: OptionOverrides) {
    const debugWorkbook = new ExcelJS.Workbook();
    const startDate = new Date();

    const salesReport = aggregateSaleReport(files.salesRelease, startDate, overrides);

    const purchaseReport = aggregatePurchaseReport(files.purchaseRelease, startDate, debugWorkbook, overrides);

    const openReport = aggregateOpenPositiosnReport(files.openPositionsFuturesAndOptions, startDate);

    const marketZoneReport = aggregateMarketZoneReport(files.byMarketZone, startDate, overrides);

    return await aggregateAndAssembleMasterSpreadReport({
        salesReport,
        purchaseReport,
        openReport,
        marketZoneReport,
        debugWorkbook,
        startDate,
        overrides,
    });
}

async function aggregateAndAssembleMasterSpreadReport({
    salesReport,
    purchaseReport,
    openReport,
    marketZoneReport,
    debugWorkbook,
    startDate,
    overrides
}: {
    salesReport: any;
    purchaseReport: any;
    openReport: any;
    marketZoneReport: any;
    debugWorkbook: any;
    startDate: Date;
    overrides: OptionOverrides;
}) {
    const testWorkbook = new ExcelJS.Workbook();

    const commodities = Array.from(new Set([
        ...Object.keys(salesReport.basis_map),
        ...Object.keys(salesReport.dp_map),
        ...Object.keys(purchaseReport.dp_map),
        ...Object.keys(purchaseReport.hta_map),
        ...Object.keys(purchaseReport.basis_map),
    ]));

    const sheets: Record<string, any> = {};

    commodities.forEach((commodityId: string) => {
        const commodityLabel = getDisplayCommodity(commodityId);
        if (sheets[commodityLabel]) return;

        sheets[commodityLabel] = testWorkbook.addWorksheet(commodityLabel);
    });

    commodities.forEach((commodityId: string) => {
        const frontMonth = determineCurrentFrontMonth(commodityId, startDate, overrides);
        const firstNAndBeyond = generateFirstNAndBeyond(commodityId, showAmount, frontMonth);

        const longCashHtaMonths = Array.from(new Set([
            ...Object.keys(purchaseReport.hta_map[commodityId] || {}),
            ...Object.keys(purchaseReport.otc_map[commodityId] || {}),
        ]));
        longCashHtaMonths.sort(sortBasicOptionMonths);

        const longCashBasisMonths = Array.from(new Set([
            ...Object.keys(purchaseReport.dp_map[commodityId] || {}),
            ...Object.keys(purchaseReport.basis_map[commodityId] || {}),
        ]));
        longCashBasisMonths.sort(sortBasicOptionMonths);

        const shortCashBasisMonths = Array.from(new Set([
            ...Object.keys(salesReport.basis_map[commodityId] || {}),
            ...Object.keys(salesReport.dp_map[commodityId] || {}),
        ]));
        shortCashBasisMonths.sort(sortBasicOptionMonths);


        const sheet = sheets[getDisplayCommodity(commodityId)];

        // TODO get rid of beyond and always show how however many months there are
        const columnCMonths = [
            ...firstNAndBeyond, "Beyond", "", // 9 - 1
            ...firstNAndBeyond, "Beyond", "", // 9 - 18 - 1
            ...firstNAndBeyond, "Beyond", "", // 18 - 1
        ];

        const longCashHtaMonthsBeyond = longCashHtaMonths.filter(aMonth => !firstNAndBeyond.includes(aMonth));
        const longCashBasisMonthsBeyond = longCashBasisMonths.filter(aMonth => !firstNAndBeyond.includes(aMonth));
        const shortCashBasisMonthsBeyond = shortCashBasisMonths.filter(aMonth => !firstNAndBeyond.includes(aMonth));

        const columnBValues = columnCMonths.map((month, index) => {
            if (index < showAmount + bufferAmount) {
                if (month === "Beyond") {
                    return [
                        ...longCashHtaMonthsBeyond.map(month => purchaseReport.hta_map[commodityId]?.[month]?.quantity),
                        ...longCashHtaMonthsBeyond.map(month => purchaseReport.otc_map[commodityId]?.[month]?.quantity),
                    ].reduce(safeReduceDecimal, new Decimal(0)).toNumber()
                }
                if (month === "") return "Long Cash Basis";

                return (
                    [
                        purchaseReport.hta_map[commodityId]?.[month]?.quantity,
                        purchaseReport.otc_map[commodityId]?.[month]?.quantity
                    ].reduce(safeReduceDecimal, new Decimal(0)).toNumber()
                );
            } else if (index < (showAmount + bufferAmount) * 2) {
                if (month === "Beyond") {
                    return [
                        ...longCashBasisMonthsBeyond.map(month => purchaseReport.dp_map[commodityId]?.[month]?.quantity),
                        ...longCashBasisMonthsBeyond.map(month => purchaseReport.basis_map[commodityId]?.[month]?.quantity),
                    ].reduce(safeReduceDecimal, new Decimal(0)).toNumber()
                }
                if (month === "") return "Short Cash Basis";

                return (
                    [
                        purchaseReport.dp_map[commodityId]?.[month]?.quantity,
                        purchaseReport.basis_map[commodityId]?.[month]?.quantity
                    ].reduce(safeReduceDecimal, new Decimal(0)).toNumber()
                );
            } else {
                if (month === "") return "";
                if (month === "Beyond") {
                    return [
                        ...shortCashBasisMonthsBeyond.map(month => salesReport.dp_map[commodityId]?.[month]?.quantity),
                        ...shortCashBasisMonthsBeyond.map(month => salesReport.basis_map[commodityId]?.[month]?.quantity),
                    ].reduce(safeReduceDecimal, new Decimal(0)).toNumber()
                }

                return (
                    [
                        salesReport.dp_map[commodityId]?.[month]?.quantity,
                        salesReport.basis_map[commodityId]?.[month]?.quantity
                    ].reduce(safeReduceDecimal, new Decimal(0)).toNumber()
                );
            }
        });

        // Reiterate the option months for proximity
        const columnJValues = firstNAndBeyond;

        // Reiterate long cash HTA
        const columnKValues = columnBValues.slice(0, showAmount);
        // Reiterate long cash basis
        const columnLValues = columnBValues.slice(showAmount + bufferAmount, (showAmount + bufferAmount) + showAmount);
        // Reiterate short cash basis
        const columnMValues = columnBValues.slice((showAmount + bufferAmount) * 2, (showAmount + bufferAmount) * 2 + showAmount);

        // Inventory
        const columnNValues = firstNAndBeyond.map(() => (new Decimal(0)).toNumber());

        // K - L - M- N
        // Net
        const columnOValues = firstNAndBeyond.map((_item, index) => {
            return safeParseDecimal(columnLValues[index]).sub(safeParseDecimal(columnKValues[index])).sub(safeParseDecimal(columnMValues[index])).sub(safeParseDecimal(columnNValues[index])).toNumber();
        });

        // Basis Long/Short
        const columnPValues = firstNAndBeyond.map((month) => {
            return safeParseDecimal(marketZoneReport?.map?.[commodityId]?.[month]?.quantity).toNumber()
        });
        columnPValues.push(columnPValues.reduce(safeReduceDecimal, new Decimal(0)).toNumber())

        // What our futures should be
        const columnQValues = firstNAndBeyond.map((_item, index) => {
            // =O3/5000+P3/-5000
            if (commodityId === Commodities.Canola) {
                return (safeParseDecimal(columnOValues[index]).div(2204.62).div(20)).plus((safeParseDecimal(columnPValues[index]).div(-2204.62).div(20))).toNumber()
            }

            return (safeParseDecimal(columnOValues[index]).div(5000)).plus((safeParseDecimal(columnPValues[index]).div(-5000))).toNumber()
        });

        // Futures Net
        const columnRValues = firstNAndBeyond.map((month) => {
            return safeParseDecimal(openReport?.map?.[month]?.[commodityId]?.net).toNumber();
        });

        // if (!salesGroup.wroteBalances && salesGroup.sum > 0) {
        //   salesGroup.balancesIndex = maxLength;
        //   sheet.getRow(rowEnd + 1).getCell(7).value = {
        //     formula: `SUM(G${rowStart}:G${rowEnd})`,
        //     result: computeOutputBalance(salesGroup.sum, salesPart[4]),
        //   };
        //   boldCell(sheet.getRow(rowEnd + 1), "G");
        // }

        // To Balance
        const columnSValues = firstNAndBeyond.map((_item, index) => {
            return (safeParseDecimal(columnQValues[index])).sub((safeParseDecimal(columnRValues[index]))).toNumber()
        });
        columnSValues.push(columnSValues.reduce(safeReduceDecimal, new Decimal(0)).toNumber())

        sheet.addRow(["Date", moment.tz( CommonDestinationTimezone).format("D-MMM"), "", "", "", "", "", "", "","", "TotalsOutput", "", "", "", "", "", "", ""]);
        sheet.addRow(["", "Long Cash HTA", "Month", "", "", "All", "OTC", "", "", "", "Long Cash HTA", "Long Cash Basis", "Short Cash Basis", "Inventory", "Net", "Basis Long/Short", "What our futures should be", "Futures Net", "To Balance"]);


        const indexOffset = 2;
        columnBValues.forEach((bValue, index) => {
            sheet.addRow([
                "", bValue, columnCMonths[index], "", "", "", "", "", "",
                getSafeValue(columnJValues, index) || "",
                getSafeValue(columnKValues, index) || "",
                getSafeValue(columnLValues, index) || "",
                getSafeValue(columnMValues, index) || "",
                getSafeValue(columnNValues, index) || "",
                getSafeValue(columnOValues, index) || "",
                getSafeValue(columnPValues, index) || "",
                getSafeValue(columnQValues, index) || "",
                getSafeValue(columnRValues, index) || "",
                getSafeValue(columnSValues, index) || "",
            ]);
        });


        sheet.getColumn("B").numFmt = CellFormat.NUMBER;
        sheet.getColumn("B").width = 20;

        sheet.getColumn("K").numFmt = CellFormat.NUMBER;
        sheet.getColumn("K").width = 20;
        sheet.getColumn("L").numFmt = CellFormat.NUMBER;
        sheet.getColumn("L").width = 20;
        sheet.getColumn("M").numFmt = CellFormat.NUMBER;
        sheet.getColumn("M").width = 20;
        sheet.getColumn("N").numFmt = CellFormat.NUMBER;
        sheet.getColumn("N").width = 14;
        sheet.getColumn("O").numFmt = CellFormat.NUMBER;
        sheet.getColumn("O").width = 20;

        sheet.getColumn("Q").numFmt = CellFormat.Int;
        sheet.getColumn("Q").width = 18;
        sheet.getColumn("R").numFmt = CellFormat.Int;
        sheet.getColumn("R").width = 14;
        sheet.getColumn("S").numFmt = CellFormat.Int;
        sheet.getColumn("S").width = 14;

        sheet.getColumn("P").numFmt = CellFormat.NON_CURRENCY_ACCOUNTING;
        sheet.getColumn("P").width = 17;
    });

    const date = moment().format("YYYY_MM_DDTHH:mm");
    downloadBinaryData(
        await workbookToBuffer(testWorkbook),
        `spread_${date}.xlsx`,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    );

    return {};
}


function aggregateOpenPositiosnReport(rows: OpenPositionsFuturesAndOptions[], startDate: Date) {
    return rows.reduce((acc: OpenPositionResult, row: OpenPositionsFuturesAndOptions) => {
        if (shouldSkipCommodity(row[`Commodity`]?.trim())) { return acc; }
        const commodityId = getCommodity(row[`Commodity`]?.trim());

        const originalOptionMonth = row[`Option Month`]?.trim();
        if (shouldSkipOptionMonth(originalOptionMonth)) { return acc; }

        const futuresNet = safeParseDecimal(row[`Buy`]).plus(safeParseDecimal(row[`Sell`]).times(-1));

        if (!commodityId) {
            acc.UnknownRows.push({ row, reason: ErrorReason.MissingCommodityIdColumn });
            return acc;
        }

        if (!originalOptionMonth) {
            acc.UnknownRows.push({ row, reason: ErrorReason.MissingOptionMonthColumn });
            return acc;
        }

        const optionMonth = parseMarketZoneOptionMonth({ originalRow: originalOptionMonth, commodityId });

        if (!optionMonth) {
            acc.UnknownRows.push({ row, reason: ErrorReason.UnknownOptionMonthColumn });
            return acc;
        }

        const map = acc.map;
        if(!map[optionMonth]) map[optionMonth] = {};
        if(!map[optionMonth][commodityId]) map[optionMonth][commodityId] = { net: new Decimal(0), rows: [] };

        const parsedNet = safeParseDecimal(futuresNet);

        map[optionMonth][commodityId].net = map[optionMonth][commodityId].net.plus(parsedNet);
        map[optionMonth][commodityId].rows.push(row);

        return acc;
    }, { SkippedRows: [], UnknownRows: [], map: {} } as any);
}

function aggregateMarketZoneReport(rows: PurchaseAndSalesByContractPositionByMarketZoneRow[], startDate: Date, overrides: OptionOverrides) {
    if (!rows.length) {
        return { SkippedRows: [], UnknownRows: [], map: {} };
    }

    // NOTE: This is a csv row list, not excel workbook so we cant query for columns like
    // give me columns I-T so we could order them, so we have to order them manually
    // This should also be length 12, one for each calendar month, but it the first item should be the current month
    // for when the report was generated
    const columns = Object.keys(rows[0]).filter((column: string) => /[a-zA-Z]{3}\/[0-9]{2}/img.test(column));
    columns.sort((a: string, b: string) => {
        const aSplit = a.split("/");
        const bSplit = b.split("/");

        // NOTE: Sort by year first. These are always 2 digit strings. e.g 24, 16 etc
        // So these can use string sort logic
        if (aSplit[1] < bSplit[1]) return -1;
        if (aSplit[1] > bSplit[1]) return 1;

        const aMonthMoment = moment.tz(`2024-${aSplit[0]}-01T00:00:00`, "YYYY-MMM-DDT00:00:00", CommonDestinationTimezone).month() || 0;
        const bMonthMoment = moment.tz(`2024-${bSplit[0]}-01T00:00:00`, "YYYY-MMM-DDT00:00:00", CommonDestinationTimezone).month() || 0;

        if (aMonthMoment < bMonthMoment) return -1;
        if (aMonthMoment > bMonthMoment) return 1;

        return 0;
    });

    // NOTE: Now that we have calendar months we have to roll the calendar months into the option month
    return rows.reduce((acc: MarketZoneResult, row: PurchaseAndSalesByContractPositionByMarketZoneRow) => {
        // const optionMonthPairs = rollRowCalendarMonthsIntoOptionMonths(row, columns)

        if (shouldSkipCommodity(row[`Commodity`]?.trim())) { return acc; }
        const commodityId = getCommodity(row["Commodity"]?.trim());

        const uom = row["Position UOM"]?.trim();
        const deliquentDecimal = safeParseQuantityWithUom({ amount: row["Delinquent"], commodity: commodityId, uom });

        const frontMonth = determineCurrentFrontMonth(commodityId, startDate, overrides);
        const monthsList = generateYearsListFrom(commodityId, startDate, frontMonth);

        const map = acc.map;
        if (!map[commodityId]) map[commodityId] = {};
        if (!map[commodityId][frontMonth]) map[commodityId][frontMonth] = { quantity: new Decimal(0), rows: [] };

        columns.forEach((month) => {
            const split = month.split("/");
            const mappedMonth = rollMonthYearNumber(
                monthsList,
                moment.tz(`2024-${split[0]}-01T00:00:00`, "YYYY-MMM-DDT00:00:00", CommonDestinationTimezone).month() + 1,
                parseInt(split[1].slice(-1))
            );
            const value = safeParseQuantityWithUom({ amount: (row as any)[month], commodity: commodityId, uom });

            if (!map[commodityId]) map[commodityId] = {};
            if (!map[commodityId][mappedMonth]) map[commodityId][mappedMonth] = { quantity: new Decimal(0), rows: [] };
            map[commodityId][mappedMonth].quantity = map[commodityId][mappedMonth].quantity.plus(safeParseDecimal(value));
        });

        map[commodityId][frontMonth].quantity = map[commodityId][frontMonth].quantity.plus(safeParseDecimal(deliquentDecimal));

        return acc;
    }, { SkippedRows: [], UnknownRows: [], map: {} } as any);
}

function aggregateSaleReport(salesRelease: SalesReleaseRow[], startDate: Date, overrides: OptionOverrides) {
    return salesRelease.reduce((acc: SaleReleaseResult, row: SalesReleaseRow) => {
        const cont_type = row[`Cont`]?.trim()?.toLocaleLowerCase();

        if (!cont_type) {
            acc.UnknownRows.push({ row, reason: ErrorReason.MissingContColumn });
            return acc;
        }

        if (shouldSkipCommodity(row[`Commodity`]?.trim())) { return acc; }
        const commodityId = getCommodity(row[`Commodity`]?.trim());

        const currentFrontMonth = determineCurrentFrontMonth(commodityId, startDate, overrides);
        const quantity = row[`Quantity`];
        // Column T
        const uom = row[`Ct UOM`]?.trim();

        if (!commodityId) {
            acc.UnknownRows.push({ row, reason: ErrorReason.MissingCommodityIdColumn });
            return acc;
        }

        if (!quantity) {
            acc.UnknownRows.push({ row, reason: ErrorReason.MissingQuantityColumn });
            return acc;
        }

        if (!uom) {
            acc.UnknownRows.push({ row, reason: ErrorReason.MissingUomColumn });
            return acc;
        }

        const originalOptionMonth = row[`Opt  Mth`]?.trim();
        if (shouldSkipOptionMonth(originalOptionMonth)) { return acc; }

        const parsedQuantity = safeParseQuantityWithUom({ amount: quantity, commodity: commodityId, uom });
        const optionMonth = parseAndRollNearestOptionMonth(originalOptionMonth, commodityId, currentFrontMonth)!;
        const isFrontMonth = optionMonth === currentFrontMonth;

        const futures = row[`Futures`];
        const basis = row[`Basis`];
        const colG = row[`Id`]?.trim();
        const colH = row[`Name`]?.trim();

        if (colG?.toLocaleLowerCase() === "1tbd") {
            return acc;
        }

        if (colH?.toLocaleLowerCase() === "tbd") {
            return acc;
        }

        if (["basis", "dp"].some(item => cont_type.startsWith(item)) && futures == null && isFrontMonth) {
            const map = acc.basis_map;

            if(!map[commodityId]) map[commodityId] = {};
            if(!map[commodityId][optionMonth]) map[commodityId][optionMonth] = { quantity: new Decimal(0), rows: [] };

            map[commodityId][optionMonth].quantity = map[commodityId][optionMonth].quantity.plus(parsedQuantity);
            map[commodityId][optionMonth].rows.push(row as any);
        } else if (basis != null && futures == null && !isFrontMonth) {
             const map = acc.basis_map;

            if(!map[commodityId]) map[commodityId] = {};
            if(!map[commodityId][optionMonth]) map[commodityId][optionMonth] = { quantity: new Decimal(0), rows: [] };


            map[commodityId][optionMonth].quantity = map[commodityId][optionMonth].quantity.plus(parsedQuantity);
            map[commodityId][optionMonth].rows.push(row as any);
        } else if (cont_type.startsWith("dp") && originalOptionMonth == null) {
            const map = acc.basis_map;

            if(!map[commodityId]) map[commodityId] = {};
            if(!map[commodityId][currentFrontMonth]) map[commodityId][currentFrontMonth] = { quantity: new Decimal(0), rows: [] };

            map[commodityId][currentFrontMonth].quantity = map[commodityId][currentFrontMonth].quantity.plus(parsedQuantity);
            map[commodityId][currentFrontMonth].rows.push(row as any);
        }

        return acc;
    }, { SkippedRows: [], UnknownRows: [], dp_map: {}, basis_map: {} } as any);
}

function aggregatePurchaseReport(purchaseRelease: PurchaseReleaseRow[], startDate: Date, debugWorkbook: any, overrides: OptionOverrides) {
    const longCashHta = debugWorkbook.addWorksheet("Long Cash HTA_Purchase Report");
    const longCashBasis = debugWorkbook.addWorksheet("Long Cash Basis_Purchase Report");

    longCashHta.addRow(["Type", "Commodity", "OG-Quantity", "OG-OptionMonth", "UOM", "Quantity", "Option Month", "Basis", "Futures"]);
    longCashBasis.addRow(["Type", "Commodity", "OG-Quantity", "OG-OptionMonth", "UOM", "Quantity", "Option Month", "Basis", "Futures"]);

    return purchaseRelease.reduce((acc: PurchaseReleaseResult, row: PurchaseReleaseRow) => {
        const cont_type = row[`Cont`]?.trim()?.toLocaleLowerCase();

        if (!cont_type) {
            acc.UnknownRows.push({ row, reason: ErrorReason.MissingContColumn });
            return acc;
        }

        if (shouldSkipCommodity(row[`Commodity`]?.trim())) { return acc; }
        const commodityId = getCommodity(row[`Commodity`]?.trim());

        const currentFrontMonth = determineCurrentFrontMonth(commodityId, startDate, overrides);

        const quantity = row[`Quantity`];
        const uom = row[`Ct UOM`]?.trim();

        if (!commodityId) {
            acc.UnknownRows.push({ row, reason: ErrorReason.MissingCommodityIdColumn });
            return acc;
        }

        if (!quantity) {
            acc.UnknownRows.push({ row, reason: ErrorReason.MissingQuantityColumn });
            return acc;
        }

        const originalOptionMonth = row[`Opt  Mth`]?.trim();
        if (shouldSkipOptionMonth(originalOptionMonth)) { return acc; }

        const parsedQuantity = safeParseQuantityWithUom({ amount: quantity, commodity: commodityId, uom });
        let optionMonth = parseAndRollNearestOptionMonth(originalOptionMonth, commodityId, currentFrontMonth)!;
        const isFrontMonth = optionMonth === currentFrontMonth;

        const futures = row[`Futures`];
        const basis = row[`Basis`];
        const colG = row[`Id`]?.trim();
        const colH = row[`Name`]?.trim();

        const marketZone = row[`Market Zone`]?.trim()?.toLocaleLowerCase();
        const prStatus = row[`Pr  Status`]?.trim()?.toLocaleLowerCase();

        const basisDecimal = safeParseDecimal(row[`Basis`]);

        if (colG?.toLocaleLowerCase() === "1tbd") {
            return acc;
        }
        if (colH?.toLocaleLowerCase() === "tbd") {
            return acc;
        }

// else if (cont_type.startsWith("beaconpric") && marketZone === 'hta') {
//             const map = acc.hta_map; // 1783463-1648463=135000

//             if (!optionMonth) {
//                 acc.UnknownRows.push({ row, reason: ErrorReason.MissingOptionMonthColumn });
//                 return acc;
//             }

//             if(!map[commodityId]) map[commodityId] = {};
//             if(!map[commodityId][optionMonth]) map[commodityId][optionMonth] = { quantity: new Decimal(0), rows: [] };

//             // TODO call a different function to check for errors like NaN
//             // const parsedQuantity = safeParseQuantityWithUom({ amount: quantity, commodity: commodityId, uom });
//             longCashHta.addRow([cont_type, commodityId, quantity, originalOptionMonth, uom, parsedQuantity.toNumber(), optionMonth, basis, futures])

//             map[commodityId][optionMonth].quantity = map[commodityId][optionMonth].quantity.plus(parsedQuantity);
//             map[commodityId][optionMonth].formattedQuantity = map[commodityId][optionMonth].quantity.toFixed();
//             map[commodityId][optionMonth].rows.push(row);
//         }


// else if (cont_type.startsWith("otc") && basis == null && !!originalOptionMonth) {
//             const map = acc.otc_map;

//             if (!originalOptionMonth) {
//                 acc.UnknownRows.push({ row, reason: ErrorReason.MissingOptionMonthColumn });
//                 return acc;
//             }

//             if(!map[commodityId]) map[commodityId] = {};
//             if(!map[commodityId][optionMonth]) map[commodityId][optionMonth] = { quantity: new Decimal(0), rows: [] };

//             // TODO call a different function to check for errors like NaN
//             // const parsedQuantity = safeParseQuantityWithUom({ amount: quantity, commodity: commodityId, uom });
//             longCashHta.addRow([cont_type, commodityId, quantity, originalOptionMonth, uom, parsedQuantity.toNumber(), optionMonth, basis, futures])

//             map[commodityId][optionMonth].quantity = map[commodityId][optionMonth].quantity.plus(parsedQuantity);
//             map[commodityId][optionMonth].formattedQuantity = map[commodityId][optionMonth].quantity.toFixed();
//             map[commodityId][optionMonth].rows.push(row);

//             // TODO add DP to first rolled month
//             // skip 1TBD for vehicles (col G) (Id)
//         }

        if ((prStatus.startsWith("hta") || prStatus === "pp") && basis == null && !!originalOptionMonth) {
            const map = acc.hta_map;

            if (!optionMonth) {
                acc.UnknownRows.push({ row, reason: ErrorReason.MissingOptionMonthColumn });
                return acc;
            }

            if(!map[commodityId]) map[commodityId] = {};
            if(!map[commodityId][optionMonth]) map[commodityId][optionMonth] = { quantity: new Decimal(0), rows: [] };

            // TODO call a different function to check for errors like NaN
            // const parsedQuantity = safeParseQuantityWithUom({ amount: quantity, commodity: commodityId, uom });
            longCashHta.addRow([cont_type, commodityId, quantity, originalOptionMonth, uom, parsedQuantity.toNumber(), optionMonth, basis, futures])

            map[commodityId][optionMonth].quantity = map[commodityId][optionMonth].quantity.plus(parsedQuantity);
            map[commodityId][optionMonth].formattedQuantity = map[commodityId][optionMonth].quantity.toFixed();
            map[commodityId][optionMonth].rows.push(row);
        }  else if (["basis", "dp"].some(item => cont_type.startsWith(item)) && futures == null && isFrontMonth) {
            const map = acc.basis_map;

            if(!map[commodityId]) map[commodityId] = {};
            if(!map[commodityId][optionMonth]) map[commodityId][optionMonth] = { quantity: new Decimal(0), rows: [] };

            // const parsedQuantity = safeParseDecimal(quantity);
            longCashBasis.addRow([cont_type, commodityId, quantity, originalOptionMonth, uom, parsedQuantity.toNumber(), optionMonth, basis, futures])

            map[commodityId][optionMonth].quantity = map[commodityId][optionMonth].quantity.plus(parsedQuantity);
            map[commodityId][optionMonth].rows.push(row as any);
        } else if (basis != null && futures == null) { // !isFrontMonth
             const map = acc.basis_map;

            if(!map[commodityId]) map[commodityId] = {};
            if(!map[commodityId][optionMonth]) map[commodityId][optionMonth] = { quantity: new Decimal(0), rows: [] };

            // const parsedQuantity = safeParseDecimal(quantity);
            longCashBasis.addRow([cont_type, commodityId, quantity, originalOptionMonth, uom, parsedQuantity.toNumber(), optionMonth, basis, futures])

            map[commodityId][optionMonth].quantity = map[commodityId][optionMonth].quantity.plus(parsedQuantity);
            map[commodityId][optionMonth].rows.push(row as any);
        } else if (cont_type.startsWith("dp") && originalOptionMonth == null) {
            const map = acc.basis_map;

            if(!map[commodityId]) map[commodityId] = {};
            if(!map[commodityId][currentFrontMonth]) map[commodityId][currentFrontMonth] = { quantity: new Decimal(0), rows: [] };

            // const parsedQuantity = safeParseDecimal(quantity);
            longCashBasis.addRow([cont_type, commodityId, quantity, currentFrontMonth, uom, parsedQuantity.toNumber(), currentFrontMonth, basis, futures])

            map[commodityId][currentFrontMonth].quantity = map[commodityId][currentFrontMonth].quantity.plus(parsedQuantity);
            map[commodityId][currentFrontMonth].rows.push(row as any);
        }

        return acc;
    }, { SkippedRows: [], UnknownRows: [], dp_map: {}, otc_map: {}, hta_map: {}, basis_map: {} } as any);
}

export function parseOptionMonth({ originalRow, commodityId }: { originalRow: string; commodityId: string; }) {
    if (!originalRow) return "";
    if (commodityId === "W" && !!["KW", "WW", "MW"].some(code => originalRow.startsWith(code))) return originalRow.slice(2);
    if (!originalRow.startsWith(commodityId)) return originalRow;
    return originalRow.slice(commodityId.length);
}

function shouldSkipOptionMonth(originalOptionMonth: string) {
    if (!originalOptionMonth) return false;
    if (originalOptionMonth.startsWith("KW")) return false;
    return false;
}

function shouldSkipCommodity(original: string) {
    if (!original) return false;
    if (original === "WW") return true;
    return false;
}

export function parseMarketZoneOptionMonth({ originalRow, commodityId }: { originalRow: string; commodityId: string; }) {
    if (!originalRow) return "";
    const splits = originalRow.split("/");
    if (splits.length < 2) { return originalRow; }

    const baseMonth = splits[0];
    const monthMoment = moment.tz(`2024-${baseMonth}-01T00:00:00`, "YYYY-MMM-DDT00:00:00", CommonDestinationTimezone);

    if (!monthMoment.isValid()) {
        return "";
    }

    const monthLetter = monthNumberToOptionLetter[monthMoment.month() + 1];
    const yearLastDigit = splits[1].slice(1);

    return `${monthLetter}${yearLastDigit}`;
}

function sortBasicOptionMonths(a: string, b: string) {
    const aYear = a.slice(-1);
    const bYear = b.slice(-1);

    if (aYear < bYear) return -1;
    if (aYear > bYear) return 1;

    const aMonth = a.slice(0,1);
    const bMonth = b.slice(0,1);

    if (aMonth < bMonth) return -1;
    if (aMonth > bMonth) return 1;

    return 0;
}

function safeReduceDecimal(acc: Decimal, item: any) {
    return acc.plus(safeParseDecimal(item));
}

function getSafeValue<T>(anArray: T[], index: number) {
    if (index >= anArray.length) {
        return undefined;
    }
    return anArray[index];
}

// NOTE: try to convert other units to BU
function safeParseQuantityWithUom({ amount, uom, commodity }: { amount: string | number | Decimal; uom?: string; commodity?: string }) {
    const parsedAmount = safeParseDecimal(amount);
    const comparableUom = uom?.toLocaleLowerCase();

    // https://grains.org/markets-tools-data/tools/converting-grain-units/
    // NOTE: This assumes Metric Ton not British Tonne, Long Ton, American or Short Ton,
    if (commodity === Commodities.Corn) {
        if (comparableUom === "mt") {
            return parsedAmount.times(39.368)
        } else if (comparableUom === "lb") {
            return parsedAmount.div(56)
        }
    }

    // else if ([Commodities.Soybeans, Commodities.WinterWheat, Commodities.MinneapolisWheat].includes(commodity! as any)) {
    //     if (comparableUom === "mt") {
    //         return parsedAmount.times(36.7437)
    //     }
    //     if (comparableUom === "lb") {
    //         return parsedAmount.div(60)
    //     }
    // } else if ([Commodities.Canola].includes(commodity! as any)) {
    //      if (comparableUom === "mt") {
    //         return parsedAmount.times(1)
    //     }
    //     if (comparableUom === "lb") {
    //         return parsedAmount.div(2204.62)
    //     }
    // }

    return parsedAmount;
}

function safeParseWithUom({ amount, uom }: { amount: string | number | Decimal; uom?: string; }) {
    const parsedAmount = safeParseDecimal(amount);
    return parsedAmount;
}

function safeParseWithCommodity({ amount, commodity }: { amount: string | number | Decimal; commodity?: string }) {
    return safeParseDecimal(amount);
}

function letterMonthAbbrevToNumber(month: string) {
 return moment.tz(`2024-${month}-01T00:00:00`, "YYYY-MMM-DDT00:00:00", CommonDestinationTimezone).month() || 0;
}

function generateFirstNAndBeyond(commodityId: string, amount: number, frontMonth: string) {
    return generateOptionMonthList(commodityId).filter(isOptionMonthPastOther(frontMonth)).slice(0, amount);
}

function parseAndRollNearestOptionMonth(optionMonth: string, commodityId: string, frontMonth: string) {
    const parsedMonth = parseOptionMonth({ originalRow: optionMonth, commodityId });
    const parsedCombo = [optionLetterToMonthNumber[parsedMonth[0]], parsedMonth[1]].map(safeParseDecimal).map(item => item.toNumber());

    const monthsList = generateOptionMonthList(commodityId).map((combo) => (
        [optionLetterToMonthNumber[combo[0]], combo[1]]
            .map(safeParseDecimal)
            .map(item => item.toNumber())
    ));

    const item = monthsList.find((combo) => {
        return parsedCombo[1] <= combo[1] && parsedCombo[0] <= combo[0];
    })!;


    if (!item) {
        throw new ServerError("Expected to find a option month", { parsedMonth, optionMonth, item, monthsList })
    }

    if (item[1] < parseInt(frontMonth[1])) {
        return frontMonth;
    }

    if (item[1] <= parseInt(frontMonth[1]) && monthNumberToOptionLetter[item[0]] <= frontMonth[0]) {
        return frontMonth;
    }

    return [monthNumberToOptionLetter[item[0]], item[1]]?.join("")!;
}

// ======================================================
//    Calculate Option Month
// ======================================================
// TODO Starting by calculating the front month by end of last month
// TODO Later convert current date to using expiration of the security to get front month
// TODO support end of decade roll
function determineCurrentFrontMonth(commodityId: string, startDate: Date, overrides?: OptionOverrides) {
    const monthRollOrder = generateYearsListFrom(commodityId, startDate);
    return monthRollOrder.find(isOptionMonthPastOther(overrides?.optionMonths?.[commodityId])) || monthRollOrder[0];
}

function isOptionMonthPastOther(comparableMonth?: string) {
    return function isOptionMonthPastOtherHelper(baseMonth: string) {
        if (!comparableMonth) return true;

        const year = parseInt(baseMonth[1]);
        const month = optionLetterToMonthNumber[baseMonth[0]];

        const comparableYear = parseInt(comparableMonth[1]);
        const comparableMonthNumber = optionLetterToMonthNumber[comparableMonth[0]];

        if (year < comparableYear) return false;
        if (year > comparableYear) return true;

        return month >= comparableMonthNumber;

    }
}


function getCommodityContractMonths(row: { Commodity: string }) {
    // https://www.cmegroup.com/markets/agriculture/oilseeds/soybean.calendar.html
    const soybeanMonths = ["Jan", "Mar", "May", "Jul", "Aug", "Sep", "Nov"];
    // https://www.ice.com/products/251/Canola-Futures/expiry
    const canolaMonths = ["Jan", "Mar", "May", "July", "Nov"];
    // https://www.cmegroup.com/markets/agriculture/grains/wheat.calendar.html
    // https://www.cmegroup.com/markets/agriculture/grains/corn.calendar.html
    const genericAgriMonths = ["Mar", "May", "Jul", "Sep", "Dec"];

    const commodityId = row[`Commodity`]?.trim();

    switch (commodityId) {
        case Commodities.Soybeans:
            return soybeanMonths;
        case Commodities.Canola:
            return canolaMonths;
        default:
            return genericAgriMonths;
    }
}

function getDisplayCommodity(commodityId: string) {
    if (["MW", "WW", "W"].includes(commodityId)) return "Wheat";
    return CommodityOptions.find(option => option.value === commodityId)?.label || ""
}

function getCommodity(commodityId: string) {
    if (["MW", "WW"].includes(commodityId)) return "W";
    return commodityId;
}

function generateYearsList(commodityId: string) {
    const currentTwoDigitYear = moment.tz(CommonDestinationTimezone).format("YYYY").slice(-2);
    const currentYear = moment.tz(CommonDestinationTimezone).format("YYYY").slice(-1);
    const nextYear = moment.tz(CommonDestinationTimezone).add(1, "year").format("YYYY").slice(-1);

    const orderedMonths = [
        ...getCommodityContractMonths({ Commodity: commodityId }).map((month: string) => {
            return `${monthNumberToOptionLetter[letterMonthAbbrevToNumber(month) + 1]}${currentYear}`;
        }),
        ...getCommodityContractMonths({ Commodity: commodityId }).map((month: string) => {
            return `${monthNumberToOptionLetter[letterMonthAbbrevToNumber(month) + 1]}${nextYear}`;
        }),
        ...getCommodityContractMonths({ Commodity: commodityId }).map((month: string) => {
            return `${monthNumberToOptionLetter[letterMonthAbbrevToNumber(month) + 1]}${moment.tz(CommonDestinationTimezone).add(2, "year").format("YYYY").slice(-1)}`;
        }),
    ];

    return orderedMonths;
}

function generateYearsListFrom(commodityId: string, startDate: Date, frontMonth?: string) {
    //  TODO calculate front month based off expiration
    const yearsList = generateYearsList(commodityId);

    const twoYearFormat = moment.tz(startDate, CommonDestinationTimezone).format("YYYY").slice(-2);
    const monthNumber = moment.tz(startDate,CommonDestinationTimezone).month() + 1;
    const monthLetterCombo = `${monthNumberToOptionLetter[monthNumber]}${twoYearFormat.slice(-1)}`;

    const result = yearsList.filter((combo: any) => {
        const comboOptionMonth = `${combo[0]}${combo[1]}`;
        if (frontMonth) {
            if (combo[1] < frontMonth[1]) return false;
            if (combo[1] > frontMonth[1]) return true;
            return optionLetterToMonthNumber[combo[0]] >= optionLetterToMonthNumber[frontMonth[0]];
        }

        if (comboOptionMonth[1] > monthLetterCombo[1]) { return true; }
        if (comboOptionMonth[1] < monthLetterCombo[1]) { return false; }

        return comboOptionMonth[0] >= monthLetterCombo[0];
    });

    return result
}

// NOTE: Takes in list of months U4, Z4 etc. Takes in option month, 8, 4. get next front month
function rollMonthYearNumber(monthList: string[], month: number, year: number)  {
    return monthList.find(combo => {
        const comboYear = parseInt(combo[1]);
        const comboMonthNumber = optionLetterToMonthNumber[combo[0]];

        if (comboYear > year) return true;
        if (comboYear < year) return false;

        return month <= comboMonthNumber;
    })!;
}

function generateOptionMonthList(commodityId: string) {
    const base = moment.tz(CommonDestinationTimezone).add(1, "month");
    const currentTwoDigitYear = moment(base).format("YYYY").slice(-2);
    const currentYear = moment(base).format("YYYY").slice(-1);

    const currentUnfixedOptionMonth = `${moment(base).format("MMM")}/${moment(base).format("YYYY").slice(-2)}`;
    const currentOptionMonth = Object.keys(rollRowCalendarMonthsIntoOptionMonths({ Commodity: commodityId, [currentUnfixedOptionMonth]: 1 }, [currentUnfixedOptionMonth], commodityId))[0];

    const orderedMonths = [
        ...getCommodityContractMonths({ Commodity: commodityId }).map((month: string) => {
            return `${monthNumberToOptionLetter[letterMonthAbbrevToNumber(month) + 1]}${currentYear}`;
        }),
        ...getCommodityContractMonths({ Commodity: commodityId }).map((month: string) => {
            return `${monthNumberToOptionLetter[letterMonthAbbrevToNumber(month) + 1]}${moment(base).add(1, "year").format("YYYY").slice(-1)}`;
        }),
        ...getCommodityContractMonths({ Commodity: commodityId }).map((month: string) => {
            return `${monthNumberToOptionLetter[letterMonthAbbrevToNumber(month) + 1]}${moment(base).add(2, "year").format("YYYY").slice(-1)}`;
        }),
    ].filter(item => {
        return true;
    });


    const currentIndex = orderedMonths.findIndex(combo => combo === currentOptionMonth);
    if (currentIndex < 0) throw new Error("Failed to find current option month");

    return orderedMonths.slice(currentIndex);
}



// NOTE: This doesnt account for inverse rolls.
// NOTE: This takes calendar months and aggregates the values for those calendar months under their corresponding
//   option month (relative that commodities option month).
//   Converts the option months from their column name, e.g. Jul/25 to their corresponding abbreviation. e.g. N5
function rollRowCalendarMonthsIntoOptionMonths(row: any, columns: string[], commodityId?: string) {
    const monthRollOrder = getCommodityContractMonths(row);
    const monthRollOrderNumbers = monthRollOrder.map((month: string) => moment.tz(`2024-${month}-01T00:00:00`, "YYYY-MMM-DDT00:00:00", CommonDestinationTimezone).month() || 0);

    return columns.reduce((acc, column: string) => {
        const aSplit = column.split("/");
        const currentNum = moment.tz(`2024-${aSplit[0]}-01T00:00:00`, "YYYY-MMM-DDT00:00:00", CommonDestinationTimezone).month() || 0;

        const rolledMonthNumber = monthRollOrderNumbers.find((aggNum: number) => currentNum <= aggNum)!;

        const monthLetter = rolledMonthNumber !== undefined ? `${monthNumberToOptionLetter[rolledMonthNumber + 1]}${aSplit[1].slice(1)}` : `${monthNumberToOptionLetter[monthRollOrderNumbers[0] + 1]}${parseInt(aSplit[1].slice(1)) + 1}`;

        const amount = safeParseDecimal((row as any)[column]);

        if (!acc[monthLetter]) acc[monthLetter] = new Decimal(0);
        acc[monthLetter] = acc[monthLetter].plus(amount);

        return acc;
    }, {} as Record<string, Decimal>);
}

export function generateDefaultFrontMonth({ commodity }: { commodity: Commodities }) {
    switch(commodity) {
        case Commodities.Canola:
        case Commodities.Soybeans:
            return generateYearsListFrom(commodity, new Date())[0]
        default:
            return generateYearsListFrom(commodity, new Date())[0]
    }
}
