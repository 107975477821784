import { z } from "zod";

export function getPath(obj: Record<string, any>, path: string[], defaultValue?: any) {
    const result = path.reduce((acc, key) => {
        if (acc && key in acc) {
            return acc[key];
        }
        return undefined;
    }, obj);

  if (result == null) {
    return result || defaultValue;
  }

  return result;
}

export function sortByArrayLength(key: string) {
    return function sortByArrayLengthHelper(a: any, b: any) {
        const aItem = getPath(a, key.split("."));
        const bItem = getPath(b, key.split("."));
        const aLength = Array.isArray(aItem) ? aItem.length : Infinity;
        const bLength = Array.isArray(bItem) ? bItem.length : Infinity;
        return aLength - bLength;
    }
}

export function isValidEmail(email?: string): boolean {
  if (!email?.trim()) return true;

  const result = z
    .string()
    .min(1, { message: "This field has to be filled." })
    .email("This is not a valid email.")
    .safeParse(email);

  return result.success;
}

export function findById(id: string) {
  return function findByIdHelper<T extends { id: string }>(items: T[]) {
    return items.find(item => item.id === id);
  }
}

export function findByKey(key: string, value: any) {
  return function findByKeyHelper<T>(items: T[]): any {
    return items.find((item: any) => item[key] === value);
  }
}