import { useQuery, useQueryClient } from "@tanstack/react-query";
import { S3ObjectItem } from "../models/trading";
import { ProjectRouteName } from "../models/analystRecommendation";
import {
  fetchApiDelete,
  fetchApiGet,
  fetchApiPost,
  fetchApiPut,
} from "./utils";
import { z } from "zod";
const ENTITY_KEY = "trading";

export const AcknowledgedJobValidation = z.object({
  id: z.string().uuid(),
  type: z.enum(['queue_job', 'ticket_job']),
  reason: z.string().optional(),
});

const TicketJobActionValidation = z.object({
  type: z.literal("ticket_job"),
  action: z.enum(['replay_incoming_ticket_s3_bucket_event']),
});

const QueueJobActionValidation = z.object({
  type: z.literal('queue_job'),
  action: z.enum(['reset_to_fresh_queued']),
});

export const ActionOnJobValidation = z.object({
  id: z.string().uuid(),
  should_acknowledge: z.boolean(),
  reason: z.string().optional(),
  action_info: TicketJobActionValidation.or(QueueJobActionValidation),
});


export function useCreateZohoSegment(projectName: ProjectRouteName) {
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams();

  return async () => {
    const result = await fetchApiPost<{ id: string }>(
      `developer/communications/zoho/${projectName}/segment`,
      {},
      queryParams,
    );
    return result;
  };
}

export function useManualCHS() {
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams();

  // TODO convert to post
  return async () => {
    const result = await fetchApiGet<{}>(`/tickets/manual/chs`, queryParams);
    return result;
  };
}

export function useRefreshZohoMailingList(projectName: ProjectRouteName) {
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams();

  return async () => {
    const result = await fetchApiPost<{ id: string }>(
      `developer/communications/zoho/${projectName}/refreshMailingList`,
      {},
      queryParams,
    );
    return result;
  };
}

export function useForceSendTodaysAnalysisComms() {
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams();

  return async () => {
    const result = await fetchApiPost<{ id: string }>(
      "developer/communications/zoho/sendTodaysAnalysisComms",
      {},
      queryParams,
    );
    return result;
  };
}

export function useUpdateTicketProviderDynamicMappings() {
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams();

  return async () => {
    const result = await fetchApiPost<{ id: string }>(
      "developer/ticket/providers/dynamicMappings/create",
      {},
      queryParams,
    );
    return result;
  };
}

export function useRunDataMigrationUpdateCropYears() {
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams();

  return async () => {
    const result = await fetchApiPost<{}>(
      "data-migrations/hedgebeacon/updateCropYear",
      {},
      queryParams,
    );
    return result;
  };
}

export function useAtsReplayEvents() {
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams();

  return async () => {
    const result = await fetchApiPost<{}>(
      "developer/ats/events/replay",
      {},
      queryParams,
    );
    return result;
  };
}

export function useListJobsDashboardInfo() {
  const queryParams = new URLSearchParams();

  return useQuery({
    queryKey: ["developer", "jobs", "dashboard"],
    queryFn: () =>
      fetchApiGet<any>("developer/jobs/dashboard", queryParams),
    retry: 1,
  });
}

export function useAcknowledgeJob() {
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams();

  return async (data: typeof AcknowledgedJobValidation._type) => {
    const result = await fetchApiPost<{}>(`developer/jobs/${data.id}/acknowledge`, data, queryParams);
    queryClient.invalidateQueries({ queryKey: ["developer", "jobs", "dashboard"] });

    return result;
  };
}

export function useActionOnJob() {
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams();

  return async (data: typeof ActionOnJobValidation._type) => {
    const result = await fetchApiPost<{}>(`developer/jobs/${data.id}/action`, data, queryParams);
    queryClient.invalidateQueries({ queryKey: ["developer", "jobs", "dashboard"] });

    return result;
  };
}
