import { useQuery, useQueryClient, QueryKey } from "@tanstack/react-query";
import Decimal from "decimal.js";
import { BaseDbObject } from "../models/db";
import { Customer } from "./reports";
import { z } from "zod";
import moment, { Moment } from "moment-timezone";
import {
  fetchApiDelete,
  fetchApiGet,
  fetchApiPost,
  fetchApiPut,
} from "./utils";
import {
  PossibleReturnType,
} from "../responseResults";
import { CommonDestinationTimezone } from "../utils";

export interface DestinationPairException {
  exception_types: ("market_zone" | "producer_or_carrier" | "no_pair" | "no_phone_number")[];
  has_market_zone: boolean;
  has_producer_or_carrier: boolean;
  market_zone_id?: string;
  producer_or_carrier_id?: string;
  original_market_zone?: string;
  original_producer_or_carrier_name?: string;
  original_producer_or_carrier_id?: string;
  commodity: string;
}

export interface InternalUser {
  id: string;
  name: string;
}

export interface BaseInfo {
    customers: {
        id: string;
        client_id: string;
        long_name: string;
    }[]
    market_zones: {
        id: string;
        dtn_market_zone: string;
        customer_id: string;
    }[]
}

export interface StagedRowData {
    destination_id: string;
    commodity: string;

    market_zone_id: string;

    producer_or_carrier_id: string;
    is_enabled: boolean;
}

export interface FutureRowData {
    destination_id: string;
    commodity: string;

    market_zone_id: string;

    producer_or_carrier_id?: string;
    internal_user_id?: string;
    is_enabled: boolean;
}

export interface HistoricalData {
    id: string;
    destination_id: string;
    message: string;
    content?: {
      meta_data?: {
        body: string;
      }
    }
    send_at: string;

    DestinationMessageReceivers: HistoricalMessageReceiver[];
}

export interface HistoricalMessageReceiver extends BaseDbObject {
  destination_message_id: string;
  receiver_id?: string;
  internal_user_id?: string;
  commodity: string;
}

export interface FutureRow {
    data: FutureRowData,
    id: string;
}

export interface StagedRow {
    data: StagedRowData,
    user_id: string;
    id: string;
}

export const FilterValidation = z.object({
    destination_id: z.string().optional(),
    commodity: z.string().optional(),
});

export const SendDestinationMessagesForValidation = z.object({
  is_test: z.boolean(),
  commodities: z.array(z.string()),
  destination_id: z.string().uuid(),
  internal_user_ids: z.array(z.string().uuid()),
  send_at: z.string().optional(),
  send_at_time: z.string().regex(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/).optional(),
  message: z.string().min(5),
});

export type FilterOptions = z.infer<typeof FilterValidation>;

export function useListBaseData() {
  const queryParams = new URLSearchParams();

  return useQuery({
    queryKey: ["destination", "base_data"],
    queryFn: () =>
      fetchApiGet<BaseInfo[]>("destination/messaging/base", queryParams),
    retry: 1,
  });
}

export function useListFutureData(send_date?: string) {
  const queryParams = new URLSearchParams();

  return useQuery({
    queryKey: ["destination", "future", queryParams.toString()],
    queryFn: () => fetchApiGet<{ messages: HistoricalData[]; internal_users: InternalUser[] }>("destination/messaging/future", queryParams),
    retry: 1,
  });
}

export function useListHistoricalData(send_date?: string) {
  const queryParams = new URLSearchParams();
  if (send_date) {
    queryParams.append("sent_date", send_date);
  }

  return useQuery({
    queryKey: ["destination", "historical", queryParams.toString()],
    queryFn: () => fetchApiGet<{ messages: HistoricalData[]; internal_users: InternalUser[] }>("destination/messaging/history", queryParams),
    enabled: !!send_date,
    retry: 1,
  });
}

export function useListStagedData(filters: typeof FilterValidation._type) {
  const queryParams = new URLSearchParams();
  queryParams.append("filters", JSON.stringify(filters))

  return useQuery({
    queryKey: ["destination", "staged", queryParams.toString()],
    queryFn: () =>
      fetchApiGet<{ data: StagedRow[]; exceptions: any[] }>("destination/messaging/staged", queryParams),
    retry: 1,
  });
}

export function useListInternalUsers() {
  const queryParams = new URLSearchParams();

  return useQuery({
    queryKey: ["destination", "internal_users"],
    queryFn: () =>
      fetchApiGet<InternalUser[]>("destination/messaging/internal", queryParams),
    retry: 1,
  });
}

export function useManageException() {
  const queryClient = useQueryClient();

  return async (body: any, params: { exception_type: string }) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<any>(
      `destination/messaging/exception/manage`,
      {
        id: body.id,
        exception_type: params.exception_type,
        exception_id: body.exception_id,
        data: body,
      },
      queryParams,
    );

    queryClient.invalidateQueries({ queryKey: ["destination", "staged"] });

    return result;
  };
}

export function useUpdateFutureMessage() {
  const queryClient = useQueryClient();
  return async ({ parentJobId, message }: { parentJobId: string; message: string; }) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPut<any>(
      `destination/messaging/future/${parentJobId}/update`,
      { message, parent_job_id: parentJobId },
      queryParams,
    );

    queryClient.invalidateQueries({ queryKey: ["destination", "future"] });

    return result;
  };
}

export function useCancelFutureMessage() {
  const queryClient = useQueryClient();
  return async (parentJobId: string) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiDelete<any>(
      `destination/messaging/future/${parentJobId}/cancel`,
      queryParams,
    );

    queryClient.invalidateQueries({ queryKey: ["destination", "future"] });

    return result;
  };
}

export function useCreateUploadJob() {
  const queryClient = useQueryClient();

  return async (body: {
    contract_release_filename: string;
    contract_dp_release_filename: string;
    freight_orders_filename: string;
    start_date_filter?: string;
    end_date_filter: string;
  }) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<any>(
      `destination/messaging/upload/presign`,
      body,
      queryParams,
    );

    queryClient.invalidateQueries({ queryKey: ["destination", "staged"] });

    return result;
  };
}

export function useUpdateEnabledBulkStatus() {
  const queryClient = useQueryClient();

  return async (ids: string[], enabled: boolean) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<any>(
      `destination/messaging/staged/bulk/status`,
      { ids, enabled },
      queryParams,
    );

    queryClient.invalidateQueries({ queryKey: ["destination", "staged"] });

    return result;
  };
}

export function useUpdateEnabledStatus() {
  const queryClient = useQueryClient();

  return async (id: string, enabled: boolean) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<any>(
      `destination/messaging/staged/${id}/status`,
      { id, enabled },
      queryParams,
    );

    queryClient.invalidateQueries({ queryKey: ["destination", "staged"] });

    return result;
  };
}

export function useSendMessage() {
  const queryClient = useQueryClient();

  return async (body: z.infer<typeof SendDestinationMessagesForValidation>) => {
    const queryParams = new URLSearchParams();
    const validatedBody = SendDestinationMessagesForValidation.parse(body);
    const { destination_id } = validatedBody;

    const result = await fetchApiPost<any>(
      `destination/messaging/staged/${destination_id}/send`,
      validatedBody,
      queryParams,
    );

    queryClient.invalidateQueries({ queryKey: ["destination", "staged"] });
    queryClient.invalidateQueries({ queryKey: ["destination", "historical"] });
    queryClient.invalidateQueries({ queryKey: ["destination", "future"] });

    return result;
  }
} 

export function useGenerateTwilioReport() {
  const queryClient = useQueryClient();

  return async (date_sent: Date) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<any>(
      `destination/messaging/twilio/report`,
      { date_sent: date_sent.toISOString() },
      queryParams,
    );

    return result;
  }
}
