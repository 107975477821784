import { InputType, InputFormType, InputRequiredStatus } from "@/lib/form";
import { generateBaseState } from "@/lib/form";
import { FormGridHelper } from "@/section/reports/helper";

const MessagingConsentSchema = {
  email: {
    label: "Email",
    input_type: InputType.String,
    input_type_validation: InputFormType.String,
    required_status: InputRequiredStatus.Required,
    placeholder: "jane.doe@gmail.com",
  },
  phone_number: {
    label: "Phone Number",
    input_type: InputType.String,
    input_type_validation: InputFormType.String,
    required_status: InputRequiredStatus.Required,
      placeholder: "+1 (111) 222 - 3333",
  },
  consent: {
    label: "SMS Consent",
    input_type: InputType.Boolean,
    input_type_validation: InputFormType.Boolean,
    required_status: InputRequiredStatus.Required,
    description: "I agree to receive SMS messages relating to destination info/updates/hours and understand that message and data rates may apply. You can unsubscribe in your account settings at any time or by replying STOP.",
  },
};

const formState = generateBaseState({
  schemas: [MessagingConsentSchema],
  baseSchema: MessagingConsentSchema,
});

export function DestinationMessagingConsent() {
  const onSubmit = async (values: any) => {
    // Handle form submission
    return { success: true };
  };

  return (
    <section className="flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
      <img src="https://lhlpublicwebimages.s3.us-west-2.amazonaws.com/lighthouse_logo_2x.png" alt="Lighthouse Logo" className="w-[200px] h-[200px]" />
      <div className="w-full">
        <FormGridHelper
          onCloseForm={() => {}}
          state={formState}
          activeSchema={MessagingConsentSchema}
          id="MessagingConsent"
          externalReferences={{}}
          failMessage="Failed to submit form"
          successMessage="Form submitted successfully"
          onSubmitTo={onSubmit}
        />
      </div>
      <a href="/privacy-policy.pdf" className="underline text-sm text-gray-500">Privacy Policy</a>
    </section>
  );
}
