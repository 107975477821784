import { useQuery, useQueryClient, QueryKey } from "@tanstack/react-query";
import hashObject from "object-hash";
import { useState, useEffect } from 'react';
import moment, { Moment } from "moment-timezone";
import ExcelJS from "exceljs";
import { z } from "zod";
import Decimal from "decimal.js";
import * as Excel from "../excel";
import { S3ObjectItem } from "../models/trading";
import { BaseDbObject } from "../models/db";
import { DbUser } from "../models/index";
import {
  fetchApiDelete,
  fetchApiGet,
  fetchApiPost,
  fetchApiPut,
} from "./utils";
import {
  PossibleReturnType,
} from "../responseResults";
import { CommonDestinationTimezone } from "../utils";
import { Customer, Trader } from "./reports";
import { Commodities, CommodityOptions } from "./commodity";
import { formatDateWithoutTimezone } from "./date";
import {
  findMatchOption,
  OptionMonthOptions, OlderOptionMonthOptions, formulateOptionMonths, optionLetterToMonthNumber, futureMonths, generateMonthOptionsFor, generateCurrentAndFutureMonthOptions, monthNumberToOptionLetter, generateAccountLabel
} from "./common"

export {
  findMatchOption,
  CommodityOptions,
OptionMonthOptions, OlderOptionMonthOptions, formulateOptionMonths, optionLetterToMonthNumber, futureMonths, generateMonthOptionsFor, generateCurrentAndFutureMonthOptions, monthNumberToOptionLetter, generateAccountLabel
};

export const posNumberKeys = ["bushel", "long_qty", "short_qty", "strike_price", "price", "premium"];
export const contractNumberKeys = [
  "quantity",
  "step_one",
  "step_two",
  "step_three",
  "option_premium",
  "contract_fee",
  "futures_price",
  "otc_futures",
  "weeks",
  "period_qty",
  "knockout",
];
const otcOpenContractBaseKey = "otcOpenContracts";
const otcOpenPositionsBaseKey = "otcOpenPositions";

export function useListAccounts(listOtcAccountsOnly: boolean) {
  const queryParams = new URLSearchParams();
  if (listOtcAccountsOnly) {
    queryParams.append("listOtcAccountsOnly", "true");
  }

  return useQuery({
    queryKey: ["otcTradingAccounts", queryParams.toString()],
    queryFn: () =>
      fetchApiGet<TradingAccount[]>("otc/open_position_filters", queryParams),
    retry: 1,
  });
}

function getOpenPositionsQueryParams(
  params: PositionListOptionParams,
): URLSearchParams {
  const queryParams = new URLSearchParams();
  if (params.listOtcAccountsOnly) {
    queryParams.append("listOtcAccountsOnly", "true");
  }

  if ((params.filters?.account_ids as string[])?.length > 0) {
    queryParams.append("account_ids", params.filters!.account_ids!.join(","));
  }
  [
    { key: "strike_price", value: params.filters?.strike_price },
    { key: "commodity", value: params.filters?.commodity },
    { key: "option_month", value: params.filters?.option_month },
  ].forEach((item: any) => {
    if (item.value) {
      queryParams.append(item.key, item.value);
    }
  });

  return queryParams;
}

export function useListClosedContracts(params: ContractListOptionParams) {
  const queryParams = getOpenContractQueryParams(params);

  return useQuery({
    queryKey: ["otc", "closed", queryParams.toString()],
    queryFn: () =>
      fetchApiGet<any>("otc/open_contracts/closed", queryParams),
    retry: 1,
  });
}

export function useUnArchiveContract() {
  const queryClient = useQueryClient();

  return async (id: string) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<boolean>(
      `otc/open_contracts/${id}/unarchive`,
      {},
      queryParams,
    );
    queryClient.invalidateQueries({ queryKey: ["otc", "closed"] });

    return result;
  };
}

// NOTE: these two dont use react-query for performance reasons
export function useListOpenContracts(params: ContractListOptionParams) {
  const [data, setData] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState<any>(false);

  const fetchData = async () => {
    if (isLoading) return;
    try {
      setIsLoading(true);

      const queryParams = getOpenContractQueryParams(params);
      const result = await fetchApiGet<{
        pairedContracts: TradingContract[];
        unpairedContracts: TradingContract[];
      }>("otc/open_contracts_with", queryParams);;

      setData(result);
      setIsLoading(false);

    } catch(error) {
      setIsLoading(false);
      setData({ success: false, error });
    }
  };

    useEffect(() => {
      const intervalId = setInterval(() => { fetchData() }, 10 * 1000);
      return () => clearInterval(intervalId)
    }, [hashObject(params || {})]);

    useEffect(() => {
        fetchData();
    }, [hashObject(params || {})]);


    return { data, fetchData, isLoading } as any;
}

export function useListOpenPositions(params: PositionListOptionParams) {
  const [data, setData] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState<any>(false);

  const fetchData = async () => {
    if (isLoading) return;

    try {
      setIsLoading(true);

      const queryParams = getOpenPositionsQueryParams(params);
      const result = await fetchApiGet<{
        pairedPositions: TradingAccountOpenPosition[];
        unpairedPositions: TradingAccountOpenPosition[];
        pairs: PositionContractPair[];
      }>("otc/open_positions", queryParams)
      setData(result);
      setIsLoading(false);
    } catch(error) {
      setIsLoading(false);
      setData({ success: false, error });
    }
  };

    useEffect(() => {
      const intervalId = setInterval(() => { fetchData() }, 10 * 1000);
      return () => clearInterval(intervalId)
    }, [hashObject(params || {})]);

    useEffect(() => {
        fetchData();
    }, [hashObject(params || {})]);


    return { data, fetchData, isLoading } as any;
}

export function useDownloadMarginReportData() {
  const queryClient = useQueryClient();

  return async (params: MarginReportDownloadParams) => {
    const queryParams = getDownloadMarginReportQueryParams(params);

    const result = await fetchApiPost<string>(
      `otc/margin/download`,
      {},
      queryParams,
    );
    return result;
  };
}

export function useDownloadSummaryData() {
  const queryClient = useQueryClient();

  return async () => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<string>(
      `otc/summary/download`,
      {},
      queryParams,
    );
    return result;
  };
}

export function useDownloadOpenData() {
  const queryClient = useQueryClient();

  return async (params: typeof DownloadFilterValidation._type) => {
    const queryParams = getDownloadQueryParams(params);

    const result = await fetchApiPost<string>(
      `otc/download`,
      {},
      queryParams,
    );
    return result;
  };
}

export function useDownloadOpenRecentContractData() {
  const queryClient = useQueryClient();

  return async (params: { created_date: string }) => {
    const queryParams = new URLSearchParams();

    queryParams.append("created_date", params.created_date);

    const result = await fetchApiPost<{
      link: string;
      import_new_contracts_link: string;
      import_update_contracts_link: string;
    }>(
      `otc/open_contracts/download/recent`,
      {},
      queryParams,
    );
    return result;
  };
}

export function useDownloadOpenPositions() {
  const queryClient = useQueryClient();

  return async (params: PositionListOptionParams) => {
    const queryParams = getOpenPositionsQueryParams(params);

    const result = await fetchApiPost<string[]>(
      `otc/open_positions/download`,
      {},
      queryParams,
    );
    queryClient.invalidateQueries({ queryKey: [otcOpenPositionsBaseKey] });
    return result;
  };
}

export function useMarkPositionManualFullyPaired() {
  const queryClient = useQueryClient();

  return async (data: MarkPositionManualFullyPairedData) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<string[]>(
      `otc/open_positions/${data.position_id}/markFullyPaired`,
      data,
      queryParams,
    );

    queryClient.invalidateQueries({ queryKey: [otcOpenContractBaseKey] });
    queryClient.invalidateQueries({ queryKey: [otcOpenPositionsBaseKey] });

    return result;
  };
}

export function useMarkContractManualFullyPaired() {
  const queryClient = useQueryClient();

  return async (data: MarkContractManualFullyPairedData) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<string[]>(
      `otc/open_contracts/${data.contract_id}/markFullyPaired`,
      data,
      queryParams,
    );

    queryClient.invalidateQueries({ queryKey: [otcOpenContractBaseKey] });
    queryClient.invalidateQueries({ queryKey: [otcOpenPositionsBaseKey] });

    return result;
  };
}

export function useUnPairContract() {
  const queryClient = useQueryClient();

  return async (data: PairingData) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<string[]>(
      `otc/open_positions/${data.position_id}/unpair`,
      data,
      queryParams,
    );

    queryClient.invalidateQueries({ queryKey: [otcOpenContractBaseKey] });
    queryClient.invalidateQueries({ queryKey: [otcOpenPositionsBaseKey] });

    return result;
  };
}

export function usePairContract() {
  const queryClient = useQueryClient();

  return async (data: PairingData) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<string[]>(
      `otc/open_positions/${data.position_id}/pair`,
      data,
      queryParams,
    );

    queryClient.invalidateQueries({ queryKey: [otcOpenContractBaseKey] });
    queryClient.invalidateQueries({ queryKey: [otcOpenPositionsBaseKey] });

    return result;
  };
}

function getDownloadMarginReportQueryParams(params: MarginReportDownloadParams) {
  const queryParams = new URLSearchParams();

  [
    { key: "after_date", value: params?.after_date },
    { key: "before_date", value: params?.before_date },

  ].forEach((item: any) => {
    if (item.key.includes("date") && item.value) {
      queryParams.append(item.key, moment.tz(item.value, CommonDestinationTimezone).format("YYYY/MM/DD"));
    } else if (item.value) {
      queryParams.append(item.key, item.value);
    }
  });

  return queryParams;
}

function getDownloadQueryParams(params: typeof DownloadFilterValidation._type) {
  const queryParams = new URLSearchParams();

  [
    { key: "trading_account_ids", value: params?.trading_account_ids },
  ].forEach((item: any) => {
    if (item.value?.length > 0) {
      queryParams.append(item.key, item.value!.join(","));
    }
  });

  [
    { key: "strike_price", value: params?.strike_price },
    { key: "contract_end_date", value: params?.contract_end_date },
    { key: "reference_month", value: params?.reference_month },
    { key: "commodity", value: params?.commodity },
  ].forEach((item: any) => {
    if (item.key === "contract_end_date" && item.value) {
      queryParams.append(item.key, moment.tz(item.value, CommonDestinationTimezone).format("YYYY/MM/DD"));
    } else if (item.value) {
      queryParams.append(item.key, item.value);
    }
  });

  return queryParams;
}

function getOpenContractQueryParams(
  params: ContractListOptionParams,
): URLSearchParams {
  const queryParams = new URLSearchParams();

  [
    { key: "customer_ids", value: params.filters?.customer_ids },
    { key: "account_no_a_ids", value: params.filters?.account_no_a_ids },
    { key: "account_no_b_ids", value: params.filters?.account_no_b_ids },
    { key: "trader_ids", value: params.filters?.trader_ids },
  ].forEach((item: any) => {
    if (item.value?.length > 0) {
      queryParams.append(item.key, item.value!.join(","));
    }
  });

  [
    { key: "contract_no", value: params.filters?.contract_no },
    { key: "date", value: params.filters?.date },
    { key: "end_date", value: params.filters?.end_date },
    { key: "contract_type", value: params.filters?.contract_type },
    { key: "commodity", value: params.filters?.commodity },
    { key: "trade_reference", value: params.filters?.trade_reference },
    { key: "option_month", value: params.filters?.option_month },
    { key: "step_one", value: params.filters?.step_one },
    { key: "step_two", value: params.filters?.step_two },
    { key: "step_three", value: params.filters?.step_three },
  ].forEach((item: any) => {
    if (item.key.includes("date") && item.value) {
      queryParams.append(item.key, moment.tz(formatDateWithoutTimezone(item.value), "YYYY/MM/DD", CommonDestinationTimezone).format("YYYY/MM/DD"));
    } else if (item.value) {
      queryParams.append(item.key, item.value);
    }
  });

  return queryParams;
}

export function useDownloadOpenContracts() {
  const queryClient = useQueryClient();

  return async (params: ContractListOptionParams) => {
    const queryParams = getOpenContractQueryParams(params);

    const result = await fetchApiPost<string>(
      `otc/open_contracts/download`,
      {},
      queryParams,
    );
    queryClient.invalidateQueries({ queryKey: [otcOpenContractBaseKey] });
    return result;
  };
}


export function useListRecentArchivedPositions() {
  const queryParams = new URLSearchParams();

  return useQuery({
    queryKey: ["otcArchivedPositions"],
    queryFn: () => {
      return fetchApiGet<TradingAccountOpenPositionAudit[]>(
        "otc/open_positions/archived",
        queryParams,
      );
    },
    retry: 1,
  });
}

export function useListOpenContractFilters() {
  const queryParams = new URLSearchParams();

  return useQuery({
    queryKey: ["otcOpenContractsFilters"],
    queryFn: () => {
      return fetchApiGet<{ customers: Customer[] }>(
        "otc/open_contracts_filters",
        queryParams,
      );
    },
    retry: 1,
  });
}

export function useEditManualPosition() {
   const queryClient = useQueryClient();

  return async (params: typeof CreatePositionValidation._type, id: string) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPut<TradingAccountOpenPosition>(
      `otc/open_positions/${id}`,
      params,
      queryParams,
    );
    return result;
  };
}

export function useCreatePosition() {
   const queryClient = useQueryClient();

  return async (params: typeof CreatePositionValidation._type) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<TradingContract>(
      `otc/open_positions`,
      params,
      queryParams,
    );
    return result;
  };
}

export function useCreateContract() {
  const queryClient = useQueryClient();

  return async (params: typeof CreateContractValidation._type) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<TradingContract>(
      `otc/open_contracts`,
      params,
      queryParams,
    );
    queryClient.invalidateQueries({ queryKey: [otcOpenContractBaseKey] });
    return result;
  };
}

export function useUpdateContract() {
  const queryClient = useQueryClient();

  return async (params: typeof UpdateContractValidation._type) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPut<TradingContract>(
      `otc/open_contracts/${params.id}`,
      params,
      queryParams,
    );
    queryClient.invalidateQueries({ queryKey: [otcOpenContractBaseKey] });

    return result;
  };
}

export function useArchiveContract() {
  const queryClient = useQueryClient();

  return async (id: string) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<boolean>(
      `otc/open_contracts/${id}/archive`,
      {},
      queryParams,
    );
    queryClient.invalidateQueries({ queryKey: [otcOpenContractBaseKey] });

    return result;
  };
}

export function useArchivePosition() {
  const queryClient = useQueryClient();

  return async (id: string) => {
    const queryParams = new URLSearchParams();

    const result = await fetchApiPost<boolean>(
      `/otc/open_positions/${id}/archive`,
      {},
      queryParams,
    );
    queryClient.invalidateQueries({ queryKey: [otcOpenPositionsBaseKey] });
    queryClient.invalidateQueries({ queryKey: ["otcArchivedPositions"] });

    return result;
  };
}

export interface MarginReportDownloadParams {
  after_date?: Date;
  before_date?: Date;
}

export interface PositionListOptionParams {
  filters: {
    account_ids?: string[];
    strike_price?: string;
    commodity?: string;
    option_month?: string;
  };
  listOtcAccountsOnly?: boolean;
}

export interface TradingAccount extends BaseDbObject {
  provider_type: ProviderType;
  account_id: string;

  is_otc?: boolean;
  is_futures?: boolean;
  is_options_otc?: boolean;

  OpenOptionTrades?: TradingAccountOpenPosition[];
  OpenOptionsTradeAudits?: TradingAccountOpenPositionAudit[];
}

export interface TradingAccountOpenPosition extends BaseDbObject {
  is_manually_inserted: boolean;
  is_manually_fully_paired: boolean;
  trading_account_id: string;

  date: Date;
  option_month: string;

  net_position: string;
  long_qty: number;
  short_qty: number;

  put_or_call?: PutCallType;
  contract: string;
  commodity?: Commodities;
  bushel?: number;

  price: number;
  premium: number;

  strike_price?: Decimal;

  original_row: any;

  contract_no?: string;

  TradingAccount?: TradingAccount;
}

export interface TradingAccountOpenPositionAudit extends BaseDbObject {
  is_manually_inserted: boolean;
  is_manually_fully_paired: boolean;

  trading_account_id: string;

  date: Date;
  option_month: string;

  net_position: string;
  long_qty: number;
  short_qty: number;

  put_or_call?: PutCallType;
  contract: string;
  commodity?: Commodities;
  bushel?: number;

  price: number;
  premium: number;

  strike_price?: Decimal;

  original_row: any;

  TradingAccount?: TradingAccount;
}

export interface TradingContract extends BaseDbObject {
  contract_type: string;
  contract_no?: string;

  date: Date;

  is_manually_fully_paired: boolean;
  start_date?: Date;
  end_date?: Date;

  shipment_start_date?: Date;
  shipment_end_date?: Date;

  futures_price?: Decimal;
  otc_futures?: Decimal; // price?

  weeks?: Decimal;
  period_qty?: Decimal;
  knockout?: Decimal;

  account_no_a: string;
  account_no_b?: string;

  trade_reference?: string;
  customer_id: string;
  trader_id?: string;

  quantity: Decimal;
  commodity: string;

  option_month: string;
  step_one?: Decimal;
  step_two?: Decimal;
  step_three?: Decimal;

  option_premium: Decimal;
  contract_fee: Decimal;
  margin: Decimal;
  margin_amount: Decimal;

  notes?: string;

  rolled_option_month?: string;
  carry?: Decimal;
  rolled_basis_month?: string;
  basis_carry?: Decimal;

  Trader?: Trader;
  AccountNoA: TradingAccount;
  AccountNoB?: TradingAccount;
  Customer?: Customer;
}
export enum PutCallType {
  Put = "Put",
  Call = "Call",
}

export enum ProviderType {
  Dorman = "dorman",
  Rjo = "rjo",
}

export enum ContractType {
  Range = "range",
  MinMax = "min_max",
  PremiumOfferWDU = "premium_offer_w_du",
  MinPriceCallSpread = "min_price_call_spread",
  MinimumPrice = "minimum_price",
  MinMaxWDU = "min_max_w_du",
  AccWeeklyDU = "acc_weekly_du",
  ReOwnWDU = "reown_w_du",
}

export const PutOrCallOptions = [
  { value: PutCallType.Put, label: "Put" },
  { value: PutCallType.Call, label: "Call" },
];

export const ContractTypeOptions = [
  { value: ContractType.Range, label: "Range" },
  { value: ContractType.MinMax, label: "Min/Max" },
  { value: ContractType.PremiumOfferWDU, label: "Premium Offer W/DU" },
  { value: ContractType.MinPriceCallSpread, label: "Min Price Call Spread" },
  { value: ContractType.MinimumPrice, label: "Min Price"},
  { value: ContractType.AccWeeklyDU, label: "Accumulator Wkly/DU" },
  { value: ContractType.MinMaxWDU, label: "Min/Max W/DU" },
  { value: ContractType.ReOwnWDU, label: "REOWN W/DU" },
];

export const DownloadRecentContractFilterValidation = z.object({
  created_date: z.string().min(1),
});


export const DownloadFilterValidation = z.object({
  trading_account_ids: z.array(z.string().uuid()).optional(),
  reference_month: z.string().optional(),
  strike_price: z.string().optional(),
  contract_end_date: z.string().min(1),
  commodity: z.string().min(1).optional(),
});

export const CreatePositionValidation =  z.object({
  trading_account_id: z.string().uuid(),

  date: z.string().min(1),
  option_month: z.string().min(1),

  long_qty: z.string(),
  short_qty: z.string(),

  put_or_call: z.string().optional(),
  contract: z.string(),

  commodity: z.string().min(1).optional(),
  bushel: z.string().min(1).optional(),
  strike_price: z.string().min(1).optional(),

  price: z.string().min(1),
  premium: z.string().min(1),
});

export const UpdatePositionValidation = CreatePositionValidation.extend({
  id: z.string().uuid(),
});

export const BaseContractValidation = z.object({
  id: z.string().uuid().optional(),

  contract_type: z.string().min(1),
  contract_no: z.string().min(1).optional(),

  date: z.string(),
  end_date: z.string(),

  shipment_start_date: z.string(),
  shipment_end_date: z.string(),

  notes: z.string().optional(),

  trader_id: z.string().uuid(),
  account_no_a: z.string().uuid(),

  trade_reference: z.string().min(1).optional(),
  customer_id: z.string().uuid(),

  quantity: z.string().min(1),
  commodity: z.string().min(1),

  option_month: z.string().min(1),

  option_premium: z.string().min(1),
  contract_fee: z.string().min(1),
  margin: z.string().optional(),
  margin_amount: z.string().optional(),

  crop_year: z.number().int().min(2016),

  rolled_option_month: z.string().min(1).optional(),
  rolled_basis_month: z.string().min(1).optional(),

  carry: z.string().optional(),
  basis_carry: z.string().optional(),
});

export const RangeValidation = BaseContractValidation.extend({
  contract_type: z.literal(ContractType.Range),
  step_one: z.string().min(1),
  step_two: z.string().min(1),
  step_three: z.string().min(1),
});

export const ReOwnWDUValidation = BaseContractValidation.extend({
  contract_type: z.literal(ContractType.ReOwnWDU),
  option_month_etc_one: z.string().min(1),
  step_one: z.string().min(1),
  step_two: z.string().min(1),
  step_three: z.string().min(1),
});

export const MinMaxValidation = BaseContractValidation.extend({
  contract_type: z.literal(ContractType.MinMax),
  step_one: z.string().min(1),
  step_three: z.string().min(1),
});

export const PremiumValidation = BaseContractValidation.extend({
  contract_type: z.literal(ContractType.PremiumOfferWDU),
  option_month_etc_one: z.string().min(1),
  account_no_b: z.string().uuid(),
  futures_price: z.string(),
  otc_futures: z.string(),
  step_three: z.string().min(1),
});

export const MinPriceValidation = BaseContractValidation.extend({
  contract_type: z.literal(ContractType.MinPriceCallSpread),
  step_one: z.string().min(1),
  step_three: z.string().min(1),
});

export const MinimumPriceValidation = BaseContractValidation.extend({
  contract_type: z.literal(ContractType.MinimumPrice),
  step_three: z.string().min(1),
});

export const AccumulatorValidation = BaseContractValidation.extend({
  contract_type: z.literal(ContractType.AccWeeklyDU),
  option_month_etc_one: z.string().min(1),
  start_date: z.string(),

  weeks: z.string(),
  period_qty: z.string(),
  knockout: z.string(),

  step_one: z.string().min(1),
});

export const MinMaxDoubleUpValidation = BaseContractValidation.extend({
  contract_type: z.literal(ContractType.MinMaxWDU),
  option_month_etc_one: z.string().min(1),

  step_one: z.string().min(1),
  step_two: z.string().min(1),
  step_three: z.string().min(1),
});

export const CreateContractValidation = MinMaxDoubleUpValidation.or(RangeValidation.or(
  MinMaxValidation.or(
    PremiumValidation.or(MinPriceValidation).or(AccumulatorValidation).or(MinimumPriceValidation).or(ReOwnWDUValidation),
  ),
));
export const UpdateContractValidation = MinMaxDoubleUpValidation.partial().or(RangeValidation.partial().or(
  MinMaxValidation.partial().or(
    PremiumValidation.partial().or(
      MinPriceValidation.partial().or(AccumulatorValidation.partial().or(MinimumPriceValidation.partial().or(ReOwnWDUValidation.partial()))),
    ),
  ),
));

export interface ContractListOptionParams {
  filters: {
    contract_no?: string;
    customer_ids?: string[];
    trader_ids?: string[];
    account_no_a_ids?: string[];
    account_no_b_ids?: string[];
    date?: Date;
    end_date?: Date;
    contract_type?: string;
    commodity?: string;
    trade_reference?: string;
    option_month?: string;
    step_one?: string;
    step_two?: string;
    step_three?: string;
  };
}

export function createContractsExcelBuffer({
  contracts,
}: {
  contracts: TradingContract[];
}) {
  const workbook = new ExcelJS.Workbook();

  const sheet = workbook.addWorksheet(
    moment.tz(CommonDestinationTimezone).format("YYYY"),
  );

  sheet.addRow(generateOpenContractColumns());

  for (const row of contracts.map(transformToOpenContractsToCsv)) {
    const formattedRow = [
      row["Date"],
      row["Open/Closed"],
      row["Brokerage HTA"],
      row["Brokerage OTC"],
      row["Contract #"],
      row["Customer"],
      row["Type"],
      row["Quantity"],
      row["Commodity"],
      row["Ref Month"],
      row["Start Date"],
      row["End Date"],
      row["Weeks"],
      row["Period Qty"],
      row["Knockout"],
      row["Step 1"],
      row["Step 2"],
      row["Step 3"],
      row["Opt Prem"],
      row["Margin"],
      row["Contract Fee"],
      row["Trade Reference"],
      row["Futures Price"],
      row["OTC Futures"],
      row["Notes"],
    ];
    sheet.addRow(formattedRow);
  }

  sheet.getColumn("A").numFmt = Excel.CellFormat.MonthDate;
  sheet.getColumn("H").numFmt = Excel.CellFormat.NON_CURRENCY_ACCOUNTING;
  sheet.getColumn("J").numFmt = Excel.CellFormat.MonthDate;
  sheet.getColumn("K").numFmt = Excel.CellFormat.MonthDate;

  sheet.getColumn("M").numFmt = Excel.CellFormat.NON_CURRENCY_ACCOUNTING;
  sheet.getColumn("N").numFmt = Excel.CellFormat.NON_CURRENCY_ACCOUNTING;
  sheet.getColumn("O").numFmt = Excel.CellFormat.ACCOUNTING;
  sheet.getColumn("P").numFmt = Excel.CellFormat.ACCOUNTING;
  sheet.getColumn("Q").numFmt = Excel.CellFormat.ACCOUNTING;
  sheet.getColumn("R").numFmt = Excel.CellFormat.ACCOUNTING;
  sheet.getColumn("S").numFmt = Excel.CellFormat.ACCOUNTING;
  sheet.getColumn("T").numFmt = Excel.CellFormat.ACCOUNTING;
  sheet.getColumn("U").numFmt = Excel.CellFormat.ACCOUNTING;

  sheet.getColumn("W").numFmt = Excel.CellFormat.ACCOUNTING;
  sheet.getColumn("X").numFmt = Excel.CellFormat.NON_CURRENCY_ACCOUNTING;

  sheet.getColumn("A").width = 11;
  sheet.getColumn("B").width = 10;
  sheet.getColumn("C").width = 10;
  sheet.getColumn("D").width = 10;
  sheet.getColumn("E").width = 15;
  sheet.getColumn("F").width = 30;
  sheet.getColumn("G").width = 30;
  sheet.getColumn("H").width = 15;
  sheet.getColumn("K").width = 11;
  sheet.getColumn("L").width = 11;
  sheet.getColumn("P").width = 11;
  sheet.getColumn("Q").width = 11;
  sheet.getColumn("R").width = 11;
  sheet.getColumn("S").width = 11;
  sheet.getColumn("T").width = 11;
  sheet.getColumn("U").width = 11;
  sheet.getColumn("V").width = 11;
  sheet.getColumn("W").width = 11;
  sheet.getColumn("X").width = 11;

  return Excel.workbookToBuffer(workbook);
}

function generateOpenContractColumns() {
  return [
    "Date",
    "Open/Closed",
    "Brokerage HTA",
    "Brokerage OTC",
    "Contract #",
    "Customer",
    "Type",
    "Quantity",
    "Commodity",
    "Option Month",
    "Start Date",
    "End Date",
    "Weeks",
    "Period Qty",
    "Knockout",
    "Step 1",
    "Step 2",
    "Step 3",
    "Opt Prem",
    "Margin",
    "Contract Fee",
    "Trade Reference",
    "Futures Price",
    "OTC Futures",
    "Notes",
  ];
}

function transformToOpenContractsToCsv(row: TradingContract) {
  return {
    Date: moment.tz(row.date, CommonDestinationTimezone).format("MM/DD/YYYY"),
    "Open/Closed": "Open",
    "Brokerage HTA": row.AccountNoB?.account_id || "",
    "Brokerage OTC": row.AccountNoA?.account_id || "",
    "Contract #": row.contract_no || "",
    Customer: row.Customer?.long_name || "",
    Type: findMatchOption(ContractTypeOptions, row.contract_type)?.label || "",
    Quantity: row.quantity ? new Decimal(row.quantity).toNumber() || "" : "",
    Commodity: findMatchOption(CommodityOptions, row.commodity)?.label || "",
    "Ref Month": row.option_month,
    "Start Date": row.start_date
      ? moment
          .tz(row.start_date, CommonDestinationTimezone)
          .format("MM/DD/YYYY")
      : "",
    "End Date": row.end_date
      ? moment.tz(row.end_date, CommonDestinationTimezone).format("MM/DD/YYYY")
      : "",
    Weeks: row.weeks ? new Decimal(row.weeks)?.toNumber() || "" : "",
    "Period Qty": row.period_qty
      ? new Decimal(row.period_qty)?.toNumber() || ""
      : "",
    Knockout: row.knockout ? new Decimal(row.knockout).toNumber() || "" : "",
    "Step 1": row.step_one ? new Decimal(row.step_one)?.toNumber() || "" : "",
    "Step 2": row.step_two ? new Decimal(row.step_two)?.toNumber() || "" : "",
    "Step 3": row.step_three
      ? new Decimal(row.step_three)?.toNumber() || ""
      : "",
    "Opt Prem": row.option_premium
      ? new Decimal(row.option_premium).toNumber() || ""
      : "",
    Margin: row.margin ? new Decimal(row.margin).toNumber() || "" : "", // Lighthouse Fee?
    "Contract Fee": row.contract_fee
      ? new Decimal(row.contract_fee).toNumber() || ""
      : "", // Initial Premium + Fee?
    "Trade Reference": row.trade_reference || "",
    "Futures Price": row.futures_price
      ? new Decimal(row.futures_price)?.toNumber() || ""
      : "",
    "OTC Futures": row.otc_futures
      ? new Decimal(row.otc_futures)?.toNumber() || ""
      : "",
    Notes: row.notes || "",
    "Margin Amount": row.margin_amount
      ? new Decimal(row.margin_amount).toNumber() || ""
      : "",
  };
}

interface PairingData {
  position_id: string;
  contract_id: string;
}

interface MarkPositionManualFullyPairedData {
  is_fully_paired: boolean;
  position_id: string;
}

interface MarkContractManualFullyPairedData {
  is_fully_paired: boolean;
  contract_id: string;
}

export interface PositionContractPair extends BaseDbObject {
  trading_contract_id?: string;
  trading_position_id?: string;
  trading_archived_contract_id?: string;
  trading_archived_position_id?: string;

  user_id: string;

  Contract?: TradingContract;
  Position?: TradingAccountOpenPosition;
  ArchivedContract?: ArchivedTradingContract;
  ArchivedPosition?: TradingAccountOpenPositionAudit;

  User?: DbUser;
}

export interface ArchivedTradingContract extends BaseDbObject {
  contract_type: string;
  contract_no?: string;

  date: Date;

  is_manually_fully_paired: boolean;

  start_date?: Date;
  end_date?: Date;

  shipment_start_date?: Date;
  shipment_end_date?: Date;

  futures_price?: Decimal;
  otc_futures?: Decimal; // price?

  weeks?: Decimal;
  period_qty?: Decimal;
  knockout?: Decimal;
  notes?: string;

  account_no_a: string;
  account_no_b?: string;

  trade_reference?: string;
  customer_id: string;
  trader_id?: string;

  quantity: Decimal;
  commodity: string;

  option_month: string;
  step_one?: Decimal;
  step_two?: Decimal;
  step_three?: Decimal;

  option_premium: Decimal;
  contract_fee: Decimal;
  margin: Decimal;
  margin_amount: Decimal;

  rolled_option_month?: string;
  rolled_basis_month?: string;

  carry?: Decimal;
  basis_carry?: Decimal;

  Trader?: Trader;
  AccountNoA: TradingAccount;
  AccountNoB?: TradingAccount;
  Customer?: Customer;
}
