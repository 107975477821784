import { v4 as uuidV4, validate as isValidUuid } from "uuid";
import Decimal from "decimal.js";
import moment from "moment-timezone";
import { create } from "zustand";
import { CommonDestinationTimezone } from "../../lib/utils";

// NOTE: About dynamic forms:
// This is a simple collection of utilities that are meant to be used on the
// front end to replace Zod and ReactHookForm or Formik
// NOTE: The Best primary use case of this are unstyled forms where the inputs
// are dumped into a grid according to the layout of the schema object.
// However, you can build your own layout instead of using the RenderFullPrimitiveGridLayout
// NOTE: Simple REST Resources that represent a single resource where you need to
// created, update, and list them are support well, using the
// PrimitiveManagedTable, PrimitiveDialogForm, RenderFullPrimitiveGridLayout,
// To render the list, the create/update dialog and the form.
// NOTE: The form supports 1-1 relationships of Depth 1.
// NOTE: you specify the base link field (foreign keyed field, e.g. uuid etc)
//   Then specify in that field, the schema it links against, and it will construct
//   a sub Form for that schema. As well as transform that into a child object
//   on Enter the form or Submit

type ValueOf<T> = T[keyof T];

export enum InputType {
    Enum = "Enum",
    String = "String",
    Literal = "Literal",
    Boolean = "Boolean",
    Uuid = "Uuid",
    Decimal = "Decimal",
    Number = "Number",
    Int = "Int",
    Float = "Float",
    Date = "Date",
    DateTime = "DateTime",
    SelectInput = "SelectInput",
    File = "File",
}

export const InputFilter = {
    Enum: (filterFunc: (formValues: Record<string, any>) => any[] | undefined) => (options: any[], formValues: Record<string, any>, key: string) => {
        const filterToValues = filterFunc(formValues);
        if (!filterToValues) return options;
        return options.filter(wrapper => filterToValues.includes(wrapper.value) || filterToValues.includes(wrapper.label));
    }
}

export const InputRequiredStatusType = {
    Custom: (filterFunc: (formValues: Record<string, any>) => any) => (formValues: Record<string, any>) => {
        return filterFunc(formValues);
    }
};

export interface InputSchema {
    skipForm?: boolean;
    hidden?: boolean;
    label?: string
    options_reference_id?: string;
    step?: string; // Step for number inputs e.g. 5000
    min?: string;
    max?: string;
    fileExtensions?: string[];
    derived?: boolean;
    input_formatter?: any;
    input_type_validation?: any;
    action_reference_id?: any;
    action?: any;
    apiDisplayFormat?: any;
    input_filter?: any;
    required_status_check?: any;
    input_type?: InputType;
    required_status?: InputRequiredStatus;
    isLink?: boolean;
    linked?: Record<string, InputSchema>;
    linkedKey?: string;
    dependencies?: {
        on: string;  // The field this depends on
        transform: (value: any, formValues: Record<string, any>, externalReferences: any) => any;  // Transform function when dependency changes
    };
    defaultValue?: any;
    minDate?: Date | string | (() => Date);
    maxDate?: Date | string | (() => Date);
}

export type FormSchema = Record<string, InputSchema>;

export enum InputRequiredStatus {
    Required = "required",
    Optional = "optional",
    Custom = 'custom'
}

// TODO unify how you call into these
export const InputFormType = {
    Custom: (filterFunc: (formValues: Record<string, any>) => any) => (formValues: Record<string, any>, key: string) => (item: any) => {
      if (item == null) { return { isValid: false, isSameType: false, isNullable: true }; };

      const result = filterFunc(formValues);
      return {
          isValid: result.isValid,
          apiOutput: result.apiOutput,
          value: item,
          isSameType: result.isValid,
          isNullable: false,
          errorMessage: result.errorMessage
      };
    },
    Enum: (enumObject: Record<string, string>) => (_formValues: Record<string, any>) => (item: any) => {
        if (item == null) { return { isValid: false, isSameType: false, isNullable: true }; };
        if (typeof item !== "string") { return { isValid: false, isSameType: false, isNullable: false }; }
        const isValid = Object.values(enumObject).includes(item);
        return { isValid, value: item, isSameType: isValid, isNullable: false };
    },
    String: (_formValues: Record<string, any>) => (item: any) => {
        if (item == null) { return { isValid: false, isSameType: false, isNullable: true }; };
        if (typeof item !== "string") { return { isValid: false, isSameType: false, isNullable: false }; }
        const isValid = true;
        return { isValid, value: item, isSameType: isValid, isNullable: false };
    },
    File: (_formValues: Record<string, any>) => (item: any) => {
        if (item == null) { return { isValid: false, isSameType: false, isNullable: true }; };
        if (![item instanceof File, item instanceof Blob].some(item => !!item)) { return { isValid: false, isSameType: false, isNullable: false }; }
        const isValid = true;
        return { isValid, value: item, isSameType: isValid, isNullable: false };
    },
    Boolean: (_formValues: Record<string, any>) => (item: any) => {
        if (item == null) { return { isValid: false, isSameType: false, isNullable: true }; };
        if (typeof item !== "boolean") { return { isValid: false, isSameType: false, isNullable: false }; }
        const isValid = true;
        return { isValid, value: item, isSameType: isValid, isNullable: false };
    },
    Literal: (literal: string | number) => (_formValues: Record<string, any>) => (item: any) => {
        if (item == null) { return { isValid: false, isSameType: false, isNullable: true }; };
        const isValid = item === literal;
        return { isValid, value: item, isSameType: isValid, isNullable: false };
    },
    Uuid: (_formValues: Record<string, any>) => (item: any) => {
        if (item == null) { return { isValid: false, isSameType: false, isNullable: true }; };
        if (typeof item !== "string") { return { isValid: false, isSameType: false, isNullable: false }; }
        const isValid = isValidUuid(item)
        return { isValid, value: item, isSameType: isValid, isNullable: false };
    },
    Decimal: (_formValues: Record<string, any>) => (item: any) => {
        if (item == null) { return { isValid: false, isSameType: false, isNullable: true }; };
        try {
            const amount = new Decimal(item);
            if (amount.isNaN()) throw new Error("Not a number");

            return { value: amount, isValid: true, isSameType: true, isNullable: false };
        } catch(error: any) {
            const message = error?.message === "Not a number" ? "Not a number" : "Invalid Number";
            return { isValid: false, isSameType: false, isNullable: false, errorMessage: message, error };
        }
    },
    Number: (_formValues: Record<string, any>) => (item: any) => {
        if (item == null) { return { isValid: false, isSameType: false, isNullable: true }; };
        try {
            const amount = parseFloat(item);
            if (isNaN(amount)) throw new Error("Not a number");

            return { isValid: true, value: amount, isSameType: true, isNullable: false };
        } catch(error: any) {
            const message = error?.message === "Not a number" ? "Not a number" : "Invalid Number";
            return { isValid: false, isSameType: false, isNullable: false, errorMessage: message, error };
        }
    },
    Int: (_formValues: Record<string, any>) => (item: any) => {
        if (item == null) { return { isValid: false, isSameType: false, isNullable: true }; };
        try {
            const amount = parseInt(item);
            if (isNaN(amount)) throw new Error("Not a number");

            return { isValid: true, value: amount, isSameType: true, isNullable: false };
        } catch(error: any) {
            const message = error?.message === "Not a number" ? "Not a number" : "Invalid Number";
            return { isValid: false, isSameType: false, isNullable: false, errorMessage: message, error };
        }
    },
    Float: (_formValues: Record<string, any>) => (item: any) => {
        if (item == null) { return { isValid: false, isSameType: false, isNullable: true }; };
        try {
            const amount = parseFloat(item);
            if (isNaN(amount)) throw new Error("Not a number");

            return { isValid: true, value: amount, isSameType: true, isNullable: false };
        } catch(error: any) {
            const message = error?.message === "Not a number" ? "Not a number" : "Invalid Number";
            return { isValid: false, isSameType: false, isNullable: false, errorMessage: message, error };
        }
    },
    Date: (_formValues: Record<string, any>) => (item: any) => {
        if (item == null) { return { isValid: false, isSameType: false, isNullable: true }; };
        try {
            const date = moment.tz(item, CommonDestinationTimezone);
            const isValid = date.isValid();
            return { isValid, value: date.toDate(), isSameType: isValid, isNullable: false };
        } catch(error: any) {
            return { isValid: false, isSameType: false, isNullable: false, errorMessage: "Invalid Date", error };
        }
    },
    DateString: (_formValues: Record<string, any>) => (item: any) => {
        if (item == null) { return { isValid: false, isSameType: false, isNullable: true }; };
        try {
            const date = moment.tz(item, CommonDestinationTimezone);
            const isValid = date.isValid();
            return { isValid, value: date.toDate(), apiOutput: date.format("YYYY-MM-DD"), isSameType: isValid, isNullable: false };
        } catch(error: any) {
            return { isValid: false, isSameType: false, isNullable: false, errorMessage: "Invalid Date", error };
        }
    },
    DateTime: (_formValues: Record<string, any>) => (item: any) => {
        if (item == null) { return { isValid: false, isSameType: false, isNullable: true }; };
        try {
            const date = moment.tz(CommonDestinationTimezone);
            const isValid = date.isValid();
            return { isValid, value: date, isSameType: isValid, isNullable: false };
        } catch(error: any) {
            return { isValid: false, isSameType: false, isNullable: false, errorMessage: "Invalid Date", error };
        }
    },
    SelectInput: (enumObject: Record<string, string>) => (_formValues: Record<string, any>) => (item: any) => {
        if (item == null) { return { isValid: false, isSameType: false, isNullable: true }; };
        if (typeof item !== "string") { return { isValid: false, isSameType: false, isNullable: false }; }
        const isValid = Object.values(enumObject).includes(item);
        return { isValid, value: item, isSameType: isValid, isNullable: false };
    },
};

// TODO Make sure dates, decimals, etc get converted to their correct type (To and From API)
export const ImportType = {};
export const ExportType = {};

export interface InputValue {
    error?: string;
    value: any;
    label?: any;
}

function createInputDefault(input_schema: InputSchema): InputValue {
    return {
        error: undefined,
        value: input_schema.defaultValue !== undefined ? input_schema.defaultValue : undefined,
    }
}

// TODO may want to take in the field type to provide a sensible default
// so the fields are always starting out controlled
function generateFormValues(totalFields: Record<string, InputSchema>, overrideItem?: any) {
    return Object.keys(totalFields).reduce((acc, field_key) => {
        acc[field_key] = createInputDefault(totalFields[field_key]);
        const schema = totalFields[field_key];

        if (overrideItem) {
            acc[field_key].value = overrideItem[field_key];
        }

        if (schema.isLink) {
            acc[schema.linkedKey!] = generateFormValues(schema.linked!, overrideItem ? overrideItem[schema.linkedKey!] : undefined) as any;
        }

        return acc;
    }, {} as Record<string, InputValue>);
}

// TODO will need a meta ancillary data
// where you can provide separately loaded data
// like accounts, etc that can be used for things like select inputs etc
export function generateBaseState({
    schemas,
    baseSchema,
    overrideValues,
}: {
    schemas: FormSchema[];
    baseSchema: FormSchema;
    overrideValues?: any
}) {
    const useFormState = create<any>((set: any) => ({
        schemas,
        baseSchema,
    }));

    const matchedSchema = undefined;
    const totalFields = schemas.reduce((acc, schema) => {
        for (const key of Object.keys(schema)) {
            if (acc[key]) { continue; }
            acc[key] = schema[key];
        }
        return acc;
    }, {});

    const useInputsState = create<any>((set: any, get: any) => ({
        totalFields,
        formValues: generateFormValues(totalFields, overrideValues),
        formRelationships: generateFormRelationships(totalFields),
        updateFormRelationship: ({ baseKey, linkedKey, value }: any) => set((state: any) => {
            if (value) {
                return ({
                    formRelationships: {
                        ...state.formRelationships,
                        [baseKey]: true,
                        [linkedKey]: generateFormValues(totalFields[baseKey].linked!) as any,
                    }
                });
            }

            return ({
                formValues: {
                    ...state.formValues,
                    [baseKey]: { error: undefined, value: undefined, label: undefined },
                    [linkedKey]: generateFormValues(totalFields[baseKey].linked!) as any,
                },
                formRelationships: {
                    ...state.formRelationships,
                    [baseKey]: false,
                }
            });
        }),
        updateFormValue: ({ key, link, value, error, label, externalReferences }: { key: string, link?: string; label?: any, value?: any, error?: any; externalReferences: any }) => set((state: any) => {
            // First update the changed field
            const newState = link ? {
                formValues: {
                    ...state.formValues,
                    [link]: {
                        ...(state.formValues[link] || {}),
                        [key]: { error, value, label }
                    }
                }
            } : {
                formValues: {
                    ...state.formValues,
                    [key]: { error, value, label }
                }
            };

            // Then check for and apply any dependent field updates
            const dependentUpdates: Record<string, any> = {};
            Object.entries(totalFields).forEach(([fieldKey, schema]: [string, InputSchema]) => {
                if (schema.dependencies?.on === key) {
                    const newValue = schema.dependencies.transform(value, newState.formValues, externalReferences);
                    dependentUpdates[fieldKey] = { error: undefined, value: newValue };
                }
            });

            return {
                formValues: {
                    ...newState.formValues,
                    ...dependentUpdates
                }
            };
        }),
        transformToApiSchema: (activeSchema: any) => {
            const { formValues, formRelationships } = get();
            return TransformFormToApiSchema({ formValues, formRelationships, activeSchema });
        },
        clearForm: () => set((state: any) => ({
            formValues: generateFormValues(totalFields),
            formRelationships: generateFormRelationships(totalFields),
        })),
        populateWith: (item: any) => set((state: any) => ({
            formValues: generateFormValues(totalFields, item),
            formRelationships: generateFormRelationships(totalFields, item),
        })),
    }));

    const useSubmissionState = create<any>((set: any) => ({
        has_submitted_form: false,
        is_valid: true,
        is_submitting: false,
        actionState: {},
        updateActionState: {},
        updateSubmissionState: ({ is_submitting, is_valid, has_submitted_form }: { is_submitting?: boolean; is_valid?: boolean; has_submitted_form?: boolean }) => set((state: any) => ({
            ...state,
            ...(is_submitting != null ? ({ is_submitting }) : ({})),
            ...(has_submitted_form != null ? ({ has_submitted_form }) : ({})),
            ...(is_valid != null ? ({ is_valid }) : ({})),
        }))
    }));

    return {
        useFormState,
        useInputsState,
        useSubmissionState,
    }
}

export function ValidateSubmissionAgainstSchema({ formRelationships, formValues, activeSchema }: { formRelationships: any; formValues: Record<string, InputValue>; activeSchema: FormSchema }) {
    const errors: Record<string, string> = {};

    for (const entry of Object.entries(activeSchema)) {
        const [key, inputSchema] = entry as any;

        if (inputSchema.skipForm) { continue; }
        const error = generateErrorMessageFor({ formRelationships, formValues, key, inputSchema });

        if (error) {
            errors[key] = error;
        }

        if (inputSchema.isLink && formRelationships[key]) {
            const result = ValidateSubmissionAgainstSchema({ formRelationships, formValues: formValues[inputSchema.linkedKey] as any, activeSchema: inputSchema.linked }) as any;
            if (!result.isValid) {
                errors[inputSchema.linkedKey] = result.errors
            }
        }
    }

    return { errors, isValid: Object.keys(errors).length == 0 };
}

export function generateErrorMessageFor({ formRelationships, formValues, key, inputSchema }: any) {
    const value = inputSchema.input_type === InputType.Boolean ? formValues?.[key]?.value || false : formValues?.[key]?.value;
    const is_required = inputSchema.required_status === InputRequiredStatus.Custom ?
        inputSchema.required_status_check(formValues).isRequired :
        inputSchema.required_status === InputRequiredStatus.Required;

    if (inputSchema.skipForm) { return; }
    if (value == null && !is_required) { return; }

    if (!inputSchema.input_type_validation && (value == null && is_required)) {
        return "Required"

    }
    const result = inputSchema.input_type_validation(formValues, key)(value);
    if (!!result.errorMessage) {
        return result.errorMessage;
    }

    if (!result.isSameType || !result.isValid)  {
        return "Invalid"
    }

    return
}

export function TransformFormToApiSchema({ formRelationships, formValues, activeSchema }: { formRelationships: any; formValues: Record<string, InputValue>; activeSchema: FormSchema }) {
    const payload: Record<string, any> = {};

    for (const entry of Object.entries(activeSchema)) {
        const [key, inputSchema] = entry as any;

        if (inputSchema.skipForm) { continue; }

        const value = inputSchema.input_type === InputType.Boolean ? formValues?.[key]?.value || false : formValues?.[key]?.value;
        const is_required = inputSchema.required_status === InputRequiredStatus.Custom ?
            inputSchema.required_status_check(formValues).isRequired :
            inputSchema.required_status === InputRequiredStatus.Required;

        if (inputSchema.isLink && formRelationships[key]) {
            const result: any = TransformFormToApiSchema({ formRelationships, formValues: (formValues[inputSchema.linkedKey] || {}) as any, activeSchema: inputSchema.linked });
            if (Object.keys(result).length > 0) {
                payload[inputSchema.linkedKey] = result;
            }
        } else if (inputSchema.isLink && !formRelationships[key]) {
            continue;
        }

        if (value === undefined && !is_required) {
            continue;
        } else if (value === null && !is_required) {
            // payload[key] = value;
            continue;
        }

        if (!inputSchema.input_type_validation && (value == null && is_required)) {
            continue;
        }

        if (value == null) {
            continue;
        }

        const result = inputSchema.input_type_validation(formValues, key)(value);
        payload[key] = result.apiOutput || value;
    }

    return payload;
}

function generateFormRelationships(totalFields: Record<string, InputSchema>, item?: any) {
    const a = Object.entries(totalFields).filter(([key, schema]: any) => schema.isLink).reduce((acc, [field_key, schema]: any) => {
        const key: string = field_key as any;
        if (!item) { acc[key] = false; }
        else {
            acc[key] = !!item[key] as any;
        }
        return acc;
    }, {} as Record<string, boolean>);

    return a;
}
