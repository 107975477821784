export function PrivacyPolicyPage() {
    return (
        <section className="container flex min-h-full flex-1 flex-col justify-start px-6 py-6 lg:px-8">
            <h1 className="text-2xl font-bold mb-4">Lighthouse Commodities, LLC Privacy Policy</h1>

            <p className="mb-4">Lighthouse Commodities has adopted this policy regarding the privacy and security of your personal information.</p>

            <p className="mb-1 font-semibold">Please read this Privacy Policy carefully.</p>

            <p className="mb-4">We do not disclose any nonpublic personal information about our customers or former customers to anyone, except as permitted by law.</p>

            <h2 className="text-xl font-bold mt-6 mb-4">Collection of Information</h2>
            <p className="mb-4">We may collect personally identifiable information ("PII") about you from the following sources:</p>
            <ul className="list-disc pl-8 mb-4 space-y-2">
                <li>Information we receive from you on applications or other forms;</li>
                <li>Information from communication and interactions that we have with you;</li>
                <li>Information from third parties who provide consumer information or verify consumer relationships;</li>
                <li>Information about your transactions with us, our affiliates or others;</li>
                <li>Information we receive from a consumer reporting agency; and</li>
                <li>Information about you that we obtain through an information collecting device from a web server.</li>
            </ul>

            <p className="mb-4">PII includes any information by which you can be personally identified, and may include your name, address, age, date of birth, electronic mail address, occupation, employment information, telephone number, education, the kind of service provided to you, credit card number, and other similar information.</p>

            <h2 className="text-xl font-bold mt-6 mb-4">Use and Disclosure of Your Information</h2>
            <p className="mb-4">We may use and/or share your PII for the following reasons:</p>
            <ul className="list-disc pl-8 mb-4 space-y-2">
                <li>to contact you in response to your inquiries, comments and suggestions;</li>
                <li>to contact you otherwise when necessary;</li>
                <li>for the specific purpose for which it was volunteered;</li>
                <li>to complete any transactions you may perform with or through us;</li>
                <li>as required by law or regulation, or as requested by government authorities, or for the protection of persons or property;</li>
                <li>in connection with an acquisition, merger, restructuring, sale or other transfer involving all or any portion of the business.</li>
            </ul>

            <h2 className="text-xl font-bold mt-6 mb-4">Confidentiality and Security</h2>
            <p className="mb-4">We restrict access to your PII to those employees who need to know that information to provide products or services to you. We maintain physical, electronic and procedural safeguards to guard your PII. However, due to the design of the Internet, we cannot guarantee that communications between you and our servers will be free from unauthorized access by third parties.</p>

            <h2 className="text-xl font-bold mt-6 mb-4">USA PATRIOT ACT</h2>
            <h3 className="text-lg font-bold mb-4">NOTICE TO CUSTOMERS</h3>
            <p className="mb-4">To help the government fight the funding of terrorism and money laundering activities, Federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an account.</p>
            <p className="mb-4">What this means for you: When you open an account, we will ask for your name, address, date of birth and other information that will allow us to identify you.</p>
        </section>
    );
}
