import { useQuery, useQueryClient } from "@tanstack/react-query";
import { z } from "zod";
import { BaseDbObject } from "../models/db";
import {
    fetchApiGet,
    fetchApiPut,
    fetchApiPost,
    fetchApiDelete,
} from "./utils";
import { PossibleRole } from "lhc_permissions";

const ENTITY_KEY = "users";

export const ClientUserFiltersValidation = z.object({
    page: z.number().min(1),
    search: z.string().optional(),
    enabled: z.boolean().optional(),
});

// Validation schema for updating user permissions
export const UpdateUserPermissionsValidation = z.object({
    user_id: z.string().uuid(),
    permissions: z.array(z.string()),
});


export const CreateUserValidation = z.object({
    phone_number: z.string(),
    name: z.string().min(1),
});

export const UpdateUserValidation = z.object({
    id: z.string().uuid(),
    phone_number: z.string(),
    name: z.string().min(1),
    enabled: z.boolean(),
});

export const DeleteUserValidation = z.object({
    id: z.string().uuid(),
});

export interface DbMobileUser extends BaseDbObject {
    name: string;
    phone_number: string;
    enabled: boolean;
}

function assembleUserQueryParams(filters: typeof ClientUserFiltersValidation._type) {
    const queryParams = new URLSearchParams();
    queryParams.append("page", `${filters.page}`)
    if (filters.search) {
        queryParams.append("search", filters.search)
    }
    if (filters.enabled) {
        queryParams.append("enabled", filters.enabled.toString())
    }
    return queryParams;
}

export function fetchClientUsersPage(filters: typeof ClientUserFiltersValidation._type) {
    const queryParams = assembleUserQueryParams(filters);

    return fetchApiGet<{
        data: DbMobileUser[]
        total: number;
        totalPages: number;
        pageSize: number;
        currentPage: number;
    }>(`client/users`, queryParams);
}

export function useClientUsersPaginated({ filters, queryFn }: {
    filters: typeof ClientUserFiltersValidation._type,
    queryFn: any,
}) {
    return useQuery({
        queryKey: ["mobile", "users", `${assembleUserQueryParams(filters).toString()}`],
        queryFn,
        retry: 1,
        cacheTime: 0,
        keepPreviousData: true,
    });
}

export function useCreateUser() {
    const queryClient = useQueryClient();

    return async (data: typeof CreateUserValidation._type) => {
        const queryParams = new URLSearchParams();

        const result = await fetchApiPost<DbMobileUser>(
            `client/users`,
            data,
            queryParams,
        );

        queryClient.invalidateQueries({ queryKey: ["mobile", "users"] });
        return result;
    };
}

export function useUpdateUser() {
    const queryClient = useQueryClient();

    return async (data: typeof UpdateUserValidation._type) => {
        const queryParams = new URLSearchParams();

        const result = await fetchApiPut<DbMobileUser>(
            `client/users/${data.id}`,
            data,
            queryParams,
        );

        queryClient.invalidateQueries({ queryKey: ["mobile", "users"] });
        return result;
    };
}

export function useDeleteUser() {
    const queryClient = useQueryClient();

    return async (data: typeof DeleteUserValidation._type) => {
        const queryParams = new URLSearchParams();

        const result = await fetchApiDelete<DbMobileUser>(
            `client/users/${data.id}`,
        );

        queryClient.invalidateQueries({ queryKey: ["mobile", "users"] });
        return result;
    };
}