import { create } from "zustand";
import { useStytchMember } from "@stytch/react/b2b";
import { useCallback, useState } from "react";
import moment from "moment-timezone";
import { GenerateLoadingOrError, genFlatOptionList, genIdOptionList, CommonDestinationTimezone } from "../../lib/utils";
import { EmailInfoValidation, useGenerateEmailPreview } from "@/lib/api/analystRecommendations";
import {
    InputRequiredStatus,
    InputFormType,
    InputType,
    ImportType,
    ExportType,
    InputFilter,
    InputRequiredStatusType,
} from "../../lib/form";
import {
    useListLocations,
    Location,
    useCreateLocation,
    useUpdateLocation,
} from "../../lib/api/generalContract";
import * as FormHelper from "../../lib/form";
import * as FormLayout from "../../lib/form/layout";
import {
    PrimitiveGridLayout,
    PrimitiveManagedTable,
    PrimitiveDialogForm,
} from "../../lib/form/layout";
import { Button } from "../../components/ui/button";
import { useToast } from "../../components/ui/use-toast";
import { Loader } from "../../components/ui/loader";
import { fetchNewsArticlesPage, useListFeeds, useListNewsArticles, useReplayArticle } from "@/lib/api/news";
import { Label } from "../../components/ui/label";
import { MultiSelect } from "../../components/ui/selectMulti";
import { Input } from "../../components/ui/input";
import useDebounce from "../otc/useDebounce";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "../../components/ui/dialog";
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "../../components/ui/accordion";
import { Badge } from "../../components/ui/badge";
import { hasAccessToFeature } from "@/lib/models/auth";

interface OptionsState {
    emailPreview?: string;
    selectedDate?: string;
    newCropYear?: string;
    isLoading: boolean;

    setEmailPreview: (preview: string) => void;
    setSelectedDate: (date: string) => void;
    setNewCropYear: (year: string) => void;
    setIsLoading: (isLoading: boolean) => void;
}

const useOptionsStore = create<OptionsState>((set) => ({
    emailPreview: undefined,
    selectedDate: moment.tz(CommonDestinationTimezone).format("YYYY-MM-DD"),
    newCropYear: moment.tz(CommonDestinationTimezone).format("YYYY"),
    isLoading: false,

    setEmailPreview: (preview: string) => set({ emailPreview: preview }),
    setSelectedDate: (date: string) => set({ selectedDate: date }),
    setNewCropYear: (year: string) => set({ newCropYear: year }),
    setIsLoading: (isLoading: boolean) => set({ isLoading: isLoading }),
}));

export function EmailPreviewDashboard() {
    const { emailPreview, isLoading } = useOptionsStore();
    return (
        <>
            <div className="container flex flex-col mb-2">
                <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                    HedgeBeacon Email Preview
                </h1>
            </div>
            <div className="container flex flex-col md:flex-col items-start gap-4">
                <EmailOptions />
            </div>
            <div className="container flex flex-col md:flex-col items-start gap-4">
                { isLoading && <Loader /> }
                {emailPreview && (
                    <iframe srcDoc={emailPreview} width="100%" height="800px" sandbox="allow-scripts allow-same-origin"/>
                )}
            </div>
        </>
    );
}

function EmailOptions() {
    const { toast } = useToast();
    const { selectedDate, setSelectedDate, newCropYear, setNewCropYear, isLoading, setIsLoading, setEmailPreview } = useOptionsStore();
    const generateEmailPreview = useGenerateEmailPreview();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();

            if (isLoading) return;
            setIsLoading(true);


            const emailInfoResult = EmailInfoValidation.safeParse({
                datePrefix: selectedDate,
                newCropYear: newCropYear,
            });

            if (!emailInfoResult.success) {
                return;
            }

            const emailInfo = emailInfoResult.data;

            const result = await generateEmailPreview(emailInfo);

            if (result.success) {
                toast({
                    title: "Generated",
                    description: "",
                });
                setEmailPreview(result.data);
            } else {
                toast({
                    title: "Failed to generate email preview",
                    description: "",
                });
            }

            setIsLoading(false);
        } catch(error) {
            console.error("Error generating email preview", error);
            toast({
                title: "Failed to generate email preview",
                description: "",
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="flex flex-row gap-2 mt-4">
                    <div className="space-y-2">
                        <Label>New Crop Year</Label>
                        <div className="grid grid-cols-1 gap-2">
                            <Input
                                required
                                type="number"
                                value={newCropYear}
                                onChange={(e) => {
                                    const inputValue = e.target.value || "0";
                                    setNewCropYear(inputValue);
                                }}
                                min={2020}
                                max={2030}
                            />
                        </div>
                    </div>
                    <div className="flex flex-row gap-2">
                        <div className="space-y-2">
                            <Label>Images Date</Label>
                            <div className="grid grid-cols-1 gap-2 ">
                                <Input
                                    required
                                    type="date"
                                    value={selectedDate}
                                    onChange={(e) => setSelectedDate(e.target.value)}
                                    placeholder="Date"
                                />
                            </div>
                        </div>
                    </div>
                    <Button type="submit" className="ml-4">Generate</Button>
                </div>
            </form>
        </>
    )
}
