import { PossiblePackage, PossibleRole, PermissionType, doesUserPackageHavePermissionTypes, doesUserHavePackages, getUserPackages, getUserRole } from "lhc_permissions";
export { PossiblePackage, PossibleRole, PermissionType, doesUserPackageHavePermissionTypes, doesUserHavePackages, getUserPackages, getUserRole }

interface Feature {
  name: string;
  requiredPermissions?: string[];
  requiredPackages?: string[];
}

export function getUserDbId(member: any): string {
  return member?.trusted_metadata["db_user_id"]!;
}

export function getUserStytchName(member: any): string {
  return member?.name! || "";
}

export function hasAccessToFeature(
  feature: Feature,
  member: any
): boolean {
  if (!feature.requiredPackages?.length) return true;

  if (!feature.requiredPermissions?.length) {
    return doesUserHavePackages({ member, requiredPackages: feature.requiredPackages as any[] });
  }

  return doesUserHavePackages({ member, requiredPackages: feature.requiredPackages as any[] }) &&
     feature.requiredPackages.every((requiredPackage) =>
        doesUserPackageHavePermissionTypes({
            role: getUserRole(member),
            packages: getUserPackages(member),
            requiredPackage: requiredPackage as any,
            requiredPermissions: feature.requiredPermissions as any[]
        })
     );
}
