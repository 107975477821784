import moment from "moment-timezone";
import { z } from "zod";
import { useState } from "react";
import {
  CheckIcon,
  CheckSquare as CheckSquareIcon,
  Plus as PlusIcon,
  Send as SendIcon,
  Trash as TrashIcon,
  FlaskConical,
} from "lucide-react";
import { useToast } from "../../components/ui/use-toast";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";
import { Skeleton } from "../../components/ui/skeleton";
import { Button } from "../../components/ui/button";
import { Textarea } from "../../components/ui/textarea";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";
import {
  AnalystRecommendation,
  AnalystAlert,
  AnalystRecommendationType,
  AnalysisAction,
  AnalysisCommodityOptions,
  AnalysisSignalOptions,
  AnalystRecommendationCropTypeOptions,
  AlertCommodities,
} from "../../lib/models/analystRecommendation";
import {
  ProjectFullName,
  usePublishMarketThoughts,
  useListMarketThoughts,
  fetchMarketThoughtsPage,
  MarketThoughtsValidation,
} from "../../lib/api/analystRecommendations";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
  DialogFooter,
} from "../../components/ui/dialog";
import { CommonDestinationTimezone, GenerateLoadingOrError } from "@/lib/utils";
import { Loader } from "@/components/ui/loader";
import * as FormHelper from "../../lib/form";
import * as FormLayout from "../../lib/form/layout";
import {
    PrimitiveGridLayout,
    PrimitiveManagedTable,
    PrimitiveDialogForm,
} from "../../lib/form/layout";
import {
  InputRequiredStatus,
  InputFormType,
  InputType,
  ImportType,
  ExportType,
  InputFilter,
  InputRequiredStatusType,
} from "../../lib/form";
import { DatePicker } from "@/components/ui/typeable_date_picker";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

moment.tz.setDefault("America/Chicago");

const MarketThoughtsSchema = {
  id: { hidden: true, input_type: InputType.Uuid, input_type_validation: InputFormType.Uuid, required_status: InputRequiredStatus.Optional },

  date: { label: "Date", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => { return moment.tz(original.date, CommonDestinationTimezone).format("MM/DD/YYYY") } },
  details: { label: "Details", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },

  published: { label: "Published", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => { return original.published ? "Yes" : "No" } },
};

export function HedgeBeaconMarketThoughts() {
  const [page, setPage] = useState(1);
  const marketThoughtsRes: any = useListMarketThoughts({
    page,
    queryFn: () => fetchMarketThoughtsPage(page)
  });
  const loadingOrErrorUi = GenerateLoadingOrError([marketThoughtsRes])

  if (loadingOrErrorUi) {
    return loadingOrErrorUi;
  }

  const info = marketThoughtsRes.data?.data || {};
  const isLoading = [marketThoughtsRes].some((result) => result.isFetching)

return (
  <>
    <div className="container flex flex-col mb-2">
      <div className="border-b border-b-2 flex flex-row justify-between items-center">
        <h1 className="scroll-m-20 text-2xl font-bold tracking-tight lg:text-3xl">
          HedgeBeacon Market Thoughts
        </h1>
        <span className="flex flex-row items-center">
          {moment.tz("America/Chicago").format("dddd M/D/YY")}
        </span>
        </div>
    </div>
    <div className="flex items-start flex-col h-full mt-4">
      <CreateMarketThoughtsForm />
      <div className="container flex flex-col">
      <PrimitiveManagedTable
          tableHeight="600px"
          externalReferences={{
          }}
          schema={MarketThoughtsSchema}
          values={info.data}
          onRowClick={(item: any) => {}}
      />
      </div>
    <div className="container flex items-center justify-between mt-4">
      <Button
        onClick={() => setPage(page - 1)}
        disabled={page <= 1 || isLoading}
      >
        {isLoading ? <Loader /> : <span>Previous</span>}
      </Button>

      <span>
        Page {page} of {info.totalPages}
      </span>

      <Button
        onClick={() => setPage(page + 1)}
        disabled={page >= info.totalPages || isLoading}
      >
        {isLoading ? <Loader /> : <span>Next</span>}
      </Button>
    </div>
    </div>
  </>
);
}

function CreateMarketThoughtsForm() {
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState("");
  const [scheduleDate, setScheduleDate] = useState<Date | undefined>();
  const [scheduleTime, setScheduleTime] = useState<string>("08:00:00");
  const publishMarketThoughts = usePublishMarketThoughts();
  
  const handleSubmit = async (projectName: ProjectFullName) => {
    try {
      if (isLoading) { return; }
      setIsLoading(true);
      
      const basePayload = {
        projectName,
        details,
        ...(scheduleDate && ({
          schedule_send: {
            date: moment(scheduleDate).format("YYYY-MM-DD"),
            time: scheduleTime,
          }
        }))
      };

      // Validate the payload
      const bodyResult = MarketThoughtsValidation.safeParse(basePayload);
      if (!bodyResult.success) {
        toast({
          title: "Error",
          description: "Invalid Date or Time",
        });
        return;
      }

      const publishBody = bodyResult.data;

      // TODO: Add your API call here
      const result = await publishMarketThoughts(publishBody);
      if (result.success) {
        toast({
          title: scheduleDate ? "Scheduled" : "Published",
          description: "",
        });

        // Reset form
        setDetails("");
        setScheduleDate(undefined);
        setScheduleTime("8:00:00");
      } else {
        toast({
          title: "Failed",
          description: "Failed to create market thoughts",
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to create market thoughts",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="space-y-4 container mb-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 items-start">
        <div className="space-y-2">
          <Label htmlFor="details">Details</Label>
          <Textarea
            rows={6}
            id="details"
            placeholder="Enter market thoughts..."
            value={details}
            onChange={(e) => setDetails(e.target.value || "")}
          />
        </div>
        
        <div className="space-y-2">
          <Label>Schedule (Optional)</Label>
          <div className="flex gap-2">
            <DatePicker
              value={scheduleDate}
              onChange={setScheduleDate}
            />
            <Input
              required={!!scheduleDate}
              type="time"
              step="1"
              value={scheduleTime}
              onChange={(e) => setScheduleTime(e.target.value)}
              className="w-32"
              disabled={!scheduleDate}
            />
          </div>
        </div>

      <div className="flex flex-col gap-2 justify-center items-center">
        <Dialog>
          <DialogTrigger asChild>
            <Button disabled={!details || isLoading}>
              <FlaskConical className="h-4 w-4 mr-2" />
              Test
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Confirm Test Submission</DialogTitle>
              <DialogDescription>
                Send test SMS to preview what you will see
              </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <DialogClose asChild>
                <Button variant="outline">Cancel</Button>
              </DialogClose>
              <DialogClose asChild>
                <Button 
                  onClick={() => handleSubmit(ProjectFullName.LighthouseTest)}
                  disabled={isLoading}
                >
                  Continue
                </Button>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </Dialog>
        <span className="text-sm text-gray-500">
          or
        </span>
        <Dialog>
          <DialogTrigger asChild>
            <Button disabled={!details || isLoading}>
              <SendIcon className="h-4 w-4 mr-2" />
              Publish
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Confirm Publication</DialogTitle>
              <DialogDescription>
                Are you sure you want to send Market thoughts to everyone?
              </DialogDescription>
            </DialogHeader>
            <DialogFooter>
              <DialogClose asChild>
                <Button variant="outline">Cancel</Button>
              </DialogClose>
              <DialogClose asChild>
                <Button 
                  onClick={() => handleSubmit(ProjectFullName.MarketThoughts)}
                  disabled={isLoading}
                >
                  Continue
                </Button>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
      </div>
    </div>
  );
}
