import { useCallback, useState } from "react";
import { create } from "zustand";
import { FEATURE_GROUPS, PossibleRole, doesUserHavePackages, doesUserPackageHavePermissionTypes, getUserPackages, getUserRole } from "lhc_permissions";
import parsePhoneNumber, { AsYouType as PhoneNumberFormatterAsYouType } from 'libphonenumber-js';
import { GenerateLoadingOrError } from "../../lib/utils";
import { useClientUsersPaginated, fetchClientUsersPage, useCreateUser, useUpdateUser, useDeleteUser, DbMobileUser } from "../../lib/api/mobile";
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "../../components/ui/dialog";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../components/ui/select";
import { Button } from "../../components/ui/button";
import { DataTable } from "../../components/ui/dataTable";
import { PrimitiveDialogForm, PrimitiveManagedTable } from "@/lib/form/layout";
import { InputFormType, InputRequiredStatus, InputType } from "@/lib/form";
import { useToast } from "@/components/ui/use-toast";
import { hasAccessToFeature } from "@/lib/models/auth";
import useDebounce from "../otc/useDebounce";
import { Loader } from "@/components/ui/loader";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import * as FormHelper from "@/lib/form";
import * as FormLayout from "@/lib/form/layout";

const MobileUserSchema = {
    id: { hidden: true, input_type: InputType.Uuid, input_type_validation: InputFormType.Uuid, required_status: InputRequiredStatus.Optional },

    name: { label: "Name", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
    phone_number: {
        label: "Phone Number", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => {
            if (!original.phone_number?.trim()) return original.phone_number || "";
            return new PhoneNumberFormatterAsYouType('US').input(original.phone_number);
        }
    },
    enabled: { label: "Enabled", input_type: InputType.Boolean, input_type_validation: InputFormType.Boolean, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => { return original.enabled ? "Yes" : "No" } },
};

const UpdateMobileUserSchema = {
    id: { hidden: true, input_type: InputType.Uuid, input_type_validation: InputFormType.Uuid, required_status: InputRequiredStatus.Optional },

    name: { hidden: true, label: "Name", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Optional },

    enabled: { label: "Enabled", input_type: InputType.Boolean, input_type_validation: InputFormType.Boolean, required_status: InputRequiredStatus.Required, apiDisplayFormat: ({ original }: any) => { return original.enabled ? "Yes" : "No" } },
};

interface FilterState {
    search: string | undefined;
    enabled: boolean | undefined;
    page: number;

    setSearch: (search: string | undefined) => void;
    setEnabled: (enabled: boolean | undefined) => void;
    setPage: (page: number) => void;
}

const useFilterStore = create<FilterState>((set) => ({
    page: 1,
    search: undefined,
    enabled: undefined,

    setPage: (page: number) => set({ page }),
    setSearch: (search: string | undefined) => set({
        search,
        page: 1
    }),
    setEnabled: (enabled: boolean | undefined) => set({ enabled }),
}));

const updateState = FormHelper.generateBaseState({
    schemas: [UpdateMobileUserSchema],
    baseSchema: UpdateMobileUserSchema,
});

export function MobileClientUsersDashboard() {
    const [isLoading, setIsLoading] = useState(false);
    const [isUpdateOpen, setIsUpdateOpen] = useState(false);
    const { setPage } = useFilterStore(state => ({ setPage: state.setPage }));
    const filters = useFilterStore(
        useCallback((state) => ({
            search: state.search,
            enabled: state.enabled,
        }), [])
    );
    const { page } = useFilterStore(
        useCallback((state) => ({ page: state.page }), [])
    );
    const debouncedFilters = useDebounce(filters, 300);
    const usersRes: any = useClientUsersPaginated({
        filters: { page: page, search: debouncedFilters.search },
        queryFn: () => fetchClientUsersPage({ page, search: debouncedFilters.search })
    });
    const updateClearForm: any = updateState.useInputsState((state: any) => state.clearForm);
    const updatePopulateWith: any = updateState.useInputsState((state: any) => state.populateWith);

    const loadingOrErrorUi = GenerateLoadingOrError([usersRes]);
    if (loadingOrErrorUi) return loadingOrErrorUi;

    const info = usersRes.data?.data || {};

    return (
        <>
            <div className="container flex flex-col mb-2">
                <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                    Manage Mobile Users
                </h1>
            </div>
            <div className="container flex flex-col md:flex-row items-start gap-4">
                <FilterOptions />
                <UpdateFormModal
                    isOpen={isUpdateOpen}
                    onDialogChange={
                      (value: any) => {
                        if (!value) {
                            setIsUpdateOpen(false);
                            updateClearForm();
                        }
                      }
                    }
                />
            </div>
            <div className="flex items-start flex-col h-full mt-4">
                <div className="container flex flex-col">
                    <PrimitiveManagedTable
                        tableHeight="600px"
                        externalReferences={{}}
                        schema={MobileUserSchema}
                        values={info.data}
                        onRowClick={(item: any) => {
                            updatePopulateWith({
                                ...item,
                                phone_number: item.phone_number ? new PhoneNumberFormatterAsYouType('US').input(item.phone_number) : item.phone_number
                            });
                            setIsUpdateOpen(true);
                        }}
                    />
                </div>
                <div className="container flex items-center justify-between mt-4">

                    <span className="flex flex-row gap-2">
                        <Button
                            onClick={() => setPage(1)}
                            disabled={page <= 1 || isLoading}
                        >
                            {isLoading ? <Loader /> : <span>First</span>}
                        </Button>
                        <Button
                            onClick={() => setPage(page - 1)}
                            disabled={page <= 1 || isLoading}
                        >
                            {isLoading ? <Loader /> : <span>Previous</span>}
                        </Button>
                    </span>

                    <span>
                        Page {page} of {info.totalPages}
                    </span>

                    <Button
                        onClick={() => setPage(page + 1)}
                        disabled={page >= info.totalPages || isLoading}
                    >
                        {isLoading ? <Loader /> : <span>Next</span>}
                    </Button>
                </div>
            </div>
        </>
    );
}

function FilterOptions() {
    const { search, setSearch } = useFilterStore();

    return (
        <>
            <div className="flex flex-row gap-2">
                <div className="space-y-2">
                    <div className="grid grid-cols-1 gap-2">
                        <Input
                            type="text"
                            value={search || ""}
                            onChange={(e: any) => {
                                const inputValue = e.target.value;
                                setSearch(inputValue || "");
                            }}
                            placeholder="Search By Clients Name"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}



function UpdateFormModal({ isOpen, onDialogChange }: { isOpen: boolean, onDialogChange: any; }) {
    return (
        <PrimitiveDialogForm isOpen={isOpen} onDialogChange={onDialogChange} dialogTitle={"Update Mobile User"} form={<UpdateDynamicForm onCloseForm={onDialogChange} />}/>
    );
}

function UpdateDynamicForm({ onCloseForm }: { onCloseForm: any; }) {
    const inputState: any = updateState.useInputsState((state: any) => state);
    const submissionState: any = updateState.useSubmissionState((state: any) => state);
    const updateApiItem = useUpdateUser();
    const { toast } = useToast();

    return (
        <>
            <span className="text-2xl font-semibold mb-2">{inputState?.formValues?.name?.value}</span>
            {FormLayout.RenderFullPrimitiveGridLayout({
        id: "MobileUserUpdate",
        activeSchema: UpdateMobileUserSchema,
        totalFields: inputState.totalFields,
        formValues: inputState.formValues,
        updateFormValue: inputState.updateFormValue,
        formRelationships: inputState.formRelationships,
        updateFormRelationship: inputState.updateFormRelationship,
        submissionState,
        externalReferences: {
        },
        onSubmitTo: (item: any) => updateApiItem(item),
        handleSubmitResponse: (result) => {
            if (!result.success) {
                toast({
                    title: "Failed to update Mobile User",
                    description: "",
                });
            } else {
                toast({
                    title: "Updated",
                    description: "",
                });
                onCloseForm(false);
                inputState.clearForm();
            }
        }
    })}
        </>
    );
}




export default MobileClientUsersDashboard;
