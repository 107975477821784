export enum Commodities {
  Barley = "B",
  BarleyHulls = "BH",
  Corn = "C",
  Canola = "CNL",
  CornSilage = "CORN SIL",
  CornScreenings = "CS",
  Durum = "D",
  DriedDistillersGrains = "DDG",
  Flax = "F",
  FeederCattle = "FC",
  Flaxseed = "FS",
  GreenPeas = "GP",
  LiveCattle = "LC",
  GreenLentils = "LEN",
  ModifiedDistillersGrains = "MDG",
  WheatMidds = "MIDD",
  MinneapolisWheat = "MW",
  Oats = "O",
  PeaAndBeanScreenings = "PBS",
  Rye = "RYE",
  Soybeans = "S",
  SoybeanHulls = "SBH",
  SoybeanMeal = "SBM",
  SunflowerScreenings = "SFS",
  Sunflowers = "SNFL",
  Urea = "UR",
  WinterWheat = "WW",
  YellowPeas = "YP",
  PintoBean="PINTOBEAN",
  // HardRedSpring = "HRS",
}

export const CommodityOptions = [
  { value: Commodities.Corn, label: "Corn" },
  { value: Commodities.Soybeans, label: "Soybeans" },
  { value: Commodities.MinneapolisWheat, label: "Minneapolis Wheat" },
  { value: Commodities.WinterWheat, label: "Winter Wheat" },
  { value: Commodities.Oats, label: "Oats" },
  { value: Commodities.Canola, label: "Canola" },
  { value: Commodities.PintoBean, label: "Pinto Beans" },
  { value: Commodities.LiveCattle, label: "Live Cattle" },
  { value: Commodities.FeederCattle, label: "Feeder Cattle" },
  { value: Commodities.Barley, label: "Barley" },
  { value: Commodities.BarleyHulls, label: "Barley Hulls" },
  { value: Commodities.CornScreenings, label: "Corn Screenings" },
  { value: Commodities.Durum, label: "Durum" },
  { value: Commodities.Flax, label: "Flax" },
  { value: Commodities.Flaxseed, label: "Flaxseed" },
  { value: Commodities.GreenPeas, label: "Green Peas" },
  { value: Commodities.Rye, label: "Rye" },
  { value: Commodities.SoybeanHulls, label: "Soybean Hulls" },
  { value: Commodities.SoybeanMeal, label: "Soybean Meal" },
  { value: Commodities.YellowPeas, label: "Yellow Peas" },
  { value: Commodities.Urea, label: "Urea" },
  { value: Commodities.Sunflowers, label: "Sunflowers" },
  { value: Commodities.SunflowerScreenings, label: "Sunflower Screenings" },
  { value: Commodities.PeaAndBeanScreenings, label: "Pea and Bean Screenigns" },
  { value: Commodities.WheatMidds, label: "Wheat Midds" },
  {
    value: Commodities.ModifiedDistillersGrains,
    label: "Modified Distillers Grains",
  },
  {
    value: Commodities.DriedDistillersGrains,
    label: "Dried Distillers Grains",
  },
  { value: Commodities.GreenLentils, label: "Green Lentils" },
  { value: Commodities.CornSilage, label: "Corn Silage" },
  // { value: Commodities.HardRedSpring, label: "Hard Red Spring" },
];

export const PotentialCommodityMappings = {
"Corn": Commodities.Corn,
"Corn Screenings": Commodities.CornScreenings,
"Canola": Commodities.Canola,
"SBY": Commodities.Soybeans,
"Soybeans": Commodities.Soybeans,
"Soybean": Commodities.Soybeans,
"Beans": Commodities.Soybeans,
"Wheat": Commodities.MinneapolisWheat,
"Hard Red Spring": Commodities.MinneapolisWheat,
"Minneapolis Wheat": Commodities.MinneapolisWheat,
"Minneapolis Wht": Commodities.MinneapolisWheat,
"Spring Wht": Commodities.MinneapolisWheat,
"SPRNG WHT": Commodities.MinneapolisWheat,
"Winter Wht": Commodities.WinterWheat,
"Red Wht": Commodities.WinterWheat,
"Red Wheat": Commodities.WinterWheat,
"Barley": Commodities.Barley,
"Spring Wheat": Commodities.MinneapolisWheat,
"Winter Wheat": Commodities.WinterWheat,
"Oats": Commodities.Oats,
"Durum": Commodities.Durum,
"Rye": Commodities.Rye,
};
