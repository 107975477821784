import { useState } from "react";
import moment from "moment-timezone";
import { Label } from "../../components/ui/label";
import { Loader } from "../../components/ui/loader";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { useToast } from "../../components/ui/use-toast";
import { cn, GenerateLoadingOrError } from "../../lib/utils";
import { useListCustomers } from "@/lib/api/customer";
import { processFromFiles } from "@/lib/invoiceAndStatementReport";
import Decimal from "decimal.js";
import { BearDecimalInput, BearFile, BearFloatInput } from "@/lib/form/ui";
import { CommonDestinationTimezone } from "@/lib/api/utils";

export function InvoiceAndStatementReport() {
    return <ReportView />;
}

function ReportView({ }: {}) {
    const { toast } = useToast();
    const [isAdvancedOpen, setIsAdvancedOpen] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [rebateTrackerFile, setRebateTrackerFile] = useState<File | undefined>(undefined);
    const [defaultRate, setDefaultRate] = useState<Decimal>(new Decimal(0));
    const [deferredRate, setDeferredRate] = useState<Decimal>(new Decimal(0));
    const [defaultYear, setDefaultYear] = useState<number>(0);
    const customerInfoRes = useListCustomers();
    const loadingOrErrorUi = GenerateLoadingOrError([customerInfoRes])
    const [reportResults, setReportResults] = useState<{
        invoiceResults?: { errors: Record<string, any> },
        statementResults?: { errors: Record<string, any>, skipped: Record<string, any> }
    } | null>(null);

    const onUploadFile = async () => {
        if (isSubmitting) return;
        if (!rebateTrackerFile) {
            toast({
                title: "Missing Rebate Tracker File",
                description: "Rebate tracker file is required",
            });
            return;
        }

        if (defaultYear && (defaultYear < 2020 || defaultYear > 2030)) {
            toast({
                title: "Invalid Default Year",
                description: "Default year must be greater than 2020",
            });
            return;
        }

        setIsSubmitting(true);

        try {
            const rebateResult = await processFromFiles(rebateTrackerFile, {
                customers,
                defaultRate: defaultRate.isZero() ? undefined : defaultRate,
                deferredRate: deferredRate.isZero() ? undefined : deferredRate,
                defaultYear: defaultYear === 0 ? undefined : defaultYear,
            });
            if (!rebateResult.success) {
                console.error("Failed to process rebate tracker file. Error:", rebateResult);
                toast({
                    title: "Failed to process rebate tracker file",
                    description: "",
                });
                setReportResults(null);
            } else {
                setReportResults(rebateResult.data);
                toast({
                    title: "Rebate Report Generated",
                    description: "Rebate Report Generated",
                })
            }
        } catch (error) {
            console.error("Failed to process rebate tracker file. Caught error:", error);
            toast({
                title: "Failed to process rebate tracker file",
                description: "",
            });
            setReportResults(null);
        }
        finally {
            setIsSubmitting(false);
        }
    };

    const onRebateTrackerFileChange = (item: any) => {
        setRebateTrackerFile(item.target.files[0]);
    };

    const customers = customerInfoRes.data?.data || [];

    return (
        <>
            <div className="container flex flex-col mb-2">
                <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                    Invoice and Statement Report
                </h1>
            </div>
            <div className="flex items-start flex-col h-full mt-4">
                <div className="container flex flex-col gap-4">
                    <div className="grid items-center gap-1.5">
                        <BearFile
                            required={true}
                            tabIndex={0}
                            name={'rebateTrackerFile'}
                            label={'Rebate Tracker File'}
                            accept=".xlsx, .xls"
                            onChange={onRebateTrackerFileChange}
                        />
                    </div>
                    <div className="border rounded-lg">
                        <div className="flex items-center p-4 cursor-pointer" onClick={() => setIsAdvancedOpen(!isAdvancedOpen)}>
                            <h2 className="text-md font-semibold">Advanced Options</h2>
                            <span className={`ml-2 transition-transform ${isAdvancedOpen ? 'rotate-180' : ''}`}>▼</span>
                        </div>
                        {isAdvancedOpen && (
                            <div className="p-4 pt-0">
                                <div className="space-y-4">
                                    <BearDecimalInput
                                        tabIndex={1}
                                        name={'defaultRate'}
                                        label={'Override Cash Rebate Rate'}
                                        value={defaultRate.toString()}
                                        step={"0.01"}
                                        min={'0'}
                                        max={'100'}
                                        onChange={(value: any) => {
                                            return setDefaultRate(new Decimal(value || "0"));
                                        }}
                                    />
                                    <BearDecimalInput
                                        tabIndex={2}
                                        name={'deferredRate'}
                                        label={'Override Deferred Rebate Rate'}
                                        value={deferredRate.toString()}
                                        step={"0.01"}
                                        min={'0'}
                                        max={'100'}
                                        onChange={(value: any) => {
                                            return setDeferredRate(new Decimal(value || "0"));
                                        }}
                                    />
                                    <BearDecimalInput
                                        tabIndex={3}
                                        name={'defaultYear'}
                                        label={'Override Year'}
                                        value={defaultYear.toString()}
                                        step={"1"}
                                        min={'2020'}
                                        max={'2030'}
                                        onChange={(value: any) => {
                                            return setDefaultYear(parseInt(value || "0"));
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="grid w-full max-w-sm items-center gap-1.5 mt-2">
                        <Button
                            disabled={
                                isSubmitting ||
                                !rebateTrackerFile
                            }
                            id="uploadButton"
                            onClick={onUploadFile}
                        >
                            {isSubmitting ? <Loader /> : "Process"}
                        </Button>
                    </div>

                    {reportResults && (
                        <div className="space-y-6 mt-8">
                            {/* Invoice Errors Section */}
                            {Object.keys(reportResults.invoiceResults?.errors || {}).length > 0 && (
                                <div className="border rounded-lg p-4">
                                    <h2 className="text-lg font-semibold mb-4">Invoice Errors</h2>
                                    <div className="space-y-2">
                                        {Object.entries(reportResults.invoiceResults?.errors || {}).map(([id, data]: [string, any]) => (
                                            <div key={`${id}-invoice-error`} className="text-red-600">
                                                {`ID: ${id} - ${data.error} - Row: ${data.rowNumber}`}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {/* Statement Errors Section */}
                            {Object.keys(reportResults.statementResults?.errors || {}).length > 0 && (
                                <div className="border rounded-lg p-4">
                                    <h2 className="text-lg font-semibold mb-4">Statement Errors</h2>
                                    <div className="space-y-2">
                                        {Object.entries(reportResults.statementResults?.errors || {}).map(([id, data]: [string, any]) => (
                                            <div key={`${id}-statement-error`} className="text-red-600">
                                                {`ID: ${id} - ${data.error} - Row: ${data.rowNumber}`}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {/* Skipped Rows Section */}
                            {Object.keys(reportResults.statementResults?.skipped || {}).length > 0 && (
                                <div className="border rounded-lg p-4">
                                    <h2 className="text-lg font-semibold mb-4">Skipped Rows</h2>
                                    <div className="space-y-2">
                                        {Object.entries(reportResults.statementResults?.skipped || {}).map(([id, data]: [string, any]) => (
                                            <div key={`${id}-statement-skipped`} className="text-yellow-600">
                                                {`ID: ${id} - ${data.reason}${data.sheetName ? ` (Sheet: ${data.sheetName})` : ''} - Row: ${data.rowNumber}`}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}