import { v4 as uuidV4, validate as isValidUuid } from "uuid";
import * as XLSX from "xlsx";
import moment from "moment-timezone";
import Decimal from "decimal.js";
import { parseFromFile } from "../file";
import { Customer, Trader, TraderCustomerBulk } from "../api/reports";
import { ServerError, SuccessResult, ValidationError } from "../responseResults";
import { CommonDestinationTimezone } from "../utils";
import { findCustomerFrom, findTraderForCustomer } from "../customer";
import * as Excel from '../excel/index';
import * as ExcelComposer from '../excel/composer';
import { convertSheetToJson, safeParseDecimal } from "../excel";
import { downloadBinaryData, downloadMultipleFilesAsZip } from "../browser/download";

interface RebateTrackerSheetRow {
  "Agtech ID": string;
  "Producer": string;
  "Bushels": string;
  "Total Dividend": string;
  "Cash Rebate": string;
  "Merch Fee": string;
  "Don’t Pass through": string;
  "Net Cash Rebate": string;
  "Deferred Rebate": string;
}

// TODO Question is there any rolling in this, like the rolling around end of year
// TODO Question is there any way to get the year from the sheet name?
// TODO Question are these always soybeans?
// TODO Question for the invoice, is the Total Cash Rebate supposed to be the Quantity * Rate (in the final output)? Or whatever is in Net Cash Rebate in the Rebate Tracker?
// TODO Question for the invoice, where does the invoice number come from? (e.g. Invoice #: REBATE121624) Is this different per customer?


export async function processFromFiles(
  file: File,
  {
    customers,
    defaultRate: overrideDefaultRate,
    deferredRate: overrideDeferredRate,
    defaultYear: overrideDefaultYear,
  }: {
    customers: Customer[];
    defaultRate?: Decimal;
    defaultYear?: number;
    deferredRate?: Decimal;
  }
) {
  const errors: Record<string, any> = {};
  const imageBuffer: Buffer = await fetch(new URL("https://lighthouse-admin-portal.s3.us-east-2.amazonaws.com/images/lighthouse_logo_2x.png")).then((res) => res.arrayBuffer()).catch(error => { throw error; }) as any;

  const workbookResult = await getWorkbookFromFile(file);
  const defaultYear = overrideDefaultYear == null ? generateDefaultYear(workbookResult.excelJsSheet) : overrideDefaultYear;

  const { success: cashRebateSuccess, data: cashRebateData } = getTotalCashDividendValue(workbookResult.excelJsSheet);
  if (!cashRebateSuccess) {
    errors["missing_cash_rebate"] = { message: "Cash Rebate Rate not found" };
    return new ServerError("", errors);
  }

  const { success: deferredRebateSuccess, data: deferredRebateData } = overrideDeferredRate == null ? getTotalDeferredDividendValue(workbookResult.excelJsSheet, cashRebateData.totalCashDividendRow!) : { success: true, data: { totalValue: overrideDeferredRate } };
  if (!deferredRebateSuccess) {
    errors["missing_deferred_rebate"] = { message: "Deferred Rebate Rate not found" };
    return new ServerError("", errors);
  }

  const defaultRate = new Decimal(overrideDefaultRate == null ? cashRebateData.totalValue! : overrideDefaultRate);
  const deferredRate = new Decimal(overrideDeferredRate == null ? deferredRebateData.totalValue! : overrideDeferredRate);

  const sheetData: RebateTrackerSheetRow[] = XLSX.utils.sheet_to_json(workbookResult.sheet);

  const invoiceFiles = await generateInvoiceFiles({ customers, defaultRate, imageBuffer, defaultYear, sheetData });

  const otherSheetMap = Object.entries(workbookResult.otherSheets).reduce((acc, [sheetName, sheet]) => {
    acc[sheetName] = XLSX.utils.sheet_to_json(sheet)
    return acc;
  }, {} as Record<string, RebateTrackerSheetRow[]>);

  const statementFiles = await generateStatementFiles({
    customers, defaultRate, defaultYear, deferredRate, imageBuffer, sheetData, otherSheetMap, workbook: workbookResult.excelJsWorkbook,
    sheet: workbookResult.excelJsSheet
  });

  const invoiceDateNumber = moment.tz(CommonDestinationTimezone).format("MMDDYY");
  const year = moment.tz(CommonDestinationTimezone).format("YYYY");
  const findCustomerBy = (client_id: string) => customers.find((customer: Customer) => customer.client_id === client_id);

  const zippedFiles = [
    ...(await Promise.all(Object.entries(invoiceFiles.files).map(async ([client_id, composer]) => ({
      buffer: await composer.getBuffer(),
      fileName: `${findCustomerBy(client_id)?.long_name || ""} Rebate ${year}.xlsx`,
      mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })))),
    ...(await Promise.all(Object.entries(statementFiles.files).map(async ([client_id, composer]) => ({
      buffer: await composer.getBuffer(),
      fileName: `${findCustomerBy(client_id)?.long_name || ""} Rebate Statement ${year}.xlsx`,
      mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })))),
  ];
  await downloadMultipleFilesAsZip(zippedFiles, "invoice_and_statements.zip");

  return new SuccessResult("", {
    invoiceResults: {
      errors: invoiceFiles.errors,
    },
    statementResults: {
      errors: statementFiles.errors,
      skipped: statementFiles.skipped,
    }
  });
}

async function getWorkbookFromFile(file: File) {
  const workbookResult = await Excel.parseFromFile(file);
  if (!workbookResult.success) {
    throw new ValidationError("Invalid excel file", {});
  }

  const excelJsWorkbook = workbookResult.data!.excelJsWorkbook;
  const exceljs_sheet = generateSheetName(excelJsWorkbook);
  return {
    workbook: workbookResult.data!.xlsxWorkbook,
    sheet: workbookResult.data!.xlsxWorkbook.Sheets[exceljs_sheet.name],
    sheet_name: exceljs_sheet.name,
    excelJsWorkbook: excelJsWorkbook,
    excelJsSheet: exceljs_sheet,
    orderedSheets: generateOrderedSheets(excelJsWorkbook),
    otherSheets: workbookResult.data!.xlsxWorkbook.Sheets
  }
}

function generateOrderedSheets(workbook: Excel.Workbook) {
  const possibleYearSheets = workbook.worksheets.filter((worksheet: Excel.Worksheet) => /20[0-9]{1}[0-9]{1}/img.test(worksheet.name));
  possibleYearSheets.sort();
  return possibleYearSheets;
}

function generateSheetName(workbook: Excel.Workbook) {
  const possibleYearSheets = generateOrderedSheets(workbook);

  return possibleYearSheets.length > 0 ? possibleYearSheets[0] : workbook.worksheets[0];
}

async function generateInvoiceFiles(
  { customers, defaultRate, imageBuffer, defaultYear, sheetData }: { customers: Customer[]; defaultRate: Decimal; imageBuffer: Buffer; defaultYear: number; sheetData: RebateTrackerSheetRow[] }
) {
  const files: Record<string, ExcelComposer.ExcelComposer> = {};
  const errors: Record<string, { id: string, error: string, row: any; rowNumber?: number }> = {};


  await Promise.all(sheetData.map(async (row: RebateTrackerSheetRow, index: number) => {
    const rowSheetIndex = index + 2;
    const foundCustomer = customers.find((customer) => customer.client_id === row["Agtech ID"]);
    if (!foundCustomer) {
      if (row["Agtech ID"]) {
        errors[row["Agtech ID"]] = {
          id: row["Agtech ID"],
          error: "Customer not found",
          row,
          rowNumber: rowSheetIndex,
        };
      }
      return;
    }

    const composer = new ExcelComposer.ExcelComposer();

    composer.createSheet("Invoice");
    composer.setPaperLike();

    await composer.addImage(imageBuffer, {
      tl: { col: 1, row: 0.2 },
      br: { col: 3.0, row: 11.2 }
    });

    const invoiceDateNumber = moment.tz(CommonDestinationTimezone).format("MMDDYY");

    composer.addSection({
      headers: [],
      data: [
        [{ value: "Lighthouse Commodities, LLC", bold: true }, { value: "" }, { value: "" }, { value: "" }, { value: moment.tz(CommonDestinationTimezone).format("MM/DD/YY") }],
        [],
        [{ value: `Invoice #: REBATE${invoiceDateNumber}`, bold: true, fontSize: 13 }],
        [{ value: foundCustomer.long_name }],
        [{ value: foundCustomer.address_line_one || "" }],
        [{ value: generateAddressLineThree(foundCustomer) }],
      ],
      footerCalculations: [],
      styling: {}
    } as ExcelComposer.SectionConfig, { startRow: 1, startColumn: 1 });

    composer.addSection({
      headers: ["Description", "", "Quantity", "Rate", "Total Cash Rebate"],
      data: [
        [{ value: `Cash Rebate AGP-Coop ${defaultYear}` }, { value: "" }, { value: safeParseDecimal(row["Bushels"]).toNumber() }, { value: defaultRate.toNumber(), format: Excel.CellFormat.ACCOUNTING }, { value: row["Net Cash Rebate"], formula: "=C$ROW * D$ROW", format: Excel.CellFormat.ACCOUNTING }],
        [],
        [],
        [],
      ],
      footerCalculations: [
        {
          type: "value",
          value: "Total Invoice Amount",
          column: 3,
          bold: true
        },
        {
          type: "sum",
          column: 4,
          bold: true,
          format: Excel.CellFormat.ACCOUNTING,
          border: {
            type: "top",
          }
        },
      ],
      styling: {
        columns: {
          A: { width: 30 },
          B: { width: 15 },
          C: { width: 15 },
          D: { width: 20 },
          E: { width: 20 },
        },
        header: {
          bold: true,
        },
      }
    } as ExcelComposer.SectionConfig, { startRow: 12, startColumn: 1 });

    files[foundCustomer.client_id] = composer;
  }));

  return { files, errors };
}

// We need to generate an invoice and statement like the attached based on the 2024 rebate tracking spreadsheet.
// The invoice if we could generate the quantity based on column C and total cash rebate based on column H.
// Rate will default to rate in the example invoice.
// On the statements we can go in and update the prior year deferred amounts in row 22 if you can get the
// statement details generated. Quantity will be based on Column C and Cash Rebate based on Column H and
// deferred rebate based on column I from 2024 Rebate Tracking. As always let me know if you have any questions.

function getNotesColumnForSheet(sheet?: Excel.Worksheet) {
  if (!sheet) {
    return undefined;
  }

  const values = sheet.getRows(1, 1)?.length ? sheet.getRows(1, 1)![0]!.values : [];
  if (!values || !values?.length || !Array.isArray(values)) {
    return undefined;
  }

  const deferredRebateColIndex = values!.findIndex((value: any) => value === 'Deferred Rebate');
  if (deferredRebateColIndex == undefined || deferredRebateColIndex < 0) {
    return undefined;
  }

  return sheet.getColumn(deferredRebateColIndex + 1);
}

async function generateStatementFiles(
  { customers, defaultRate, defaultYear, deferredRate, imageBuffer, sheetData, otherSheetMap, workbook, sheet }:
    { customers: Customer[]; defaultRate: Decimal; defaultYear: number; deferredRate: Decimal; imageBuffer: Buffer; sheetData: RebateTrackerSheetRow[]; otherSheetMap: Record<string, RebateTrackerSheetRow[]>; workbook: Excel.Workbook; sheet: Excel.Worksheet }
) {
  const files: Record<string, ExcelComposer.ExcelComposer> = {};
  const errors: Record<string, { id: string, error: string; row: any; rowNumber?: number }> = {};
  const skipped: Record<string, { id: string, reason: string; value?: string; sheetName?: string; row: any; rowNumber?: number }> = {};

  if (!sheetData.length) {
    return { files, errors, skipped };
  }

  await Promise.all(sheetData.map(async (row: RebateTrackerSheetRow, index: number) => {
    // NOTE: 1 indexed + 1 for the header row
    const rowSheetIndex = index + 2;
    const notesColumn = getNotesColumnForSheet(sheet);
    if (notesColumn) {
      const notes = notesColumn.values[rowSheetIndex];
      // console.warn("base notes", row["Agtech ID"], row["Producer"], notes, typeof notes, rowSheetIndex, typeof notes === "string" ? passThroughRegex.test(notes) : false, !!notes && typeof notes === "string" && passThroughRegex.test(notes));
      const shouldSkip = !!notes && typeof notes === "string" && /(don't|do not|dont|don’t) (pass|passthrough|pass through)/img.test(notes);
      console.warn("\tbase notes", row["Agtech ID"], row["Producer"], !!notes, typeof notes === "string", shouldSkip);

      if (shouldSkip) {
        console.error("registered base skipped", row["Agtech ID"], row["Producer"], notes);
        skipped[row["Agtech ID"]] = {
          id: row["Agtech ID"],
          reason: "Skipped. Don't pass through",
          value: notes,
          rowNumber: rowSheetIndex,
          sheetName: sheet.name,
          row,
        };
        return;
      }
    }

    const foundCustomer = customers.find((customer) => customer.client_id === row["Agtech ID"]);
    if (!foundCustomer) {
      if (row["Agtech ID"]) {
        errors[row["Agtech ID"]] = {
          id: row["Agtech ID"],
          error: "Customer not found",
          row,
          rowNumber: rowSheetIndex,
        };
      }

      return
    }

    const composer = new ExcelComposer.ExcelComposer();
    composer.createSheet("Statement");
    composer.setPaperLike();
    await composer.addImage(imageBuffer, {
      tl: { col: 1, row: 0.2 },
      br: { col: 3.0, row: 11.2 }
    });

    composer.addSection({
      headers: [],
      data: [
        [{ value: "Lighthouse Commodities, LLC", bold: true }, { value: "" }, { value: "" }, { value: "" }, { value: moment.tz(CommonDestinationTimezone).format("MM/DD/YY") }],
        [],
        [{ value: `STATEMENT: ${defaultYear}`, bold: true }],
        [{ value: foundCustomer.long_name }],
        [{ value: foundCustomer.address_line_one || "" }],
        [{ value: generateAddressLineThree(foundCustomer) }],
      ],
      footerCalculations: [],
      styling: {
        columns: {
          A: { width: 30 },
        }
      }
    } as ExcelComposer.SectionConfig, { startRow: 1, startColumn: 1 });

    composer.addSection({
      headers: ["Commodity", "Rate", "Qty", "Cash Rebate", "Deferred Rate", "Deferred Rebate"],
      data: [
        [{ value: "Soybeans" }, { value: defaultRate.toNumber(), format: Excel.CellFormat.ACCOUNTING }, { value: safeParseDecimal(row["Bushels"]).toNumber() }, { value: row["Net Cash Rebate"], format: Excel.CellFormat.ACCOUNTING }, { value: deferredRate.toNumber(), format: Excel.CellFormat.ACCOUNTING }, { value: row["Deferred Rebate"], format: Excel.CellFormat.ACCOUNTING }],
      ],
      footerCalculations: [],
      styling: {
        header: {
          border: {
            type: "bottom",
          },
        },
        columns: {
          B: { width: 15 },
          C: { width: 15 },
          D: { width: 15 },
          E: { width: 15 },
          F: { width: 15 },
        }
      }
    } as ExcelComposer.SectionConfig, { startRow: 12, startColumn: 1 });

    const otherYears = (new Array(6)).fill(0).map((_item: any, index: number) => moment.tz(CommonDestinationTimezone).year(defaultYear).subtract(4, "year").add(index, "year").format("YYYY")).map((year: string) => ({ value: year }));
    composer.addSection({
      headers: [],
      data: [
        [{ value: "Deferred Rates by Year" }, { value: "Total Deferred:" }, { formula: "=SUM(A22:F22)", format: Excel.CellFormat.ACCOUNTING }],
        [],
        otherYears,
      ],
      footerCalculations: [],
      styling: {
      }
    } as ExcelComposer.SectionConfig, { startRow: 19, startColumn: 1 });

    composer.addSection({
      headers: [],
      data: [
        otherYears.map((item: any, index: number) => {
          if (item.value === defaultYear) {
            return ({ formula: "=F13", format: Excel.CellFormat.ACCOUNTING });
          }

          const otherSheetName = `${item.value}`;
          const foundOtherYearSheetRows = otherSheetMap[otherSheetName];
          if (foundOtherYearSheetRows) {
            const otherRowIndex = foundOtherYearSheetRows.findIndex((otherRow: RebateTrackerSheetRow) => otherRow["Producer"] === row["Producer"]);
            if (otherRowIndex !== -1) {
              const otherNotesColumn = getNotesColumnForSheet(workbook.worksheets.find((sheet: Excel.Worksheet) => sheet.name === otherSheetName)!);
              if (otherNotesColumn) {
                const otherNotes = otherNotesColumn.values[otherRowIndex + 2]; // NOTE: 1 indexed + 1 for the header row

                if (otherNotes && typeof otherNotes === "string" && /(don't|do not|dont|don’t) (pass|passthrough|pass through)/img.test(otherNotes)) {
                  // skipped[row["Agtech ID"]] = {
                  //   id: row["Agtech ID"],
                  //   reason: "Skipped. Don't pass through",
                  //   value: otherNotes,
                  //   sheetName: otherSheetName,
                  //   row,
                  // };
                  return ({ value: 0, format: Excel.CellFormat.ACCOUNTING });
                }
              }

              return ({ value: otherRowIndex, format: Excel.CellFormat.ACCOUNTING });
            }
          }

          return ({ value: 0, format: Excel.CellFormat.ACCOUNTING });
        }),
      ],
      footerCalculations: [],
      styling: {
        section: {
          border: {
            type: "around",
          }
        }
      }
    } as ExcelComposer.SectionConfig, { startRow: 22, startColumn: 1 });

    files[foundCustomer.client_id] = composer;
  }));

  return { files, errors, skipped };
}

function generateAddressLineThree(customer: Customer) {
  return [
    customer.city ? `${customer.city},` : "",
    customer.state ? `${customer.state?.toLocaleUpperCase()} ` : "",
    customer.zip ? `${customer.zip}` : "",
    ["canada", "cad", "ca"].includes((customer.country || "")?.toLocaleLowerCase()) ? `${customer.country}` : "",
  ].join(" ");
}

function getTotalCashDividendValue(sheet: Excel.Worksheet) {
  let totalCashDividendRow: undefined | number = undefined;
  sheet.getColumn('G').eachCell((cell: any, rowNumber: number) => {
    if (cell.value === 'Total Cash Dividend' && totalCashDividendRow === undefined) {
      totalCashDividendRow = rowNumber;
    }
  });

  const cell: any = totalCashDividendRow !== -1
    ? sheet.getCell(`H${totalCashDividendRow}`)
    : null;

  const totalValue = cell?.result;

  return {
    success: totalCashDividendRow !== undefined,
    data: {
      totalCashDividendRow,
      totalValue: totalValue as any,
    }
  };
}


function getTotalDeferredDividendValue(sheet: Excel.Worksheet, totalCashDividendRow: number) {
  const row = totalCashDividendRow - 2;
  if (row <= 0) {
    return {
      success: false,
      data: {
        totalValue: 0,
      }
    };
  }

  const value = sheet.getCell(`I${row}`)?.result;
  return {
    success: value != undefined,
    data: {
      totalValue: value as any,
    }
  }
}

function generateDefaultYear(sheet: Excel.Worksheet) {
  const cell: any = sheet.getCell("Q1");
  const totalValue = cell?.result || cell?.value?.toISOString()?.split("T")[0];

  const momentYear = moment.tz(totalValue as any, "YYYY-MM-DD", true, CommonDestinationTimezone);
  if (!momentYear || !momentYear.isValid()) {
    throw new ValidationError("Invalid date", {});
  }

  return new Decimal(momentYear.format("YYYY")).toNumber();
}
