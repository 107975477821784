import { downloadZip } from "client-zip";

export function downloadUsingATag(url: string, fileName: string) {
  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;

  document.body.appendChild(a);

  a.click();

  document.body.removeChild(a);
}

export function downloadCsvTextAsFile(filename: string, text: string) {
  var element = document.createElement("a");

  element.setAttribute("id", filename);
  element.setAttribute(
    "href",
    "data:text/csv;charset=utf-8," + encodeURIComponent(text),
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function downloadBinaryData(
  data: any,
  fileName: string,
  mimeType: string,
) {
  const blob = new Blob([data], { type: mimeType });
  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();

  // Cleanup
  window.addEventListener(
    "focus",
    () => {
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    },
    { once: true },
  );
}

export async function downloadMultipleFilesAsZip(
  files: Array<{
    buffer: ArrayBuffer | Uint8Array | Blob;
    fileName: string;
    mimeType?: string;
  }>,
  zipFileName: string = 'download.zip'
) {
  try {
    // Convert files to the format expected by client-zip
    const zipFiles = files.map(file => ({
      name: file.fileName,
      input: file.buffer,
      ...(file.mimeType && { type: file.mimeType })
    }));

    // Generate and download the zip file
    const blobResponse = await downloadZip(zipFiles);

    // Create a download link
    const url = URL.createObjectURL(await blobResponse.blob());
    const a = document.createElement('a');
    a.href = url;
    a.download = zipFileName;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();

    // Cleanup
    window.addEventListener(
      'focus',
      () => {
        URL.revokeObjectURL(url);
        document.body.removeChild(a);
      },
      { once: true }
    );
  } catch (error) {
    console.error('Error generating zip file:', error);
    throw error;
  }
}