import {
    InputRequiredStatus,
    InputFormType,
    InputType,
    ImportType,
    ExportType,
    InputFilter,
    InputRequiredStatusType,
} from "../../lib/form";
import {
  CommodityOptions,
  Commodities,
} from "../../lib/api/commodity";
import { reduceArrayTo, formulateOptionMonths } from "../../lib/api/common";
import { GenerateLoadingOrError, genFlatOptionList, genIdOptionList, CommonDestinationTimezone, createDateLabel } from "../../lib/utils";
import moment from 'moment-timezone';

export const BaseFormSchema = {
    id: { hidden: true, input_type: InputType.Uuid, input_type_validation: InputFormType.Uuid, required_status: InputRequiredStatus.Optional },

    market_zone: { label: "Market Zone", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },
    dtn_market_zone: { label: "DTN Market Zone", input_type: InputType.String, input_type_validation: InputFormType.String, required_status: InputRequiredStatus.Required },

    active: { hidden: true, label: "Active", input_type: InputType.Boolean, input_type_validation: InputFormType.Boolean, required_status: InputRequiredStatus.Optional },

    customer_id: { label: "Related Customer", options_reference_id: "customerOptions", input_type: InputType.Uuid, input_type_validation: InputFormType.Uuid, required_status: InputRequiredStatus.Optional, apiDisplayFormat: ({ original }: any) => { return original.Customer?.long_name || "" } },
};
